import React from 'react'
import { Box, makeStyles } from '@perk-ui/core'

import { ReactComponent as Doctor } from '../../../assets/scenes/Doctor.svg'

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
    '& > svg': {
      height: 200,
      width: 200,
    },
  },
}))

const ActivityHero: React.FC = () => {
  const classes = useStyles()
  return (
    <Box pt={5} className={classes.hero}>
      <Doctor />
    </Box>
  )
}

export default ActivityHero
