import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  H2,
  H5,
  HeaderBar,
  HeaderBarButton,
  Icon,
  makeStyles,
  useTheme,
} from '@perk-ui/core'
import { ArrowRight } from 'react-feather'

import { ReactComponent as BodyMapIcon } from '../../../assets/BodyMapIcon.svg'
import InteractiveBodyMap from '../../../components/InteractiveBodyMap'
import { Footer, Header } from '../../../components/Layout'

export interface BodyMapPart {
  /**
   * The body part's code
   */
  id: string
}

export interface BodyMapProps {
  selectedParts: BodyMapPart[]
  onChange: (parts: BodyMapPart[]) => void
}

const useStyles = makeStyles((theme) => ({
  promptButton: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
  },
  bodyMapTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: -16,
  },
}))

const BodyMap: React.FC<BodyMapProps> = ({ selectedParts, onChange }) => {
  const theme = useTheme()
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleBackButtonClick = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Button
        className={classes.promptButton}
        variant="outlined"
        fullWidth
        onClick={handleOpen}
        startIcon={
          <Icon color="secondaryText" size="x-large">
            <ArrowRight stroke={theme.palette.grey[800]} />
          </Icon>
        }
      >
        <Box
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <H5 color="textPrimary" style={{ fontWeight: 'normal' }}>
            Select body parts
          </H5>
          {selectedParts.length > 0 ? (
            <Avatar size="small">{selectedParts.length}</Avatar>
          ) : (
            <Icon color="secondaryText" size="x-large">
              <BodyMapIcon />
            </Icon>
          )}
        </Box>
      </Button>

      <Dialog
        fullScreen
        aria-labelledby="body-part-picker-label"
        open={isOpen}
        onClose={handleClose}
        style={{
          maxWidth: 620,
          width: '100%',
          margin: 'auto',
        }}
      >
        {/* TODO Add a fade animation */}
        <Header withNav={false}>
          <HeaderBar
            titleContent={
              <H5 id="body-part-picker-label" align="center">
                Where is your pain?
              </H5>
            }
            left={
              <HeaderBarButton variant="back" onClick={handleBackButtonClick} />
            }
          />
        </Header>
        <Box p={3} pt={9}>
          <H2 className={classes.bodyMapTitle}>Front</H2>
          <InteractiveBodyMap
            perspective="front"
            selectedParts={selectedParts}
            onChange={onChange}
          />

          <H2 className={classes.bodyMapTitle}>Back</H2>
          <InteractiveBodyMap
            perspective="back"
            selectedParts={selectedParts}
            onChange={onChange}
          />
          <Footer withNav={false} floating>
            <Box p={3}>
              <Button fullWidth onClick={handleClose}>
                Done
              </Button>
            </Box>
          </Footer>
        </Box>
      </Dialog>
    </>
  )
}

export default BodyMap
