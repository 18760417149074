"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Use Typescript module augmentation to extend the Theme interface with the PerkThemeValues.
 */
require("./themeAugmentation");
var styles_1 = require("../styles");
/**
 * Create a Perk Theme! Accepts a Palette and set of customizations that will be applied on top of the
 * base Material-UI theme and the base Perk theme.
 *
 * @param palette A Material-UI Palette of colors
 * @param customizations A set of customizations to the base Material-UI theme.
 *  These will be deep-merged with our defaults
 */
var createPerkTheme = function (palette, customizations) {
    // Create a standard theme so that we can access some default helpers in our custom theme.
    var theme = styles_1.createMuiTheme();
    // Custom Perk values we make available via the theme object
    // e.g. makeStyles(theme => .. theme.perk.inputBackground ..)
    var perkThemeValues = {
        icon: {
            size: {
                small: 16,
                medium: 20,
                large: 24,
                'x-large': 30,
            },
        },
        boxShadow: {
            button: '0 2px 20px rgba(0, 0, 0, .10)',
            card: '0 2px 24px rgba(0, 0, 0, .08)',
            switch: '0 2px 5px rgba(0, 0, 0, .25)',
        },
        borderRadius: 10,
        inputBackground: '#f5f5f8',
        textPrimaryActiveStyle: {
            // This not() increases specificity to make :active work on Mobile Safari
            '&:not(:disabled):active': {
                backgroundColor: styles_1.fade(palette.primary.main, palette.action.activatedOpacity),
            },
        },
        backgroundContainerImage: 'https://placeimg.com/200/320/nature',
        backgroundContainerColor: '#f5f5f8',
    };
    var perkThemeOptions = {
        perk: perkThemeValues,
        palette: palette,
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    html: {
                        backgroundColor: palette.background.paper,
                    },
                    body: {
                        backgroundColor: palette.background.paper,
                        overscrollBehavior: 'none',
                    },
                    // Use the Link component. These anchor styles are just a fallback.
                    'a:link': {
                        color: palette.primary.main,
                    },
                    'a:visited': {
                        color: palette.primary.light,
                    },
                    'a:active': {
                        color: palette.primary.dark,
                    },
                    // For some reason, these @material-ui/pickers classes are not overrideable
                    // and must be referenced by standard CSS selectors
                    '.MuiPickersStaticWrapper-staticWrapperRoot .MuiPickersBasePicker-pickerView': {
                        maxWidth: '310px',
                        padding: 0,
                        width: '100%',
                        // prevent a scrollbar flash when changing between with a different number
                        // of weeks
                        overflow: 'hidden',
                        justifyContent: 'initial',
                    },
                    '.MuiPickersBasePicker-containerLandscape': {
                        justifyContent: 'center',
                    },
                },
            },
            MuiAccordion: {
                root: {
                    boxShadow: perkThemeValues.boxShadow.card,
                },
            },
            MuiAvatar: {
                root: {
                    height: 48,
                    width: 48,
                },
            },
            MuiButton: {
                root: {
                    // These values represent size "medium", but by default the Button size is set to "large"
                    fontSize: '1rem',
                    minHeight: 48,
                },
                contained: {
                    boxShadow: perkThemeValues.boxShadow.button,
                    '&:hover': {
                        boxShadow: perkThemeValues.boxShadow.button,
                        '@media (hover: none)': {
                            // Hovered items on mobile devices should look normal - not
                            // as if they've been de-selected or hovered over
                            boxShadow: undefined,
                            backgroundColor: 'transparent',
                        },
                    },
                    '&:active': {
                        boxShadow: perkThemeValues.boxShadow.button,
                    },
                },
                containedPrimary: {
                    color: 'white !important',
                    // This not() increases specificity to make :active work on Mobile Safari
                    '&:not(:disabled):active': {
                        backgroundColor: palette.primary.light,
                    },
                },
                outlined: {
                    boxShadow: perkThemeValues.boxShadow.button,
                },
                outlinedPrimary: __assign({ border: 'none', '&:hover, &:disabled': {
                        border: 'none',
                    }, '&:hover': {
                        backgroundColor: styles_1.fade(palette.primary.light, palette.action.hoverOpacity),
                    } }, perkThemeValues.textPrimaryActiveStyle),
                sizeSmall: {
                    minHeight: 32,
                },
                text: {
                    transition: theme.transitions.create('background-color', {
                        duration: theme.transitions.duration.short,
                    }),
                },
                textPrimary: __assign({ color: theme.palette.primary.contrastText, '&:hover': {
                        backgroundcolor: styles_1.fade(palette.primary.light, palette.action.hoverOpacity),
                    } }, perkThemeValues.textPrimaryActiveStyle),
                textSizeSmall: {
                    fontSize: '.875rem',
                    minWidth: 0,
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                },
                iconSizeSmall: {
                    '&.MuiButton-startIcon': {
                        marginLeft: 0,
                        marginRight: theme.spacing(0.5),
                    },
                    '&.MuiButton-endIcon': {
                        marginLeft: theme.spacing(0.5),
                        marginRight: 0,
                    },
                },
                // Note: MUI does not have a sizeMedium class override
                sizeLarge: {
                    fontSize: '1rem',
                    minHeight: 60,
                    padding: theme.spacing(1, 4),
                },
            },
            MuiButtonGroup: {
                root: {
                    boxShadow: perkThemeValues.boxShadow.card,
                },
                groupedOutlined: {
                    boxShadow: 'none',
                    '&:active': {
                        backgroundColor: palette.primary.main,
                        color: palette.primary.contrastText,
                    },
                },
                groupedHorizontal: {
                    borderColor: 'transparent',
                    '&:not(:first-child)': {
                        borderLeftColor: palette.divider,
                    },
                },
                groupedVertical: {
                    borderColor: 'transparent',
                    '&:not(:first-child)': {
                        borderTopColor: palette.divider,
                    },
                },
            },
            MuiCard: {
                root: {
                    boxShadow: perkThemeValues.boxShadow.card,
                },
            },
            MuiCardActions: {
                root: {
                    padding: theme.spacing(1.5, 3, 3),
                },
                spacing: {
                    '& > :not(:first-child)': {
                        marginLeft: theme.spacing(2),
                    },
                },
            },
            MuiCardActionArea: {
                focusHighlight: {
                    // give the hover and focus background effect a primary.light color
                    backgroundColor: palette.primary.light,
                },
            },
            MuiCardContent: {
                root: {
                    padding: theme.spacing(3),
                    '.MuiCard-root > &:not(:last-child), .MuiCardActionArea-root:not(:last-child) > &': {
                        // Shorten the bottom padding of CardContent items within a Card or CardActionArea
                        paddingBottom: theme.spacing(1.5),
                    },
                    '& > .muitypography-root:first-child': {
                        // typography's line-height causes a small but noticeable amount of additional top padding
                        // in cards, making them feel off-balance. Add a negative margin to compensate.
                        margintop: theme.spacing(-0.5),
                    },
                },
            },
            MuiCheckbox: {
                root: {
                    borderRadius: 2,
                    padding: 0,
                    marginRight: 2,
                    transition: theme.transitions.create('color', {
                        duration: theme.transitions.duration.short,
                    }),
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    '&$colorSecondary': {
                        '&:active': {
                            color: palette.primary.light,
                        },
                        '&$checked:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                    // Add browser-appropriate focus state to Checkbox
                    '&.Mui-focusVisible': {
                        outline: '0px auto -webkit-focus-ring-color',
                        fallbacks: [{ outline: '1px dotted #212121' }],
                    },
                },
            },
            MuiFormHelperText: {
                root: {
                    fontSize: '.875rem',
                },
            },
            MuiFormLabel: {
                root: {
                    color: palette.text.primary,
                    fontWeight: theme.typography.fontWeightBold,
                    '&$focused': {
                        color: palette.text.primary,
                    },
                },
            },
            MuiIconButton: {
                root: {
                    color: theme.palette.text.primary,
                    '&:hover': {
                        backgroundcolor: styles_1.fade(palette.primary.light, palette.action.hoverOpacity),
                    },
                    // This not() increases specificity to make :active work on Mobile Safari
                    '&:not(:disabled):active': {
                        backgroundColor: styles_1.fade(palette.action.active, palette.action.activatedOpacity),
                        transition: theme.transitions.create('background-color', {
                            duration: theme.transitions.duration.short,
                        }),
                    },
                },
            },
            MuiInput: {
                root: {
                    backgroundColor: perkThemeValues.inputBackground,
                    border: '2px solid transparent',
                    borderRadius: perkThemeValues.borderRadius,
                    transition: theme.transitions.create(['border-color', 'background-color'], {
                        duration: theme.transitions.duration.short,
                    }),
                    '&:hover:not($disabled), &$focused': {
                        borderColor: theme.palette.common.black,
                    },
                    '&$focused': {
                        backgroundColor: theme.palette.common.white,
                    },
                },
                input: {
                    borderRadius: perkThemeValues.borderRadius - 2,
                    boxSizing: 'border-box',
                    minHeight: 56,
                    padding: theme.spacing(2),
                    // This allows the input's autofilled style to flow beneath the end adornment
                    '&.MuiInputBase-inputAdornedEnd': {
                        marginRight: -56,
                        paddingRight: 56,
                    },
                },
                formControl: {
                    'label + &': {
                        marginTop: theme.spacing(1),
                    },
                },
                multiline: {
                    paddingBottom: 0,
                    paddingTop: 0,
                },
                inputMultiline: {
                    // These padding values cause the text of the multiline textarea to
                    // match the vertical alignment of the default text input
                    paddingBottom: theme.spacing(2.1),
                    paddingTop: theme.spacing(2.4),
                },
            },
            MuiInputAdornment: {
                positionEnd: {
                    marginRight: theme.spacing(0.5),
                },
                // Match the end adornment's icon hover and focus styles to the border
                root: {
                    '& svg': {
                        transition: theme.transitions.create('color', {
                            duration: theme.transitions.duration.short,
                        }),
                    },
                    '.MuiInput-root:hover & svg, .Mui-focused & svg': {
                        color: palette.text.primary,
                    },
                },
            },
            MuiInputLabel: {
                root: {
                    '&$error': {
                        color: 'unset',
                    },
                },
                shrink: {
                    position: 'static',
                    transform: 'none',
                },
            },
            MuiLink: {
                root: {
                    '&:link': {
                        color: palette.primary.main,
                    },
                    '&:visited': {
                        color: palette.primary.light,
                    },
                    '&:active': {
                        color: palette.primary.dark,
                    },
                },
                underlineAlways: {
                    '&:link': {
                        color: palette.text.hint,
                    },
                    '&:visited': {
                        color: palette.text.hint,
                    },
                    '&:hover': {
                        color: palette.primary.main,
                    },
                    '&:active': {
                        color: palette.primary.dark,
                    },
                },
            },
            MuiList: {
                padding: {
                    paddingBottom: theme.spacing(2),
                    paddingTop: theme.spacing(2),
                },
                root: {
                    '& > .listitem-base:first-child .listitemtext': {
                        marginTop: 0,
                    },
                    '& > .listitem-base:last-child .listitemtext': {
                        marginBottom: 0,
                    },
                },
            },
            MuiListItem: {
                root: {
                    minHeight: theme.spacing(9),
                },
                dense: {
                    minHeight: theme.spacing(7.5),
                },
                button: {
                    borderRadius: perkThemeValues.borderRadius,
                    // Remove the default hover effect so that the below Paper's background shines through
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    '& .MuiPaper-root:hover': {
                        backgroundColor: styles_1.fade(palette.primary.light, palette.action.hoverOpacity),
                    },
                },
                gutters: {
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                },
            },
            MuiListItemIcon: {
                root: {
                    color: palette.text.primary,
                    minWidth: theme.spacing(2) + 24,
                },
            },
            MuiListItemSecondaryAction: {
                root: {
                    right: theme.spacing(3),
                },
            },
            /////////////////////////////////////////////////////////
            // Begin @material-ui/pickers augmentation
            /////////////////////////////////////////////////////////
            MuiPickersCalendarHeader: {
                daysHeader: {
                    justifyContent: 'space-between',
                },
                dayLabel: {
                    color: 'unset',
                    fontSize: theme.typography.body2.fontSize,
                    fontWeight: 'bold',
                },
                transitionContainer: {
                    width: '80%',
                    '& p': {
                        fontSize: theme.typography.h5.fontSize,
                        marginTop: theme.spacing(-0.5),
                    },
                },
                switchHeader: {
                    justifyContent: 'center',
                    marginTop: theme.spacing(1),
                },
            },
            MuiPickersCalendar: {
                transitionContainer: {
                    minHeight: 'var(--calendar-body-min-height, 216px)',
                    '& > div > .MuiPickersCalendar-week:first-child': {
                        borderTop: "1px solid " + '#8A8A8A',
                    },
                    '& > div > .MuiPickersCalendar-week': {
                        borderBottom: "1px solid " + '#8A8A8A',
                    },
                },
                week: {
                    '& div': {
                        borderRight: "1px solid " + '#8A8A8A',
                    },
                    '& div:last-child': {
                        borderRight: 'none',
                    },
                },
            },
            MuiPickersDay: {
                day: {
                    width: '40px',
                    height: '40px',
                    '& p': {
                        // This should apply only to the Date numbers (1..31)
                        fontSize: '0.65rem',
                    },
                    '& > .MuiIconButton-label': {
                        // Move the date number to the top-left corner of the Day square
                        transform: 'translate(-12px, -12px)',
                    },
                },
                current: {
                    color: 'inherit',
                },
                daySelected: {
                    color: 'inherit',
                    opacity: 1,
                    // By default, a 2px horizontal margin is applied that makes the
                    // grey background look inset. We want the background to fill out
                    // all the way to the containing box, so remove the margin and
                    // explicitly force the box to a certain width.
                    margin: 0,
                    width: 44,
                    borderRadius: 'initial',
                    backgroundColor: theme.palette.grey[300],
                    '&:hover': {
                        backgroundColor: theme.palette.grey[300],
                    },
                    '& p': {
                        fontWeight: 'bold',
                    },
                },
                dayDisabled: {
                    // I'm not sure why, but days in the past/future are displayed
                    // as selected even when the `disablePast`/`disableFuture` props
                    // are applied.
                    // Remove those visuals.
                    background: 'none',
                },
            },
            /////////////////////////////////////////////////////////
            // End @material-ui/pickers augmentation
            /////////////////////////////////////////////////////////
            MuiSwitch: {
                root: {
                    // Reducing the vertical padding increases the height of the track.
                    paddingTop: 11,
                    paddingBottom: 11,
                },
                thumb: {
                    boxShadow: perkThemeValues.boxShadow.switch,
                },
                track: {
                    backgroundColor: palette.text.secondary,
                    borderRadius: 8,
                },
                switchBase: {
                    '&.Mui-focusVisible': {
                        backgroundColor: styles_1.fade(palette.action.active, palette.action.hoverOpacity),
                    },
                },
                /* The MUI Switch's default color is "secondary". */
                colorSecondary: {
                    '&$checked': {
                        color: palette.success.main,
                        '&:not($disabled) + .MuiSwitch-track': {
                            backgroundColor: palette.success.light,
                        },
                        '&:hover': {
                            backgroundColor: styles_1.fade(palette.success.main, palette.action.hoverOpacity + 0.05),
                        },
                        '&:active': {
                            backgroundColor: styles_1.fade(palette.success.dark, palette.action.activatedOpacity + 0.1),
                        },
                    },
                },
            },
            MuiTouchRipple: {
                child: {
                    // give the ripple a light primary-theme color
                    backgroundColor: palette.primary.light,
                },
                ripple: {
                    animation: 'none',
                },
                rippleVisible: {
                    animation: 'none',
                    opacity: 0.08,
                },
            },
        },
        props: {
            MuiButton: {
                color: 'primary',
                disableTouchRipple: true,
                size: 'large',
                variant: 'contained',
            },
            MuiButtonGroup: {
                fullWidth: true,
                orientation: 'vertical',
            },
            MuiIconButton: {
                disableRipple: true,
            },
            MuiInput: {
                disableUnderline: true,
            },
            MuiList: {
                disablePadding: true,
            },
            MuiSkeleton: {
                animation: 'wave',
            },
            MuiTextField: {
                InputLabelProps: {
                    shrink: true,
                },
            },
        },
        shape: {
            borderRadius: perkThemeValues.borderRadius,
        },
        typography: {
            /* Note: body2 is defaulted to the textSecondary color by the Typography
            component. We can't set that color here, because body2's color is applied to
            the entire document */
            fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
            button: {
                fontWeight: theme.typography.fontWeightBold,
                textTransform: 'none',
            },
            h1: {
                fontSize: '2.5rem',
                fontWeight: theme.typography.fontWeightBold,
            },
            h2: {
                fontSize: '2rem',
                fontWeight: theme.typography.fontWeightBold,
            },
            h3: {
                fontSize: '1.5rem',
                fontWeight: theme.typography.fontWeightBold,
            },
            h5: {
                fontSize: '1rem',
                fontWeight: theme.typography.fontWeightBold,
            },
            body1: {
                lineHeight: '1.3',
            },
        },
    };
    return styles_1.createMuiTheme(perkThemeOptions, customizations);
};
exports.default = createPerkTheme;
