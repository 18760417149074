import { useQuery } from 'react-query'

import request from '../../config/axios'
import { useIsAuthenticated } from '../auth/AuthContext'
import { InterruptSearchData, InterruptState } from './interrupts'

export interface InterruptSearchResult {
  data: InterruptSearchData[]
}

interface InterruptSearchParams {
  states: InterruptState[]
}

const useInterruptSearch = (searchParams: InterruptSearchParams) => {
  const isAuthd = useIsAuthenticated()

  return useQuery<InterruptSearchResult>(
    'intrepids/search',
    () => request.post('intrepids/search', searchParams).then((r) => r.data),
    {
      enabled: isAuthd,
      refetchInterval: 60 * 1000,
      refetchIntervalInBackground: true,
    },
  )
}

export default useInterruptSearch
