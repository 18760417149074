import { IQuestionnaireResponse } from '@ahryman40k/ts-fhir-types/lib/R4'

import { findExtension } from './utils'

const SCORES_URL =
  'http://hl7.org/fhir/StructureDefinition/questionnaire-scores'
const SCORES_THETA_URL =
  'http://hl7.org/fhir/StructureDefinition/questionnaire-scores/theta'

/**
 * Extracts a CAT questionnaire's theta and calculates a normalized score.
 * Returns undefined if no score was found.
 *
 * @note score = 50 + (theta * 10)
 *
 * @param qr The QuestionnaireResponse to dig for scores
 * @returns calculated score if extensions exist, otherwise undefined
 */
export const getQrScoreFromTheta = (qr: IQuestionnaireResponse) => {
  const theta = findExtension(
    [SCORES_URL, SCORES_THETA_URL],
    qr.extension || [],
  ).valueDecimal

  if (theta == null) return undefined

  // theta * 10 + 50 is the score for all Assessments (for now, at least)
  const score = theta * 10 + 50

  return score
}
