"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toRelativeDay = exports.toRelativeDateString = void 0;
var date_fns_1 = require("date-fns");
/**
 *  Returns a human readable relative date string with these patterns:
 *    - Today
 *    - Yesterday
 *    - 2..6 days + 'ago'
 *    - 1..4 weeks + 'ago'
 *    - 1..11 months + 'ago'
 *    - N years + 'ago'
 *
 * @param date A ms-based number, or a Date object
 * @param baseDate the date to base the comparison to. Defaults to now
 */
exports.toRelativeDateString = function (date, baseDate) {
    if (baseDate === void 0) { baseDate = Date.now(); }
    if (date_fns_1.isToday(date))
        return 'Today';
    if (date_fns_1.isYesterday(date))
        return 'Yesterday';
    var oneWeekAgo = date_fns_1.subWeeks(baseDate, 1);
    if (date_fns_1.isAfter(date, oneWeekAgo)) {
        return date_fns_1.formatDistanceStrict(date, baseDate, {
            addSuffix: true,
        });
    }
    // Unfortunately, formatDistanceStrict does not accept a `unit` of weeks
    // If it did, everything after `isYesterday` would be just one
    // formatDistanceStrict call.
    var lastMonth = date_fns_1.subMonths(baseDate, 1);
    if (date_fns_1.isAfter(date, lastMonth)) {
        var weeksSince = Math.abs(date_fns_1.differenceInWeeks(date, baseDate));
        return weeksSince + " week" + (weeksSince > 1 ? 's' : '') + " ago";
    }
    return date_fns_1.formatDistanceStrict(date, baseDate, {
        addSuffix: true,
    });
};
/**
 *  Returns a human readable relative date string with these patterns:
 *    - Today
 *    - Yesterday
 *    - 'Last' + Day of the week
 *    - m/d/y date
 *
 * @param date A ms-based number, or a Date object
 * @param baseDate The date to base the comparison to. Defaults to now
 */
exports.toRelativeDay = function (date, baseDate) {
    if (baseDate === void 0) { baseDate = Date.now(); }
    if (date_fns_1.isToday(date))
        return 'Today';
    if (date_fns_1.isYesterday(date))
        return 'Yesterday';
    var oneWeekAgo = date_fns_1.subWeeks(baseDate, 1);
    if (date_fns_1.isAfter(date, oneWeekAgo)) {
        return "Last " + date_fns_1.format(date, 'EEEE');
    }
    return date_fns_1.format(date, 'M/d/y');
};
