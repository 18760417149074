import React from 'react'
import { Box, makeStyles } from '@perk-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingBottom: '56.25%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    height: '0',
    overflow: 'hidden',
    maxWidth: '100%',
    '& iframe, & object, & embed': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
}))

interface VideoProps {
  src: string
  title: string
}

const Video: React.FC<VideoProps> = ({ src, title }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <iframe src={src} frameBorder="0" allowFullScreen title={title}></iframe>
    </Box>
  )
}

export default Video
