interface ConditionalWrapProps {
  condition: boolean
  wrapper: (children: JSX.Element) => JSX.Element
  children: JSX.Element
}

type ConditionalWrapper = (props: ConditionalWrapProps) => JSX.Element

// TODO: Use a `React.FC` type here
const ConditionalWrap: ConditionalWrapper = ({
  children,
  condition,
  wrapper,
}) => (condition ? wrapper(children) : children)

export default ConditionalWrap
