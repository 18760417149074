import {
  IQuestionnaire,
  IQuestionnaireResponse,
  QuestionnaireResponseStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import deepmerge from 'deepmerge'

import { toIsoStringWithTz } from '../../utils/dates'
import { DeepPartial } from '../../utils/type-helpers'

export interface BuildBaseQRParams {
  patientId: string
  containedQuestionnaire: IQuestionnaire
}

export const buildQuestionnaireResponse = (
  { patientId, containedQuestionnaire }: BuildBaseQRParams,
  overrides: DeepPartial<IQuestionnaireResponse> = {},
): IQuestionnaireResponse => {
  const qId = containedQuestionnaire.id

  return deepmerge<IQuestionnaireResponse>(
    {
      resourceType: 'QuestionnaireResponse',
      contained: [containedQuestionnaire],
      extension: [
        {
          // This extension prevents HAPI Fhir from stripping the contained
          // Questionnaire from the response.
          url: 'https://perkhealth.me/StructureDefinition/hub-reference',
          valueReference: {
            // `#` indicates a reference to a resource `contained` in this QR
            reference: `#${qId}`,
          },
        },
      ],
      subject: {
        reference: `Patient/${patientId}`,
      },
      questionnaire: `Questionnaire/${qId}`,
      status: QuestionnaireResponseStatusKind._inProgress,
      authored: toIsoStringWithTz(new Date()),
    },
    overrides,
  )
}

export default buildQuestionnaireResponse
