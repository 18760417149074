import React, { HTMLAttributes } from 'react'
import { makeStyles, Skeleton } from '@perk-ui/core'
import clsx from 'clsx'

import ConditionalWrap from '../utils/ConditionalWrap'

interface ImgWithFallbackProps extends HTMLAttributes<HTMLImageElement> {
  /**
   * The image src to load. Generally, should be a .webp file.
   */
  src: string
  /**
   * A less-optimized, more broadly usable image link. Generally,
   * this should be a .jpg file
   */
  fallback: string
  /**
   * Alt text.
   * The best format for alt text is sufficiently descriptive but doesn't
   * contain any spammy attempts at keyword stuffing. If you can close your
   * eyes, have someone read the alt text to you, and imagine a reasonably
   * accurate version of the image, you're on the right track.
   *
   * @example alt="Stack of blueberry pancakes with powdered sugar"
   * @example alt="man wearing backpack walking down escalator"
   * @example alt="Red-crested rooster crowing"
   */
  alt: string

  /**
   * minHeight of the image and skeleton placeholder. Used to give the
   * skeleton a measure of how tall to be.
   * @default 250px
   */
  minHeight?: string
  /**
   *  A hint to the `source` tag for what format the src'd image is.
   *
   * Interestingly, it's a long-standing convention for browsers to not
   * rely on assumptions of content type just by a file's extension, which
   * is why you there are so many specifiers for content type in various
   * HTML elements.
   */
  type?: string
}

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'content-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '& source, & img': {
      display: 'block',
      maxWidth: '100%',
    },
  },
}))

/**
 * An Image component with a Skeleton wrapper.
 *
 * Accepts a primary @param src, which should be a url to an image
 * with a .webp format. The component will display that image if the
 * device/browser we're on supports it, and will otherwise display
 * the image linked to a .jpg image @param fallback.
 *
 * @see https://www.joshwcomeau.com/performance/embracing-modern-image-formats/
 */
const ImgWithFallback: React.FC<ImgWithFallbackProps> = ({
  src,
  fallback,
  alt,
  type = 'image/webp',
  minHeight = '250px',
  className,
  ...rest
}) => {
  // TODO: replace with asserts

  if (__DEV__) {
    if (!src.endsWith('.webp')) {
      throw new Error(
        `ImgWithFallback's src prop must be an image link ending in .webp. Was: ${fallback}`,
      )
    }
    if (!fallback.endsWith('.jpg')) {
      throw new Error(
        `ImgWithFallback's falback prop must be an image link ending in .jpg. Was: ${fallback}`,
      )
    }
  }
  const styles = useStyles()
  const [loaded, setLoaded] = React.useState(false)

  return (
    <ConditionalWrap
      condition={!loaded}
      wrapper={(children) => (
        <Skeleton variant="rect" style={{ maxWidth: 'unset' }}>
          {children}
        </Skeleton>
      )}
    >
      <picture
        className={clsx(styles.root, className)}
        {...rest}
        onLoad={(evt: React.SyntheticEvent<HTMLImageElement>) => {
          setLoaded(true)
          rest?.onLoad?.(evt)
        }}
      >
        <source srcSet={src} type={type} />
        <img style={{ minHeight }} src={fallback} alt={alt} />
      </picture>
    </ConditionalWrap>
  )
}

export default ImgWithFallback
