import React, { useState } from 'react'
import { IQuestionnaireResponse } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Body1, Box, H3, HeaderBar, HeaderBarButton } from '@perk-ui/core'
import { format } from 'date-fns'

import AppLinkButton from '../../../components/AppLinkButton'
import { Footer, Header, Page } from '../../../components/Layout'
import { getDailyJournalActivities } from '../../../features/Fhir/dailyJournalUtils'
import { useHistory } from '../../../utils/useHistory'
import ActivityCalendar from './components/ActivityCalendar'

const ActivityHistory: React.FC = () => {
  const { goBack } = useHistory()

  const [day, setDay] = useState<Date>(new Date())
  const [qr, setQr] = useState<IQuestionnaireResponse | undefined>()
  const activities = qr ? getDailyJournalActivities(qr) || [] : []

  const handleDaySelect = (day: Date, qr?: IQuestionnaireResponse) => {
    setQr(qr)
    setDay(day)
  }

  return (
    <Page
      title="Activity History"
      header={
        <Header withNav={false}>
          <HeaderBar
            titleContent="Activity History"
            left={<HeaderBarButton variant="back" onClick={goBack} />}
          />
        </Header>
      }
      footer={
        <Footer withNav={false} floating>
          <Box p={3}>
            <AppLinkButton to="/me/manage-activities" fullWidth>
              Change the Activities you track
            </AppLinkButton>
          </Box>
        </Footer>
      }
    >
      <Box p={3} pt={1}>
        <ActivityCalendar onChange={handleDaySelect} />
        <Box pt={3}>
          <H3>{format(day, 'MMMM d')}</H3>
          {qr && activities.length ? (
            activities.map((activity) => {
              const label = activity.valueCoding
                ? activity.valueCoding.display
                : activity.valueString
              return label ? <Body1 key={label}>{label}</Body1> : null
            })
          ) : (
            <Body1>No Activities</Body1>
          )}
        </Box>
      </Box>
    </Page>
  )
}

export default ActivityHistory
