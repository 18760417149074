import React from 'react'
import { Body1, ListItem, ListItemText, toRelativeDay } from '@perk-ui/core'
import { format } from 'date-fns'

interface ScoreListItemProps {
  baseline?: boolean
  date: number
  divider?: boolean
  label: string
  score: number | null
}

const ScoreListItem: React.FC<ScoreListItemProps> = ({
  baseline = false,
  date,
  divider = false,
  label,
  score,
}) => {
  const helper = baseline
    ? `${format(date, 'M/d/yy')} Baseline`
    : toRelativeDay(date)

  return (
    <ListItem
      leftSpacing="full-width"
      divider={divider}
      disableGutters
      style={{ paddingLeft: 16, paddingRight: 16 }}
    >
      <Body1 style={{ fontSize: 20, fontWeight: 'bold', marginRight: 16 }}>
        {score}
      </Body1>
      <ListItemText body={label} helper={helper} />
    </ListItem>
  )
}

export default ScoreListItem
