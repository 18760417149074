import React from 'react'
import { ListItem, makeStyles, Paper } from '@perk-ui/core'

import Checklist, { ChecklistItem } from '../../../components/Checklist'
import Textbox from '../../../components/Textbox'

export interface ActivitiesProps {
  activities: ChecklistItem[]
  onActivitySelect: (activityId: string) => void
  otherValue: string
  onOtherChange: (value: string) => void
}

const useStyles = makeStyles((theme) => ({
  itemCard: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    textAlign: 'left',
    width: '100%',
    boxShadow: theme.perk.boxShadow.card,
  },
}))

const Activities: React.FC<ActivitiesProps> = ({
  activities,
  onActivitySelect,
  otherValue,
  onOtherChange,
}) => {
  const classes = useStyles()
  return (
    <>
      <Checklist items={activities} onItemSelect={onActivitySelect} />
      <ListItem
        button
        disableGutters
        tabIndex={-1}
        style={{ padding: 0, minHeight: 0, marginTop: 16 }}
      >
        <Paper className={classes.itemCard}>
          <Textbox
            style={{ width: '100%' }}
            value={otherValue}
            onChange={(e) => onOtherChange(e.target.value)}
            placeholder="Other Activity"
          />
        </Paper>
      </ListItem>
    </>
  )
}

export default Activities
