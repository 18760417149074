import React from 'react'
import { AppBar, AppBarProps } from '@perk-ui/core'

import { isPlatform } from '../../utils/platform'
import BottomNav from './BottomNav'

export interface HeaderProps extends AppBarProps {
  /**
   * @required
   */
  withNav: boolean

  /**
   * @default true
   */
  withSafeInset?: boolean
}

const Header: React.FC<HeaderProps> = ({
  children,
  withNav,
  withSafeInset = true,
  ...rest
}) => {
  const isPhoneApp = isPlatform('mobile') && !isPlatform('mobileweb')

  return (
    <AppBar placement="top" {...rest}>
      {withSafeInset && (
        <div style={{ paddingTop: 'env(safe-area-inset-top)' }} />
      )}
      {children}
      {!isPhoneApp && withNav && <BottomNav />}
    </AppBar>
  )
}

export default Header
