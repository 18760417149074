import React, { useEffect } from 'react'
import { Box, Divider, H2 } from '@perk-ui/core'
import { useLocation } from 'react-router'

import AppLinkButton from '../../../components/AppLinkButton'
import { Footer, Page } from '../../../components/Layout'
import AnimatedRedirect from '../../../features/animation/AnimatedRedirect'
import { getQrScoreFromTheta } from '../../../features/Fhir/painAssessmentUtils'
import { useInterruptManager } from '../../../features/interrupts/InterruptProvider'
import { questionnaireMap } from '../../../features/survey/questionnaire-data'
import useQRSearch from '../../../features/survey/useQRSearch'
import {
  intensityScoreMap,
  interferenceScoreMap,
} from '../../../utils/painScores'
import { WeeklyAssessmentLocationState } from '../Intro/WeeklyAssessmentIntro'
import ResultCard from './components/ResultCard'

const buildNextUrl = (changeFromBaseline: {
  intensity: number
  interference: number
}) => {
  const params = new URLSearchParams()

  if (changeFromBaseline.intensity >= 3) {
    params.set('intensity', String(changeFromBaseline.intensity))
  }
  if (changeFromBaseline.interference >= 3) {
    params.set('interference', String(changeFromBaseline.interference))
  }
  const hasSignificantChange = Boolean(String(params).length)

  return hasSignificantChange
    ? `/weekly-assessment/change-alert?${String(params)}`
    : '/weekly-assessment/thank-you'
}

const WeeklyAssessmentResults: React.FC = () => {
  const { state } = useLocation<WeeklyAssessmentLocationState>()
  const { completeCurrentInterrupt, currentInterrupt } = useInterruptManager()

  useEffect(() => {
    if (currentInterrupt?.kind === 'weekly_assessment_due') {
      completeCurrentInterrupt()
    }
  }, [currentInterrupt])

  const intensityBaseline = useQRSearch({
    questionnaire: questionnaireMap.intensity,
    limit: 1,
  })
  const interferenceBaseline = useQRSearch({
    questionnaire: questionnaireMap.interference,
    limit: 1,
  })

  const baselineIntensityScore = intensityBaseline.data?.[0]
    ? getQrScoreFromTheta(intensityBaseline.data[0]) || 0
    : 0
  const baselineInterferenceScore = interferenceBaseline.data?.[0]
    ? getQrScoreFromTheta(interferenceBaseline.data[0]) || 0
    : 0

  if (
    !state.intensity.score ||
    !state.interference.score ||
    state.staleAt < new Date().getTime()
  ) {
    return <AnimatedRedirect to="/home" />
  }

  const changeFromBaseline = {
    intensity: state.intensity.score - baselineIntensityScore,
    interference: state.interference.score - baselineInterferenceScore,
  }
  const nextUrl = buildNextUrl(changeFromBaseline)
  const isLoading =
    interferenceBaseline.isLoading || intensityBaseline.isLoading

  return (
    <Page
      title="Taper App - Weekly Assessment Results"
      footer={
        <Footer withNav={false} withSafeInset={false} floating>
          <Box px={3} pb={3}>
            <AppLinkButton
              fullWidth
              to={nextUrl}
              loading={isLoading}
              disabled={isLoading}
            >
              Next
            </AppLinkButton>
          </Box>
        </Footer>
      }
    >
      <Box
        p={4}
        style={{ paddingTop: 'calc(env(safe-area-inset-top) + 16px)' }}
      >
        <H2>Results</H2>

        <Box my={3}>
          <Divider style={{ height: 2 }} />
        </Box>

        <Box>
          <ResultCard
            title="Pain Intensity"
            description="How strong your pain is."
            score={state.intensity.score}
            scoreMap={intensityScoreMap}
          />
        </Box>

        <Box my={3}>
          <Divider style={{ height: 2 }} />
        </Box>

        <ResultCard
          title="Pain Interference"
          description="The amount your pain impacts your daily life."
          score={state.interference.score}
          scoreMap={interferenceScoreMap}
        />
      </Box>
    </Page>
  )
}

export default WeeklyAssessmentResults
