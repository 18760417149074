import React, { useState } from 'react'
import { DatePicker, DatePickerProps } from '@perk-ui/core'
import {
  addWeeks,
  endOfMonth,
  getWeeksInMonth,
  isAfter,
  isSameDay,
  startOfMonth,
  subWeeks,
} from 'date-fns'

import PainScoreCircle from '../../../components/PainScoreCircle'
import { getDailyJournalPainScore } from '../../../features/Fhir/dailyJournalUtils'
import { latestQrOnDay } from '../../../features/Fhir/utils'
import { questionnaireMap } from '../../../features/survey/questionnaire-data'
import useQRSearch from '../../../features/survey/useQRSearch'

export interface DailyJournalCalendarProps {
  value: Date
  month: Date
  onChange: (day: Date, qrId: string) => void | Promise<void>
  onMonthChange: DatePickerProps['onMonthChange']
}

const DailyJournalCalendar: React.FC<DailyJournalCalendarProps> = ({
  value,
  month,
  onChange,
  onMonthChange,
}) => {
  const { data = [] } = useQRSearch({
    from: subWeeks(startOfMonth(month), 1),
    to: addWeeks(endOfMonth(month), 1),
    questionnaire: questionnaireMap.DailyPainJournal,
  })
  const [weeksInMonth, setWeeksInMonth] = useState(() => getWeeksInMonth(value))

  const handleDayClick = (day: Date) => () => {
    if (isAfter(day, new Date())) return

    const qr = latestQrOnDay(day, data)
    return qr && onChange(day, qr.id as string)
  }

  return (
    <DatePicker
      disableToolbar
      disableFuture
      orientation="landscape"
      variant="static"
      minHeight={weeksInMonth === 6 ? '247px' : undefined}
      value={value}
      onChange={() => {
        // This callback is required but does not fire when a day from another
        // month is selected. But we need that, so we've added the listener
        // to the `renderDay` result instead
      }}
      onMonthChange={(date) => {
        setWeeksInMonth(getWeeksInMonth(date))
        onMonthChange?.(date)
      }}
      renderDay={(day, selectedDate, _isDayInCurrentMonth, dayComponent) => {
        if (!day) return dayComponent

        const isToday = isSameDay(day, selectedDate)
        const qrOnDay = latestQrOnDay(day, data)
        const score = qrOnDay ? getDailyJournalPainScore(qrOnDay) : null

        return (
          <span style={{ display: 'flex' }} onClick={handleDayClick(day)}>
            {dayComponent}
            <span
              style={{
                opacity: isToday ? 1 : 0.7,
                position: 'absolute',
                transform: 'translate(6px, 4px)',
              }}
            >
              {score && <PainScoreCircle score={score} />}
            </span>
          </span>
        )
      }}
    />
  )
}

export default DailyJournalCalendar
