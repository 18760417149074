import React, { createContext, useContext, useEffect, useState } from 'react'

import { InterruptSearchData } from './interrupts'
import useCompleteInterrupt from './useCompleteInterrupt'
import useInterruptSearch from './useInterruptSearch'

type CompleteCurrentInterruptType = () => Promise<void>
interface InterruptState {
  currentInterrupt?: InterruptSearchData
  completeCurrentInterrupt: CompleteCurrentInterruptType
}

const initialState: InterruptState = {
  currentInterrupt: undefined,
  completeCurrentInterrupt: () =>
    Promise.reject('completeCurrentInterrupt not implemented yet'),
}

export const InterruptContext = createContext<InterruptState>(initialState)
export const useInterruptManager = () => useContext(InterruptContext)

const InterruptProvider: React.FC = ({ children }) => {
  const { mutateAsync: completeInterrupt } = useCompleteInterrupt()

  const [currentInterrupt, setCurrentInterrupt] = useState<
    InterruptSearchData | undefined
  >(undefined)

  // NOTE: Interrupts come in priority order from backend.
  // 1 is highest priority 2 is second highest
  const { data = { data: [] } } = useInterruptSearch({ states: ['active'] })
  const interrupts = data.data

  // Wrap function to complete interrupt so that we can automatically
  // set the currentInterrupt as undefined afterwards
  const completeCurrentInterrupt = () => {
    if (!currentInterrupt) {
      return Promise.resolve()
    }

    return completeInterrupt(currentInterrupt.id).then(() => {
      setCurrentInterrupt({
        ...currentInterrupt,
        state: 'completed',
      })
    })
  }

  /** If the currentInterrupt is empty, set it with the
   * first interrupt off of the list. Note that the list
   * of interrupts is sorted in priority order by the backend
   */
  useEffect(() => {
    if (interrupts.length === 0) {
      setCurrentInterrupt(undefined)
      return
    }

    // If still have the same currentInterrupt, don't do anything.
    if (currentInterrupt && currentInterrupt.id === interrupts[0].id) {
      return
    }

    setCurrentInterrupt(interrupts[0])
  }, [currentInterrupt, interrupts])

  const contextValue = {
    currentInterrupt,
    completeCurrentInterrupt,
  }

  return (
    <InterruptContext.Provider value={contextValue}>
      {children}
    </InterruptContext.Provider>
  )
}

export default InterruptProvider
