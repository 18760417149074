import React from 'react'
import { Variant } from 'framer-motion'
import { Route, RouteProps } from 'react-router'

export interface RouteTransitionProps extends RouteProps {}

// TODO: Investigate using variants to coordinate animations among children
export type RouteVariantLabels = 'initial' | 'enter' | 'exit'
export type RouteAnimationVariants = {
  [key in RouteVariantLabels]: Variant
}

export const routeVariants: RouteAnimationVariants = {
  initial: {},
  enter: {},
  exit: {},
}

// TODO: Is this component necessary?
// Idea: Perhaps we could have a prop to enable/disable header transitions?
const RouteTransition: React.FC<RouteTransitionProps> = ({
  children,
  component: Component,
  ...rest
}) => {
  return (
    <Route {...rest}>
      {/* @ts-expect-error TODO: figure out why this is erroring*/}
      <Component />
    </Route>
  )
}

export default RouteTransition
