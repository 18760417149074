import { QueryClient, QueryKey } from 'react-query'

export interface OptimisticUpdateContext<T> {
  previous: T
  queryKey: QueryKey
}

/**
 * A helper for applying a simple optimistic update strategy for react-query.
 * Returns an object with `onMutate`, `onError`, and `onSettled` functions
 * that you can then pass directly to `useMutation`'s options arg.
 *
 * @note To pass these directly to `useMutation`, you must add the last
 * generic argument to it: `useMutation<Data, Error, Variables, Context>`
 *
 * The strategy revolves around the @param targetQueryKey and the vars
 * passed to `useMutation`'s `mutate` function:
 *
 * 1) Save the cached value at `targetQueryKey`
 * 2) Set the `targetQueryKey` cache to the vars passed to the `mutate` function
 * 3) Wait for the mutation to resolve
 * 4) If error, reset the cache at `targetQueryKey`
 * 5) If success, keep the cache (it will be overriden by whatever `mutate`
 * returned with), and invalidate any queries for `targetQueryKey`
 *
 * @example
 * const optimisticUpdateOptions = simpleOptimisticUpdater(queryClient, (todo) => ['todos', todo.id])
 * const { mutateAsync: updateTodo } = useMutation(
 *    (vars) => request.put(`/todos/${vars.todoId}`, { body: vars.isComplete }),
 *    optimisticUpdateOptions
 * )
 * updateTodo({ todoId: 123, isComplete: true })
 * // `updateTodo` will now optimistically update the cache for `['todos', 123]`
 * // and invalidate any queries that are subscribed to that cache key, causing
 * // them to refetch.
 *
 * @param queryClient The react-query client that gives access to the cache, etc.
 * @param targetQueryKey The QueryKey that identifies the cache to snapshot then
 * replace with our incoming value, then invalidate once the mutation is settled.
 */
export function simpleOptimisticUpdater<T>(
  queryClient: QueryClient,
  targetQueryKey: QueryKey | ((arg: T) => QueryKey),
) {
  return {
    // When mutate is called
    onMutate: async (next: T) => {
      const queryKey =
        typeof targetQueryKey === 'function'
          ? targetQueryKey(next)
          : targetQueryKey
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey)

      // Snapshot the previous value
      const previous = queryClient.getQueryData(queryKey) as T

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, next)

      // Return a context with the snapshotted value
      return {
        previous,
        queryKey,
      }
    },
    // If the mutation fails, use the context we returned above to reset the cache
    onError: (
      _err: unknown,
      _next: T,
      context?: OptimisticUpdateContext<T>,
    ) => {
      context && queryClient.setQueryData(context.queryKey, context.previous)
    },
    // Always refetch after error or success
    onSettled: (
      _data: unknown,
      _error: unknown,
      _next: T,
      context?: OptimisticUpdateContext<T>,
    ) => {
      context && queryClient.invalidateQueries(context.queryKey)
    },
  }
}
