import { capitalize } from './strings'

export type DailyJournalPainScore = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
export type WeeklyAssessmentScoreSeverity =
  | 'normal'
  | 'mild'
  | 'moderate'
  | 'severe'

export type WeeklyAssessmentScoreRangeMap = {
  /**
   * The values assigned to these keys are the exclusive upper bound for
   * assigning that severity.
   *
   * @example
   * const map: ScoreRangeMap = { mild: 50 }
   * // indicates that a score is 'Mild' if it is below 50
   */
  [key in 'min' | 'max' | WeeklyAssessmentScoreSeverity]: number
}

interface PainColor {
  /** The primary color for this pain level, in RGB format */
  main: string
  /** The primary color for this pain level, in RGB format */
  ambient: string
}

export const intensityScoreMap: WeeklyAssessmentScoreRangeMap = {
  min: 15,
  max: 85,
  normal: 55,
  mild: 60,
  moderate: 70,
  severe: 85,
}

export const interferenceScoreMap: WeeklyAssessmentScoreRangeMap = {
  min: 15,
  max: 85,
  normal: 55,
  mild: 60,
  moderate: 70,
  severe: 85,
}

const NORMAL_PAIN_COLOR: PainColor = {
  main: 'rgb(38, 118, 44)',
  ambient: 'rgb(219, 226, 220)',
}
const MILD_PAIN_COLOR: PainColor = {
  main: 'rgb(217, 88, 8)',
  ambient: 'rgb(239, 225, 217)',
}
const MODERATE_PAIN_COLOR: PainColor = {
  main: 'rgb(230, 15, 14)',
  ambient: 'rgb(237, 217, 218)',
}
const SEVERE_PAIN_COLOR: PainColor = {
  main: 'rgb(164, 12, 9)',
  ambient: 'rgb(231, 216, 216)',
}

const BLACK_TEXT = 'rgb(71, 56, 56)'
export const dailyPainScoreColorMap: Record<
  DailyJournalPainScore,
  PainColor
> = {
  0: {
    main: BLACK_TEXT,
    ambient: 'rgb(208, 227, 206)',
  },
  1: {
    main: BLACK_TEXT,
    ambient: 'rgb(212, 232, 214)',
  },
  2: {
    main: BLACK_TEXT,
    ambient: 'rgb(216, 232, 220)',
  },
  3: {
    main: BLACK_TEXT,
    ambient: 'rgb(229, 231, 217)',
  },
  4: {
    main: BLACK_TEXT,
    ambient: 'rgb(238, 230, 191)',
  },
  5: {
    main: BLACK_TEXT,
    ambient: 'rgb(251, 233, 184)',
  },
  6: {
    main: BLACK_TEXT,
    ambient: 'rgb(250, 222, 182)',
  },
  7: {
    main: BLACK_TEXT,
    ambient: 'rgb(249, 212, 181)',
  },
  8: {
    main: BLACK_TEXT,
    ambient: 'rgb(248, 201, 180)',
  },
  9: {
    main: BLACK_TEXT,
    ambient: 'rgb(247, 191, 179)',
  },
  10: {
    main: BLACK_TEXT,
    ambient: 'rgb(247, 183, 179)',
  },
}

export const weeklyAssessmentScoreColorMap: Record<
  WeeklyAssessmentScoreSeverity,
  PainColor
> = {
  normal: NORMAL_PAIN_COLOR,
  mild: MILD_PAIN_COLOR,
  moderate: MODERATE_PAIN_COLOR,
  severe: SEVERE_PAIN_COLOR,
}

export const dailyJournalScoreToColor = (score: DailyJournalPainScore) =>
  dailyPainScoreColorMap[score]

export const weeklyAssessmentScoreToColor = (
  map: WeeklyAssessmentScoreRangeMap,
  score: number,
) => weeklyAssessmentScoreColorMap[severityForScore(map, score)]

export const scoreToLabel = (
  map: WeeklyAssessmentScoreRangeMap,
  score: number,
) => capitalize(severityForScore(map, score))

export const severityForScore = (
  { min, normal, mild, moderate, severe }: WeeklyAssessmentScoreRangeMap,
  score: number,
): WeeklyAssessmentScoreSeverity => {
  switch (true) {
    case isWithinRange(min - 1, normal, score):
      return 'normal'
    case isWithinRange(normal, mild, score):
      return 'mild'
    case isWithinRange(mild, moderate, score):
      return 'moderate'
    case isWithinRange(moderate, severe, score):
      return 'severe'
    default:
      throw new Error(`No known severity for score: ${score}`)
  }
}

const isWithinRange = (min: number, max: number, value: number) => {
  return value > min && value <= max
}
