import React, { useEffect, useMemo, useState } from 'react'
import {
  ICarePlan,
  ICoding,
  IQuestionnaireResponse,
  IQuestionnaireResponse_Answer,
  IQuestionnaireResponse_Item,
  IValueSet,
  IValueSet_Concept,
  QuestionnaireResponseStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Box,
  Button,
  H2,
  HeaderBar,
  HeaderBarButton,
  makeStyles,
} from '@perk-ui/core'
import { format, isToday, startOfDay, subDays } from 'date-fns'
import { useQueryClient } from 'react-query'

import { ChecklistItem } from '../../components/Checklist'
import { Header, Page } from '../../components/Layout'
import SectionTitle from '../../components/SectionTitle'
import apm from '../../config/analytics'
import config from '../../config/app-config'
import { useHubId } from '../../features/auth/AuthContext'
import usePrimaryCarePlan from '../../features/Fhir/CarePlan/usePrimaryCarePlan'
import {
  ACTIVITIES_LINK,
  BODY_MAP_LINK,
  getDailyJournalPainScore,
  NOTES_LINK,
  PAIN_SCORE_LINK,
  SENTIMENT_LINK,
} from '../../features/Fhir/dailyJournalUtils'
import { latestQrOnDay } from '../../features/Fhir/utils'
import useValueSets from '../../features/Fhir/ValueSet/useValueSets'
import { useInterruptManager } from '../../features/interrupts/InterruptProvider'
import { INITIAL_JOURNAL_PROMPT } from '../../features/interrupts/interrupts'
import buildQuestionnaireResponse from '../../features/survey/buildQuestionnaireResponse'
import {
  PERK_HEALTH_SYSTEM,
  questionnaireMap,
  SENTIMENT_VALUESET_ID,
} from '../../features/survey/questionnaire-data'
import useQRSearch from '../../features/survey/useQRSearch'
import useQuestionnaire from '../../features/survey/useQuestionnaire'
import useQuestionnaireResponse from '../../features/survey/useQuestionnaireResponse'
import useSubmitQr from '../../features/survey/useSubmitQr'
import { findBy } from '../../utils/arrays'
import { toIsoStringWithTz } from '../../utils/dates'
import {
  DailyJournalPainScore,
  dailyJournalScoreToColor,
} from '../../utils/painScores'
import { useHistory } from '../../utils/useHistory'
import { useQueryParam } from '../../utils/useQueryParam'
import Activities from './components/Activities'
import ActivityInfo from './components/ActivityInfo'
import BodyMap, { BodyMapPart } from './components/BodyMap'
import Notes from './components/Notes'
import PastDailyJournalSelector from './components/PastDailyJournalSelector'
import EmojiPicker from './components/Sentiments'
import PainScoreHero from './PainScoreHero'
import PainSlider from './PainSlider'

const DEFAULT_PAIN_SCORE = 5

const useStyles = makeStyles(
  (theme) => ({
    title: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'space-around',
      paddingTop: `calc(env(safe-area-inset-top) + ${theme.spacing(2)}px)`,
    },
    page1Container: {
      display: 'flex',
      flexFlow: 'column',
      height: '100vh',
    },
    questionContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      transition: 'background-color 0.1s ease',
      height: '100%',
    },
    sliderContainer: {
      flex: 1,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      transition: 'background-color 0.1s ease',
    },
  }),
  { name: 'DailyJournal' },
)

const DailyJournal: React.FC = () => {
  const classes = useStyles()
  const { push, replace } = useHistory()
  const hubId = useHubId()
  const queryClient = useQueryClient()
  const { currentInterrupt, completeCurrentInterrupt } = useInterruptManager()

  const [isPage1, setIsPage1] = useState(true)
  const [authoringDate, setAuthoringDate] = useState<Date>(new Date())
  const [sliderValue, setSliderValue] = useState<DailyJournalPainScore>(
    DEFAULT_PAIN_SCORE,
  )
  const [selectedBodyParts, setSelectedBodyParts] = useState<BodyMapPart[]>([])
  const [selectedSentiments, setSelectedSentiments] = useState<
    IValueSet_Concept[]
  >([])
  const [otherActivity, setOtherActivity] = useState<string>('')
  const [notesValue, setNotesValue] = useState<string>('')
  const { mutateAsync: submitQr, isLoading: isSubmitQrLoading } = useSubmitQr()

  // Fetch the DailyJournal Questionnaire and scaffold
  // a QuestionnaireResponse for this patient
  const { data: questionnaire } = useQuestionnaire(
    questionnaireMap.DailyPainJournal,
  )
  const djQr = useMemo(
    () =>
      questionnaire &&
      buildQuestionnaireResponse({
        patientId: hubId,
        containedQuestionnaire: questionnaire,
      }),
    [hubId, questionnaire],
  )

  // Fetch CarePlan Activities
  const { data: primaryCarePlan } = usePrimaryCarePlan()
  const carePlanStartDate = new Date(primaryCarePlan?.period?.start || '')
  const planActivities = checklistItemsFromCarePlan(primaryCarePlan)
  useEffect(() => {
    setActivities(planActivities)
    // eslint-disable-next-line
  }, [JSON.stringify(planActivities)])

  // If we've selected an already-answered DailyJournal entry,
  // fetch the QR and set the form's values
  const qId = useQueryParam('id')
  const { data: activeQr } = useQuestionnaireResponse(
    { id: qId },
    {
      enabled: !!qId,
    },
  )

  // When the activeQr changes, process it and update form values.
  // Either reset data, or load the activeQr's data into the for values
  useEffect(() => {
    if (!activeQr) {
      // Reset form values
      setSliderValue(DEFAULT_PAIN_SCORE)
      setActivities(planActivities)
      setSelectedBodyParts([])
      setOtherActivity('')
      setSelectedSentiments([])
      setNotesValue('')
      return
    }

    // Set the authored date to that of the questionnaire we're viewing
    const authored = activeQr.authored
    authored && setAuthoringDate(new Date(authored))

    for (const item of activeQr.item || []) {
      switch (item.linkId) {
        case PAIN_SCORE_LINK: {
          const score = item.answer?.[0].valueInteger || DEFAULT_PAIN_SCORE
          setSliderValue(score as DailyJournalPainScore)
          break
        }
        case ACTIVITIES_LINK: {
          const otherAnswer = item.answer?.find((a) => a.valueString)
          if (otherAnswer) {
            setOtherActivity(otherAnswer.valueString as string)
          }

          const savedQrActivities = (item.answer || [])
            .filter((answer) => !answer.valueString)
            .map(
              (answer) =>
                ({
                  id: answer.valueCoding?.code,
                  label: answer.valueCoding?.display,
                  selected: true,
                } as ChecklistItem),
            )

          const nextActivities = planActivities
          for (const act of savedQrActivities) {
            // merge the saved Activites with the possible CarePlan Activities.
            const carePlanActivity = findBy('id', act.id, planActivities)
            if (carePlanActivity) {
              carePlanActivity.selected = true
            } else {
              nextActivities.push(act)
            }
          }

          setActivities(nextActivities)
          break
        }
        case SENTIMENT_LINK: {
          const sentiments = item.answer
            ?.map((a) => a.valueCoding as ICoding)
            .filter(Boolean)
            .map(({ system, ...rest }) => rest) // strip `system` from coding
          setSelectedSentiments(sentiments || [])
          break
        }
        case BODY_MAP_LINK: {
          const bodyParts = (item.answer || []).map((answer) => ({
            id: answer.valueString,
          })) as BodyMapPart[]
          return setSelectedBodyParts(bodyParts)
        }
        case NOTES_LINK: {
          const note = item.answer?.[0].valueString
          setNotesValue(note || '')
          break
        }
      }
    }
    // eslint-disable-next-line
  }, [activeQr?.id])

  const sevenDaysAgo = startOfDay(subDays(new Date(), 7))
  const pastDailyJournals = useQRSearch({
    questionnaire: questionnaireMap.DailyPainJournal,
    from: sevenDaysAgo,
    to: new Date(),
  })
  useEffect(() => {
    const data = pastDailyJournals.data || []
    const todaysQr = latestQrOnDay(new Date(), data)
    if (todaysQr && isPage1) {
      const painScore = getDailyJournalPainScore(todaysQr)
      painScore && setSliderValue(painScore)
      handleDaySelect(new Date(), todaysQr.id)
    }
  }, [pastDailyJournals.data, isPage1])

  // ValueSet queries
  const valueSetUrls = (questionnaire?.item || [])
    .map((i) => i.answerValueSet)
    .filter(Boolean) as string[]
  const valueSetQueries = useValueSets(valueSetUrls)
  const valueSets = valueSetQueries
    .map((q) => q.data)
    .filter(Boolean) as IValueSet[]
  const sentimentVs = findBy('id', SENTIMENT_VALUESET_ID, valueSets)
  const sentiments =
    sentimentVs?.compose?.include.flatMap(
      (s) => s.concept as IValueSet_Concept[],
    ) || []

  // Questionnaire Items
  const painScoreItem = findBy('id', 'PAIN_SCORE', questionnaire?.item)
  const activitiesItem = findBy('id', 'ACTIVITIES', questionnaire?.item)
  const sentimentItem = findBy('id', 'SENTIMENT', questionnaire?.item)
  const bodyMapItem = findBy('id', 'BODY_MAP', questionnaire?.item)
  const notesItem = findBy('id', 'NOTES', questionnaire?.item)

  // Activities
  const [activities, setActivities] = useState<ChecklistItem[]>([])
  const handleActivitySelect = (id: string) => {
    const nextItems = activities.map((item) => {
      if (item.id === id) {
        item.selected = !item.selected
      }
      return item
    })
    setActivities(nextItems)
  }

  const handleDaySelect = (day: Date, id?: string) => {
    setAuthoringDate(day)
    replace({
      pathname: '/daily-journal',
      search: id ? `?id=${id}` : undefined,
    })
  }

  const handleQrSubmit = () => {
    const items = [] as IQuestionnaireResponse_Item[]

    if (sliderValue && painScoreItem) {
      items.push({
        linkId: painScoreItem.linkId,
        answer: [{ valueInteger: sliderValue }],
      })
    }

    if (activities.length && activitiesItem) {
      const answers: IQuestionnaireResponse_Answer[] = activities
        .filter((item) => item.selected)
        .map((item) => ({
          valueCoding: {
            system: PERK_HEALTH_SYSTEM,
            code: item.id,
            display: item.label,
          },
        }))

      if (otherActivity) answers.push({ valueString: otherActivity })

      items.push({
        linkId: activitiesItem.linkId,
        answer: answers,
      })
    }

    if (selectedSentiments.length && sentimentItem) {
      const answers = selectedSentiments.map((s) => ({
        valueCoding: {
          system: sentimentVs?.compose?.include[0].system,
          code: s.code,
          display: s.display,
        },
      }))

      items.push({
        linkId: sentimentItem.linkId,
        answer: answers,
      })
    }

    if (bodyMapItem) {
      const answers = selectedBodyParts.map((part) => ({
        valueString: part.id,
      }))
      items.push({
        linkId: bodyMapItem.linkId,
        answer: answers,
      })
    }

    if (notesValue && notesItem) {
      items.push({
        linkId: notesItem.linkId,
        answer: [
          {
            valueString: notesValue,
          },
        ],
      })
    }

    submitQr({
      ...(djQr as IQuestionnaireResponse),
      item: items,
      authored: toIsoStringWithTz(authoringDate),
      status: QuestionnaireResponseStatusKind._completed,
    })
      .then(() => {
        if (currentInterrupt?.kind === INITIAL_JOURNAL_PROMPT) {
          return completeCurrentInterrupt()
        }
      })
      .then(() => {
        // TODO Remove these callbacks once we have faster job processing
        // or a websocket-based Feed connection
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;[500, 1500, 3000].forEach((wait) => {
          setTimeout(() => {
            // mark feed cache as stale to trigger a refetch
            queryClient.invalidateQueries(['feed_system/feeds', 'homefeed'])
          }, wait)
        })

        push(config.authenticatedRootUrl)
      })
      .catch((error: Error) => {
        push(config.authenticatedRootUrl)
        apm.captureError(error)
      })
  }

  const dayName = isToday(authoringDate)
    ? 'Today'
    : format(authoringDate, 'EEEE')

  return (
    <Page
      title="Taper App - Daily Journal"
      fullHeight
      header={
        <Header withNav={false} withSafeInset={false}>
          {isPage1 ? null : (
            <HeaderBar
              style={{ marginTop: 'env(safe-area-inset-top)' }}
              titleContent={`My Pain ${dayName}`}
              left={
                <HeaderBarButton
                  variant="back"
                  onClick={() => setIsPage1(true)}
                />
              }
            />
          )}
        </Header>
      }
    >
      <Box
        style={{
          backgroundColor: isPage1
            ? dailyJournalScoreToColor(sliderValue).ambient
            : undefined,
        }}
      >
        {questionnaire &&
          pastDailyJournals.isFetched &&
          (isPage1 ? (
            <Box className={classes.page1Container}>
              <Box p={2} className={classes.title}>
                <PastDailyJournalSelector
                  pastDailyJournals={pastDailyJournals.data || []}
                  selectedDate={authoringDate}
                  selectedScore={sliderValue}
                  disableBefore={carePlanStartDate}
                  onDaySelect={handleDaySelect}
                />
              </Box>
              <Box className={classes.sliderContainer}>
                <Box py={3}>
                  <H2 gutterBottom>My Pain {dayName}</H2>
                </Box>

                <Box flex="1" pb={3}>
                  <PainSlider value={sliderValue} onChange={setSliderValue} />
                </Box>
                <Box
                  px={3}
                  pt={1}
                  pb={3}
                  style={{
                    width: '100%',
                    marginBottom: 'env(safe-area-inset-bottom)',
                  }}
                >
                  <Button
                    fullWidth
                    onClick={() => {
                      setIsPage1(false)
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            //////////////
            /// Page 2
            //////////////
            <Box p={3} className={classes.questionContainer}>
              {sliderValue && <PainScoreHero score={sliderValue} />}

              {activitiesItem && (
                <>
                  <Box
                    pt={3}
                    pb={2}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <SectionTitle py={0} title="What did you do today?" />
                    <ActivityInfo />
                  </Box>
                  <Activities
                    activities={activities}
                    onActivitySelect={handleActivitySelect}
                    otherValue={otherActivity}
                    onOtherChange={setOtherActivity}
                  />
                </>
              )}

              {bodyMapItem && (
                <>
                  <SectionTitle title="Where is your pain?" />
                  <BodyMap
                    selectedParts={selectedBodyParts}
                    onChange={(parts) => setSelectedBodyParts(parts)}
                  />
                </>
              )}

              {sentimentItem && (
                <>
                  <SectionTitle title="How do you feel?" />
                  <EmojiPicker
                    item={sentimentItem}
                    sentiments={sentiments}
                    value={selectedSentiments}
                    onChange={setSelectedSentiments}
                  />
                </>
              )}

              {notesItem && (
                <>
                  <SectionTitle title="Notes" />
                  <Notes
                    item={notesItem}
                    value={notesValue}
                    onChange={(e) => setNotesValue(e.target.value)}
                  />
                </>
              )}

              <Button
                loading={isSubmitQrLoading}
                fullWidth
                onClick={handleQrSubmit}
                style={{ marginTop: 24 }}
              >
                Save
              </Button>
            </Box>
          ))}
      </Box>
    </Page>
  )
}

const checklistItemsFromCarePlan = (plan?: ICarePlan) =>
  (plan?.activity
    ?.map((a) => a.detail)
    .map((d) => d?.code)
    .filter(Boolean)
    .map((code) => ({
      id: code?.coding?.[0].code,
      label: code?.text,
      selected: false,
    }))
    .filter((item) => item.id && item.label) as ChecklistItem[]) || []

export default DailyJournal
