import React from 'react'
import { Card, CardContent, Stack } from '@perk-ui/core'
import { format } from 'date-fns'

import DailyJournalScoreList from './custom-sections/DailyJournalScoreList'
import { DailyJournalItem } from './FeedResponse'

export interface DailyJournalFeedItemProps {
  item: DailyJournalItem
}

const DailyJournalFeedItem: React.FC<DailyJournalFeedItemProps> = ({
  item,
}) => {
  const { title, data } = item

  const feedTitle =
    format(new Date(), `yyyy-'W'II`) === item.week_date
      ? 'New Daily Pain Score'
      : title

  return (
    <Card style={{ marginBottom: 24 }}>
      <CardContent>
        <Stack title={feedTitle} />
        <DailyJournalScoreList data={data} />
      </CardContent>
    </Card>
  )
}

export default DailyJournalFeedItem
