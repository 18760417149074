import React from 'react'
import { tail } from '@perk-ui/core'

import DailyJournalScoreList from '../../../features/feed/custom-sections/DailyJournalScoreList'
import { DailyJournalScore } from '../../../features/feed/FeedResponse'
import { getDailyJournalPainScore } from '../../../features/Fhir/dailyJournalUtils'
import { questionnaireMap } from '../../../features/survey/questionnaire-data'
import useQRSearch from '../../../features/survey/useQRSearch'
import { DAYS_THIS_WEEK, toDayName } from '../../../utils/dates'

export interface DailyJournalScoresProps {}

const DailyJournalScores: React.FC<DailyJournalScoresProps> = () => {
  const { data = [] } = useQRSearch({
    questionnaire: questionnaireMap.DailyPainJournal,
    from: DAYS_THIS_WEEK[0],
    to: new Date(),
  })

  const painScores: DailyJournalScore[] = data
    .filter((dj) => {
      if (!dj.authored) return false
      const d = new Date(dj.authored)
      return d >= DAYS_THIS_WEEK[0] && d <= tail(DAYS_THIS_WEEK)
    })
    .map((dj) => ({
      value: getDailyJournalPainScore(dj) ?? 0,
      day: toDayName(new Date(dj.authored as string)),
    }))

  return <DailyJournalScoreList data={{ scores: painScores }} />
}

export default DailyJournalScores
