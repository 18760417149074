import React from 'react'
import { Body1,Box, H5 } from '@perk-ui/core'

export interface SectionProps {
  title: string
}

const Section: React.FC<SectionProps> = ({ title, children }) => (
  <Box pb={2}>
    <H5 gutterBottom>{title}</H5>
    <Body1 component="span">{children}</Body1>
  </Box>
)

export default Section
