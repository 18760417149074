import React from 'react'
import { ICarePlan, IDosage } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Body1, Box, H5 } from '@perk-ui/core'
import { format, isValid } from 'date-fns'

import { getTaperStepMedications } from '../../../features/Fhir/CarePlan/taperStepUtils'
import { capitalize } from '../../../utils/strings'

interface TaperStepMedications {
  taperStep: ICarePlan
  withDate?: boolean
}

const TaperStepMedications: React.FC<TaperStepMedications> = ({
  taperStep,
  withDate = true,
}) => {
  if (!taperStep.contained) return null
  const meds = getTaperStepMedications(taperStep)

  const startDate = new Date(taperStep.period?.start || '')
  const endDate = new Date(taperStep.period?.end || '')
  const validDateRange = isValid(startDate) && isValid(endDate)
  const dateStr =
    validDateRange &&
    `${format(startDate, 'MMM d, yyyy')} - ${format(endDate, 'MMM d, yyyy')}`

  return (
    <>
      {meds.map((med, idx) => {
        const medName = med.medicationCodeableConcept?.coding?.[0].display
        const isLastMed = idx === meds.length - 1

        return (
          <>
            {idx === 0 && withDate && <H5 gutterBottom>{dateStr}</H5>}
            <Box mb={1}>
              <Body1>{medName}</Body1>
            </Box>
            <Body1 paragraph={!isLastMed}>
              {(med.dosageInstruction || []).map((instruction) =>
                getDosageText(instruction),
              )}
            </Body1>
          </>
        )
      })}
    </>
  )
}

const getDosageText = (instruction: IDosage) => {
  const timing = (instruction.timing?.code?.coding || [])
    .map((timing) => timing.display)
    .map((str) => str?.toLowerCase())
    .join(' ')
  const dose = (instruction.doseAndRate || [])
    .map((dar) => {
      const value = dar.doseQuantity?.value
      let unit = dar.doseQuantity?.unit

      if (!value || !unit) return ''
      if (unit === 'tab') unit = 'tablet'
      if (value > 1) unit += 's'

      return `${value} ${unit}`
    })
    .join(' ')

  const asNeeded = instruction.asNeededBoolean
  const asNeededText = asNeeded ? `${dose || timing ? 'or ' : ''}as needed` : ''

  const text = [dose, timing, asNeededText].join(' ').trim()

  return capitalize(text)
}

export default TaperStepMedications
