import React, { useState } from 'react'
import {
  CarePlan_DetailStatusKind,
  ICarePlan,
  IValueSet_Concept,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { Body1, Box, H1, HeaderBar, HeaderBarButton } from '@perk-ui/core'

import { Footer, Header, Page } from '../../components/Layout'
import useCarePlanUpdate from '../../features/Fhir/CarePlan/useCarePlanUpdate'
import usePrimaryCarePlan from '../../features/Fhir/CarePlan/usePrimaryCarePlan'
import useValueSets from '../../features/Fhir/ValueSet/useValueSets'
import {
  ACTIVITIES_VALUESET_URL,
  PERK_HEALTH_SYSTEM,
} from '../../features/survey/questionnaire-data'
import { findBy } from '../../utils/arrays'
import { useHistory } from '../../utils/useHistory'
import ActivityCard from './components/ActivityCard'
import ActivityHero from './components/ActivityHero'

const ManageActivities: React.FC = () => {
  const { goBack } = useHistory()
  const [pendingCodes, setPendingCodes] = useState<string[]>([])

  // Activities ValueSet - the list of all possible activities
  const [activityValueSet] = useValueSets([ACTIVITIES_VALUESET_URL])
  const perkActivityConcepts =
    findBy(
      'system',
      PERK_HEALTH_SYSTEM,
      activityValueSet.data?.compose?.include,
    )?.concept || []

  // The Primary CarePlan contains the user's tracked Activities
  const { data: primaryCarePlan } = usePrimaryCarePlan()
  const currentActivities = (primaryCarePlan?.activity || [])
    .map((a) => a.detail)
    .filter((a) => a?.kind === 'Task')
    .map((a) => a?.code)
    .map((code) => code?.coding?.[0].code)
    .filter(Boolean) as string[]

  const { mutateAsync: updateCarePlan } = useCarePlanUpdate(primaryCarePlan?.id)
  const handleConceptClick = (
    { code, display }: IValueSet_Concept,
    isCurrentlyActive: boolean,
  ) => {
    const carePlanClone: ICarePlan = JSON.parse(JSON.stringify(primaryCarePlan))
    if (!code || !display) {
      throw new Error(
        `Failed to add/remove Activity: code and display must be present. code: ${code}, display: ${display}`,
      )
    }

    if (isCurrentlyActive) {
      carePlanClone.activity = carePlanClone.activity?.filter(
        (a) => a.detail?.code?.coding?.[0].code !== code,
      )
    } else {
      carePlanClone.activity = [
        ...(carePlanClone.activity || []),
        {
          detail: {
            kind: 'Task',
            status: CarePlan_DetailStatusKind._inProgress,
            code: {
              coding: [
                {
                  system: PERK_HEALTH_SYSTEM,
                  code,
                  display,
                },
              ],
              text: display,
            },
          },
        },
      ]
    }

    setPendingCodes((prev) => [...prev, code])
    updateCarePlan(carePlanClone).finally(() => {
      setPendingCodes((prev) =>
        prev.filter((pendingCode) => pendingCode !== code),
      )
    })
  }

  return (
    <Page
      title="Taper App - Pain Reduction Options"
      header={
        <Header withNav={false}>
          <HeaderBar
            titleContent="Pain Reduction Options"
            left={<HeaderBarButton variant="back" onClick={goBack} />}
          />
        </Header>
      }
      footer={<Footer withNav={false}></Footer>}
    >
      <>
        <ActivityHero />
        <Box p={3}>
          <H1 gutterBottom>Ways to manage your pain.</H1>
          <Body1>
            There are many ways to manage chronic pain. The options below can be
            added to My Activities so you can track them in your Daily Journal.
          </Body1>
          {perkActivityConcepts.map((concept) => (
            <ActivityCard
              key={concept.code}
              concept={concept}
              isActive={currentActivities.includes(concept.code || '')}
              loading={pendingCodes.includes(concept.code || '')}
              onClick={handleConceptClick}
            />
          ))}
        </Box>
      </>
    </Page>
  )
}

export default ManageActivities
