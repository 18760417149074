import { EnvConfig, MainConfig } from './config-interfaces'
import b41Config from './env-config.b41'
import devConfig from './env-config.development'
import p41Config from './env-config.p41'
import stagingConfig from './env-config.staging'

let envConfig: EnvConfig

switch (process.env.REACT_APP_STAGE) {
  // case 'prod':
  //   config = prodConfig
  //   break
  case 'dev':
    envConfig = devConfig
    break
  case 'staging':
    envConfig = stagingConfig
    break
  case 'b41':
    envConfig = b41Config
    break
  case 'p41':
    envConfig = p41Config
    break
  default:
    envConfig = devConfig
}

const config: MainConfig = {
  authenticatedRootUrl: '/home',
  unAuthenticatedRootUrl: '/auth',
  supportEmail: 'opioid-taper-research@medstar.net',
  apmUrl: '',
  ...envConfig,
}

export default config
