import React from 'react'
import { IQuestionnaireResponse_Item } from '@ahryman40k/ts-fhir-types/lib/R4'
import { makeStyles, Paper, TextFieldProps } from '@perk-ui/core'

import Textbox from '../../../components/Textbox'
import { findExtension } from '../../../features/Fhir/utils'

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: theme.perk.boxShadow.card,
    width: '100%',
  },
}))

interface NotesProps extends Pick<TextFieldProps, 'value' | 'onChange'> {
  item: IQuestionnaireResponse_Item
}

const Notes: React.FC<NotesProps> = ({ item, value, onChange }) => {
  const classes = useStyles()
  const placeholder =
    findExtension(
      'https://www.hl7.org/fhir/extension-entryformat',
      item.extension || [],
    )?.valueString || 'Anything else about your day?'

  return (
    <Paper className={classes.paper}>
      <Textbox
        multiline
        rows={3}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </Paper>
  )
}

export default Notes
