import React from 'react'
import { Box, Card, CardActionArea, H5,Link } from '@perk-ui/core'
import { ExternalLink } from 'react-feather'

import { LinkItem } from '../patient-resource-data'

export interface BodyLinkProps {
  value: LinkItem['value']
}

const BodyLink: React.FC<BodyLinkProps> = ({ value }) => {
  return (
    <Card style={{ marginTop: 16, marginBottom: 16 }}>
      <CardActionArea href={value} rel="noopener" target="_blank">
        <Box
          p={2}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Link component="div" style={{ overflowWrap: 'anywhere' }}>
            <H5 style={{ fontWeight: 'normal' }}>{value}</H5>
          </Link>

          <Link component="div" style={{ marginLeft: 8 }}>
            <ExternalLink />
          </Link>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default BodyLink
