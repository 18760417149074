/**
 * Returns all the DOM Elements that intersect with an X, Y coordinate on the
 * screen. Uses `visibility` manipulation to drill past overlapping elements
 *
 * This can be particularly useful for making a clickable SVG map, where SVG
 * paths frequently overlap.
 *
 *
 * @see https://stackoverflow.com/a/27884653/8222788
 */
export const allElementsFromPoint = (x: number, y: number) => {
  const elements = [] as HTMLElement[]
  const oldVisibility = [] as string[]

  // eslint-disable-next-line
  while (true) {
    const element = document.elementFromPoint(x, y) as HTMLElement | null
    if (!element || element === document.documentElement) {
      break
    }

    elements.push(element)

    oldVisibility.push(element.style.visibility)

    // Temporarily hide the element (without changing the layout)
    element.style.visibility = 'hidden'
  }

  for (let k = 0; k < elements.length; k++) {
    elements[k].style.visibility = oldVisibility[k]
  }

  return elements
}
