import React, { useEffect } from 'react'
import { Box, HeaderBar } from '@perk-ui/core'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

import apm from '../config/analytics'
import ErrorAlertCard from './ErrorAlertCard'
import { Header, Page } from './Layout'

export interface AppErrorBoundaryProps {}

const AppErrorBoundary: React.FC<AppErrorBoundaryProps> = ({ children }) => (
  <ErrorBoundary FallbackComponent={ErrorPage}>{children}</ErrorBoundary>
)

const ErrorPage: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  useEffect(() => {
    apm.captureError(error)
  }, [apm, error])

  return (
    <Page
      title="Error"
      header={
        <Header withSafeInset withNav={false}>
          <HeaderBar titleContent="Error" />
        </Header>
      }
    >
      <Box p={3}>
        <ErrorAlertCard error={error} resetErrorBoundary={resetErrorBoundary} />
      </Box>
    </Page>
  )
}

export default AppErrorBoundary
