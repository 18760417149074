import { init as initApm } from '@elastic/apm-rum'

import { getPlatforms } from '../utils/platform'
import config from './app-config'

const apm = initApm({
  serviceName: 'Tapr-CPM',
  serverUrl: config.apmUrl,
  active: process.env.REACT_APP_STAGE === 'p41',
  instrument: true,
  // `eventtarget` intrumentation is replaced by the click event listener below
  disableInstrumentations: ['eventtarget'],
  logLevel: 'debug',
})

// Add our platform labels to all APM transactions.
apm.addLabels({
  platforms: getPlatforms().join(', '),
})

/**
 * This block listens for all `click` events on the window and initiates an
 * apm `transaction` for them. If you'd like to hook into this system, you can
 * add a `name` attribute to any Button or clicked element, or add a `data-rum`
 * tag if you can't add a `name` tag or want to include additional information
 * in the event.
 *
 *
 * Background
 *
 * @elastic/apm-rum's automatic `user-interaction` (or click) tracking doesn't
 * work automatically with React's DOM event system.
 *
 * React attaches a single event listener to the app root and then dispatches
 * events as they come to the appropriate React components, while
 * @elastic/apm-rum patches the `EventTarget` class to listen in on and wrap
 * event callbacks. These don't mesh well, so this prevents
 *
 * In particular, see the below PR comment
 * @see https://github.com/elastic/apm-agent-rum-js/pull/951#issuecomment-800463878
 */
window.addEventListener(
  'click',
  (e) => {
    // Get our clicked element
    const target = (e.target as HTMLElement) || null
    if (!target) return

    // Check if we have a valid element
    if (typeof target.getAttribute !== 'function') return

    // Generate a Transaction Name from the attributes
    // Name is of the form `Click ${tagName}[name]`
    const tagName = target.tagName.toLowerCase()
    const name = target.getAttribute('name')
    const nameStr = name ? `["${name}"]` : ''
    const transactionName = `Click - ${tagName}${nameStr}`

    // Kick start our APM transaction so that Elastic can redefine it
    // Let the transaction be reused by APM so that we can monitor traffic
    const transaction = apm.startTransaction(
      transactionName,
      'user-interaction',
      {
        managed: true,
        canReuse: true,
        // @ts-expect-error: This exists and is improperly typed in the library
        reuseThreshold: 1000,
      },
    )

    // The `data-rum` attribute on the target can be used to pass additional info
    // to APM. i.e. the product id of an item added to a cart.
    // If this is a core piece of info, consider attaching custom labels in your
    // click handler.
    const dataRum = target.getAttribute('data-rum')
    dataRum &&
      transaction?.addLabels({
        data: dataRum,
      })
  },
  true,
)

export default apm
