import React from 'react'
import {
  Avatar,
  Body1,
  Box,
  Card,
  CardActionArea,
  CardContent,
  HorizontalScroll,
  HorizontalScrollItem,
  Icon,
  makeStyles,
} from '@perk-ui/core'
import { Award } from 'react-feather'

import CardImage from '../../../components/CardImage'
import {
  CallToActionItem,
  PatientResourceItem,
} from '../../../features/feed/FeedResponse'
import useFeedSearch from '../../../features/feed/useFeedSearch'
import { useHistory } from '../../../utils/useHistory'
import { resourcesById } from '../../PatientResource/patient-resource-data'

export interface ResourceFeedProps {}

const useStyles = makeStyles((theme) => ({
  thumbsUp: {
    backgroundColor: theme.palette.secondary.main,
  },
  resourceCard: {
    // Make the Cards fill the vertical space they have, in-case other cards are larger
    height: '100%',
  },
  resourceActionArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    height: '100%',
  },
  resourceCardContent: {
    padding: theme.spacing(2, 1.5),
    width: '100%',
  },
}))

const ResourceFeed: React.FC<ResourceFeedProps> = () => {
  const { data } = useFeedSearch({ feedName: 'resources' })
  const classes = useStyles()
  const { push } = useHistory()

  const FAQItem: CallToActionItem = {
    id: 'help-and-faq',
    title: 'FAQ & Help',
    type: 'FeedSystem::CallToActionFeedItem',
    is_read: false,
    created_at: '2021-02-01',
    action: {
      type: 'read',
      title: 'Read',
      path: '/faq',
    },
    media: {
      image: {
        src: `${process.env.PUBLIC_URL}/images/help-and-faq.webp`,
        title: 'title',
        alt: 'alt',
      },
    },
  }

  const items = (data?.feed_items || []).map((item) => {
    const i = item as PatientResourceItem
    const resource = resourcesById[i.resource_id]

    return {
      type: 'FeedSystem::CallToActionFeedItem',
      created_at: 'new Date()',
      is_read: false,
      id: 'foo',
      title: resource.title,
      description: resource.description,
      action: {
        type: 'read',
        title: 'Read',
        path: `/resource/${i.resource_id}`,
      },
      time_helper: i.time_helper,
      media: {
        image: {
          src: `${process.env.PUBLIC_URL}/images/${resource.id}.jpg`,
          title: 'foo',
          alt: resource.title,
        },
      },
    }
  })

  return (
    <HorizontalScroll offsetSpacing={3}>
      {[FAQItem, ...items].map((item, idx) => (
        <HorizontalScrollItem key={idx}>
          <Card className={classes.resourceCard}>
            <CardActionArea
              classes={{ root: classes.resourceActionArea }}
              onClick={() => push(item.action.path)}
            >
              {item.media?.image && (
                <CardImage
                  src={item.media.image.src}
                  alt={item.media.image.alt}
                  height="70px"
                />
              )}
              <CardContent className={classes.resourceCardContent}>
                <Box display="flex">
                  {idx === 0 && (
                    <Box mr={1}>
                      <Avatar size="small" className={classes.thumbsUp}>
                        <Icon color="secondaryText" size="small">
                          <Award />
                        </Icon>
                      </Avatar>
                    </Box>
                  )}
                  <Body1>{item.title}</Body1>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </HorizontalScrollItem>
      ))}
    </HorizontalScroll>
  )
}

export default ResourceFeed
