import React from 'react'
import { Body1, Box, makeStyles, useTheme } from '@perk-ui/core'

import { ReactComponent as HoorayThumbsUpSvg } from '../../../assets/HoorayThumbsUp.svg'

interface MedicationDecreaseGraphProps {
  data: {
    before: {
      value: number
    }
    after: {
      value: number
    }
  }
}

const useStyles = makeStyles((theme) => ({
  root: {},
  textCenter: {
    marginTop: 2, // Give the text a bit of breathing room from the big bar
    textAlign: 'center',
  },
  progressText: {
    display: 'inline-block',
    fontWeight: 'bold',
  },
  thumbsUp: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
    marginLeft: 4, // just a little nudge over
  },
  startBar: {
    height: theme.spacing(8),
    backgroundColor: '#FFE1C7',
  },
  nowBar: {
    backgroundColor: '#FFE1C7',
  },
}))

/**
 * This component encapsulates the common UI for displaying a medication decrease.
 * It is currently used in the MePage and in the MedicationDecreaseFeedItem.
 * The parent component must handle data loading and preparation.
 */
const MedicationDecreaseGraph: React.FC<MedicationDecreaseGraphProps> = ({
  data,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const { before, after } = data
  const diffRatio = (before.value - after.value) / before.value
  const afterBarHeight = (1 - diffRatio) * theme.spacing(8)

  return (
    <>
      <Box display="flex" alignItems="center" py={2}>
        <HoorayThumbsUpSvg
          className={classes.thumbsUp}
          height={24}
          width={17}
        />
        <Body1 className={classes.progressText} color="primary">
          {Math.round(diffRatio * 100)}% Lower
        </Body1>
      </Box>

      <Box display="flex" alignItems="flex-end">
        <Box paddingRight={2} flex="1">
          <div className={classes.startBar}></div>
          <Body1 className={classes.textCenter}>Start</Body1>
        </Box>
        <Box flex="1">
          <div
            className={classes.nowBar}
            style={{
              height: `${afterBarHeight}px`,
            }}
          ></div>
          <Body1 className={classes.textCenter}>Now</Body1>
        </Box>
      </Box>
    </>
  )
}

export default MedicationDecreaseGraph
