import React, { useEffect, useState } from 'react'
import { IQuestionnaireResponse } from '@ahryman40k/ts-fhir-types/lib/R4'
import { DatePicker } from '@perk-ui/core'
import {
  addWeeks,
  endOfMonth,
  getWeeksInMonth,
  isSameDay,
  isSameMonth,
  startOfMonth,
  subWeeks,
} from 'date-fns'

import { getDailyJournalActivities } from '../../../../features/Fhir/dailyJournalUtils'
import { latestQrOnDay } from '../../../../features/Fhir/utils'
import { questionnaireMap } from '../../../../features/survey/questionnaire-data'
import useQRSearch from '../../../../features/survey/useQRSearch'
import ActivityAvatar from './ActivityAvatar'

export interface ActivityCalendarProps {
  onChange: (day: Date, qr?: IQuestionnaireResponse) => void
}

const ActivityCalendar: React.FC<ActivityCalendarProps> = ({ onChange }) => {
  const [value, setValue] = useState(new Date())
  const [month, setMonth] = useState(startOfMonth(new Date()))
  const { data: djQrs = [] } = useQRSearch({
    from: subWeeks(startOfMonth(month), 1),
    to: addWeeks(endOfMonth(month), 1),
    questionnaire: questionnaireMap.DailyPainJournal,
  })

  useEffect(() => {
    // Invoke onChange when we load new data
    if (djQrs.length) {
      const qrOnDay = latestQrOnDay(value, djQrs)
      qrOnDay && onChange(value, qrOnDay)
    }
    // eslint-disable-next-line
  }, [JSON.stringify(djQrs)])

  const handleDayClick = (day: Date, qr?: IQuestionnaireResponse) => () => {
    if (!isSameMonth(day, month)) {
      // Selecting a day that's visible but not in the current month
      // will cause the UI to change months but, for some ungodly reason,
      // not trigger the `onMonthChange` callback - so we need to check
      // it here.
      setMonth(startOfMonth(day))
    }

    setValue(day)
    onChange(day, qr)
  }

  return (
    <DatePicker
      disableToolbar
      disableFuture
      orientation="landscape"
      variant="static"
      minHeight={getWeeksInMonth(month) === 6 ? '247px' : undefined}
      value={value}
      onChange={(_date) => {
        // This callback is required but does not fire when a day from another
        // month is selected. But we need that, so we've added the listener
        // to the `renderDay` result instead.
      }}
      onMonthChange={(date) => {
        setMonth(date)
      }}
      renderDay={(day, selectedDate, _isDayInCurrentMonth, dayComponent) => {
        if (!day) return dayComponent

        const isToday = isSameDay(day, selectedDate)
        const qrOnDay = latestQrOnDay(day, djQrs)
        const activities = qrOnDay ? getDailyJournalActivities(qrOnDay) : []

        return (
          <span
            style={{ display: 'flex' }}
            onClick={handleDayClick(day, qrOnDay)}
          >
            {dayComponent}
            <span
              style={{
                opacity: isToday ? 1 : 0.7,
                position: 'absolute',
                transform: 'translate(6px, 4px)',
              }}
            >
              {activities?.length ? <ActivityAvatar hadActivities /> : null}
            </span>
          </span>
        )
      }}
    />
  )
}

export default ActivityCalendar
