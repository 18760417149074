import React from 'react'
import { Icon, IconSize, makeStyles } from '@perk-ui/core'
import { ArrowRightCircle } from 'react-feather'

import AppLinkButton from '../../../components/AppLinkButton'

interface ReadButtonProps {
  /** Where to link to on click */
  target: string

  /**
   * The size of the contained Icon
   * @default 'small'
   */
  iconSize?: IconSize

  /**
   * Which variant to use: a primary-colored, contained button or a text-like one.
   *
   * This is a pass-through to the MUI Button's `variant` prop.
   */
  variant?: 'contained' | 'text'
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
    '& .MuiButton-endIcon': {
      marginLeft: theme.spacing(1),
    },
  },
}))

const ReadButton: React.FC<ReadButtonProps> = React.memo(
  ({ children, target = '/', iconSize = 'small', variant = 'contained' }) => {
    const classes = useStyles()
    return (
      <AppLinkButton
        className={classes.root}
        size="small"
        variant={variant}
        to={target}
        endIcon={
          <Icon size={iconSize}>
            <ArrowRightCircle size={16} />
          </Icon>
        }
      >
        {children}
      </AppLinkButton>
    )
  },
)

export default ReadButton
