import React, { HTMLAttributes } from 'react'
import { Body2, makeStyles, Mark, Slider } from '@perk-ui/core'

import { DailyJournalPainScore } from '../../utils/painScores'

interface PainSliderProps {
  value: number
  onChange: (val: DailyJournalPainScore) => void
}

const useStyles = makeStyles((theme) => ({
  // Classes are nested here to mimic the Slider's styling nesting and
  // raise CSS specificty
  vertical: {
    // Root
    '&.MuiSlider-root': {
      color: 'rgba(0, 0, 0, 0.3)',
      width: theme.spacing(2),
    },
    '& .MuiSlider-active:not(disabled)': {
      boxShadow: `0px 0px 0px 10px rgba(0,0,0,0.3)`, // Thumb's shadow when pressed
    },
    // Rail
    '& .MuiSlider-rail': {
      borderRadius: 8,
      marginTop: '-12px',
      paddingTop: '12px',
      width: theme.spacing(2),
    },
    // Thumb
    '& .MuiSlider-thumb': {
      boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.5)', // Thumb's shadow when not pressed
      color: theme.palette.primary.main,
      height: 40,
      marginLeft: -12,
      marginBottom: -16,
      width: 40,
      '&:hover': {
        boxShadow: `0px 0px 0px 8px rgba(0,0,0,0.3)`,
      },
    },

    // ValueLabel
    '& .MuiSlider-valueLabel': {
      left: `calc(-50% - ${theme.spacing(2.5)}px)`,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 'bold',
      top: 13,
      '& *': {
        background: 'transparent',
        color: theme.palette.primary.main,
      },
    },
    '& .MuiSlider-mark': {
      // We want to display the markLabels, but not the mark
      display: 'none',
    },
    '& .MuiSlider-markLabel': {
      color: 'rgb(46, 46, 46)',
      left: '48px',
      fontWeight: 'bold',
      whiteSpace: 'normal', // disable the Slider's default `no-wrap`
      width: theme.spacing(10), // provide a width to cause line-wrapping
      '&[data-index="0"]': {
        paddingTop: theme.spacing(1), // scooch the top markLabel down
      },
      '&[data-index="1"]': {
        paddingBottom: theme.spacing(1.5), // scooch the bottom markLabel up
      },
    },
    '& .MuiSlider-markLabelActive': {
      left: '65px', // When active, move the labels to the right
    },
  },
}))

const marks: Mark[] = [
  { value: 10, label: 'Worst Pain Imaginable' },
  { value: 0, label: 'No pain' },
]

const PainSlider: React.FC<PainSliderProps> = ({ value, onChange }) => {
  const classes = useStyles()

  return (
    <>
      {value !== 10 && <MarkNumber translateY="-75%">10</MarkNumber>}
      <Slider
        onChange={(_, val) => onChange(val as DailyJournalPainScore)}
        value={value}
        classes={{
          vertical: classes.vertical,
        }}
        ThumbComponent={ThumbComponent}
        orientation="vertical"
        min={0}
        max={10}
        step={1}
        marks={marks}
        valueLabelDisplay="on"
        track={false}
      />
      {value !== 0 && <MarkNumber translateY="-110%">0</MarkNumber>}
    </>
  )
}

const ThumbComponent: React.FC<HTMLAttributes<HTMLSpanElement>> = (props) => (
  <>
    <span {...props}></span>
    <hr
      style={{
        // `props.style` has attributes that position the element properly
        ...props.style,
        border: '1px solid rgba(255, 255, 255, 0.98)',
        borderRadius: 4,
        marginBottom: 3,
        marginLeft: -2,
        position: 'absolute',
        width: 18,
      }}
    />
  </>
)

const MarkNumber: React.FC<{ translateY: string }> = ({
  children,
  translateY,
}) => (
  <Body2
    style={{
      color: 'rgb(46, 46, 46)',
      fontSize: '1rem',
      position: 'absolute',
      transform: `translate(-50%, ${translateY})`,
      width: '20px',
    }}
  >
    {children}
  </Body2>
)

export default PainSlider
