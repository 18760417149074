import React from 'react'
import { Icon, List, ListItem, makeStyles, Paper } from '@perk-ui/core'
import { CheckCircle, Circle } from 'react-feather'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& $item:last-child': {
      marginBottom: 0,
    },
    '& $item:first-child': {
      marginTop: 0,
    },
  },
  item: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 0,
  },
  itemCard: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    padding: theme.spacing(2),
    textAlign: 'left',
    width: '100%',
    boxShadow: theme.perk.boxShadow.card,
  },
  itemLabel: {
    marginLeft: theme.spacing(2),
    fontSize: '1rem',
  },
}))

export interface ChecklistItem {
  id: string
  label: string

  /** Whether this item is selected or not */
  selected: boolean
}

interface ChecklistProps {
  items: ChecklistItem[]

  /**
   * A callback when an item is selected - required if the
   */
  onItemSelect?: (itemId: string) => void

  /**
   * Whether to display the checklist in a read-only format
   *
   * TODO: Needs more design tweaks
   *
   * @default false
   */
  readOnly?: boolean
}

const Checklist: React.FC<ChecklistProps> = ({
  items,
  onItemSelect,
  readOnly,
}) => {
  const classes = useStyles()

  if (!readOnly && !onItemSelect) {
    throw new Error(
      'Checklist requires an `onItemSelect` callback when not readOnly',
    )
  }

  return (
    <List className={classes.root}>
      {items.map((i) => (
        <ListItem
          key={i.id}
          className={classes.item}
          button
          disabled={readOnly}
          // Undo the opacity that is applied when disabled === true
          style={readOnly ? { opacity: 1 } : {}}
          disableGutters
          onClick={() => onItemSelect && onItemSelect(i.id)}
        >
          <Paper className={classes.itemCard}>
            <Icon color={i.selected ? 'success' : 'disabled'}>
              {i.selected ? <CheckCircle /> : <Circle />}
            </Icon>
            <span className={classes.itemLabel}>{i.label}</span>
          </Paper>
        </ListItem>
      ))}
    </List>
  )
}

export default Checklist
