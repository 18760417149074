"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var toggler = function (value, nextValue) {
    return nextValue === undefined ? !value : Boolean(nextValue);
};
/**
 * A hook that tracks and changes the value of a boolean.
 *
 * @example
 * const [isOpen, toggle] = useToggle(false)
 * toggle() // isOpen === true
 * toggle() // isOpen === false
 * toggle(false) // isOpen === false
 *
 * @param initialValue The initial state
 * @returns a [value, setter] tuple. The setter may be called with an arg to force
 * the next value, or with no argument.
 */
var useToggle = function (initialValue) { return react_1.useReducer(toggler, initialValue); };
exports.default = useToggle;
