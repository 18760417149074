import React from 'react'
import { Box, BoxProps, makeStyles, Typography } from '@perk-ui/core'

import {
  DailyJournalPainScore,
  dailyJournalScoreToColor,
} from '../../utils/painScores'

const useStyles = makeStyles(() => ({
  hero: {
    borderRadius: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  heroScore: {
    fontSize: 120,
    lineHeight: '0.9',
  },
  heroDenom: {
    fontSize: 48,
  },
}))

export interface PainScoreHeroProps extends BoxProps {
  score: DailyJournalPainScore
}

const PainScoreHero: React.FC<PainScoreHeroProps> = ({
  score,
  style,
  ...rest
}) => {
  const classes = useStyles()
  const { main, ambient } = dailyJournalScoreToColor(score)
  const circleSize = '60%'
  // If the score is double digits, this container ends up being quite wide
  // relative to its height. That breaks the `radial-gradient` style that's
  // responsible for the background circle, so we make some adjustments for it below
  // Also, the Baskervvile font's alignment of numbers is pretty inconsistent
  const is10 = score === 10

  return (
    <Box
      style={{
        background: `radial-gradient(circle at center, ${ambient} ${circleSize}, transparent 0%)`,
        maxWidth: 150,
        ...style,
      }}
      {...rest}
    >
      <Typography
        component="span"
        className={classes.heroScore}
        style={{
          color: main,
          ...(is10 ? { letterSpacing: -12 } : {}),
        }}
      >
        {score}
      </Typography>
      <Typography
        component="span"
        className={classes.heroDenom}
        style={{
          color: main,
        }}
      >
        /
      </Typography>
      <Typography
        component="span"
        className={classes.heroDenom}
        style={{
          color: main,
        }}
      >
        10
      </Typography>
    </Box>
  )
}

export default PainScoreHero
