import { useMutation } from 'react-query'

import request from '../../config/axios'
import { useHubId } from '../auth/AuthContext'

interface ConsentRequestBody {
  signature: string
  consents_to_study: boolean
  agrees_to_esign: boolean
}

interface ConsentResponseBody {
  user_id: string
  signature: string
  agrees_to_esign: boolean
  consent_form_version: '1' // | '2'
  consents_to_study: boolean
  id: string
  created_at: string
  updated_at: string
}

const useSubmitConsent = () => {
  const hubId = useHubId()

  return useMutation<ConsentResponseBody, unknown, ConsentRequestBody>((vars) =>
    request
      .post('/consents', {
        ...vars,
        user_id: hubId,
      })
      .then((r) => r.data),
  )
}

export default useSubmitConsent
