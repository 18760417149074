import { Plugins } from '@capacitor/core'
import { Log } from 'oidc-client'

import config from '../../config/app-config'

interface LoginResult {
  url: string
}

interface NavigateParams {
  url?: string
  [k: string]: unknown
}

const { PerkOAuth } = Plugins

export class IosNavigator {
  prepare() {
    return Promise.resolve(this)
  }

  navigate(params: NavigateParams): Promise<LoginResult> {
    if (!params || !params.url) {
      Log.error('IosNavigator.navigate: No url provided')
      return Promise.reject(new Error('No url provided'))
    }

    return PerkOAuth.startOAuth({
      authUrl: params.url,
      callbackUrlScheme:
        config.oauthConfig.ios.callbackScheme +
        config.oauthConfig.ios.callbackPath,
    })
  }
}
