import React, { useState } from 'react'
import {
  Body1,
  Box,
  Button,
  Checkbox,
  CheckboxLabel,
  H1,
  H3,
  H5,
  makeStyles,
  TextField,
} from '@perk-ui/core'
import { format } from 'date-fns'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router'

import { Footer,Header, Page } from '../../components/Layout'
import useSubmitConsent from '../../features/consent/useSubmitConsent'
import { useInterruptManager } from '../../features/interrupts/InterruptProvider'
import MedStarLogo from './components/MedStarLogo'
import Section from './components/Section'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: theme.palette.primary.main + ' !important',
  },
}))

const StudySignature: React.FC = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const { completeCurrentInterrupt } = useInterruptManager()
  const [consent, setConsent] = useState<'yes' | 'no' | null>(null)
  const [signature, setSignature] = useState('')
  const [agreesToEsign, setAgreesToEsign] = useState(false)
  const canContinue = consent === 'yes' && signature && agreesToEsign
  const submitConsent = useSubmitConsent()

  const handleYes = () => {
    if (consent === 'yes') return setConsent(null)
    setConsent('yes')
  }

  const handleNo = () => {
    if (consent === 'no') return setConsent(null)
    setConsent('no')
  }

  const handleContinue = () => {
    if (!canContinue) return

    submitConsent
      .mutateAsync({
        signature,
        consents_to_study: consent === 'yes',
        agrees_to_esign: agreesToEsign,
      })
      .then(() => completeCurrentInterrupt())
      .then(() => {
        push('/home')
      })
  }

  return (
    <Page
      title="Taper App - Study Summary"
      header={<Header withNav={false}></Header>}
      footer={
        <Footer withNav={false} floating>
          <Box p={3}>
            <Button onClick={handleContinue} fullWidth disabled={!canContinue}>
              Sign and Continue
            </Button>
          </Box>
        </Footer>
      }
    >
      <Box p={3}>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Seaweed+Script&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <H3 style={{ marginBottom: 16 }}>
          Permission to Take Part in a Human Research Study
        </H3>
        <MedStarLogo />
        <Section title="Confirmation">
          I agree to take part in this study. I authorize the use and disclosure
          of my personal information as described in this consent and
          authorization form. I have had a chance to ask questions about being
          in this study and have those questions answered. By agreeing to take
          part in this study, I am not releasing or agreeing to release the
          investigator, the sponsor, the institution, or its agents from
          liability for negligence.
        </Section>
        <H5 gutterBottom>Do you consent to participate in this study?</H5>
        <Box
          pb={2}
          style={{
            display: 'flex',
          }}
        >
          <Button
            fullWidth
            variant="outlined"
            style={{ marginRight: 8 }}
            selected={consent === 'yes'}
            onClick={handleYes}
          >
            Yes
          </Button>
          <Button
            fullWidth
            variant="outlined"
            style={{ marginLeft: 8 }}
            selected={consent === 'no'}
            onClick={handleNo}
          >
            No
          </Button>
        </Box>

        <H5 gutterBottom>Signature</H5>
        <TextField
          type="text"
          fullWidth
          value={signature}
          onChange={(ev) => setSignature(ev.target.value)}
        />
        <Box py={2} pl={1}>
          <H1
            style={{ minHeight: 24, fontFamily: "'Seaweed Script', cursive" }}
          >
            {signature}
          </H1>
        </Box>
        <CheckboxLabel
          control={
            <Checkbox className={classes.checkbox} checked={agreesToEsign} />
          }
          className={classes.checkbox}
          label={
            <Body1 component="span" color="primary">
              I agree to e-signature
            </Body1>
          }
          onChange={() => setAgreesToEsign(!agreesToEsign)}
        />
        <H5 style={{ marginTop: 16 }}>
          Date: {format(new Date(), 'M/d/yyyy')}
        </H5>
      </Box>
    </Page>
  )
}

export default StudySignature
