import { Log, User, UserManager as OIDCUserManager } from 'oidc-client'

import apm from '../../config/analytics'
import { AndroidNavigator } from './AndroidNavigator'
import { IosNavigator } from './IosNavigator'

export interface SignInError {
  result: 'USER_CANCELLED' | 'FAILURE'
}

export class UserManager extends OIDCUserManager {
  _iosNavigator: IosNavigator
  _androidNavigator: AndroidNavigator

  constructor(
    settings = {},
    iosNavigator = IosNavigator,
    androidNavigator = AndroidNavigator,
  ) {
    super(settings)

    this._iosNavigator = new iosNavigator()
    this._androidNavigator = new androidNavigator()

    // Log.level = 4
    // Log.logger = window.console
  }

  signinAndroid(args: Record<string, unknown> = {}): Promise<User> {
    args.request_type = 'si:a'
    args.display = 'android'

    // @ts-expect-error This is a private method that is not declared in the type file
    return this._signinStart(args, this._androidNavigator, {})
      .then((result: { url: string }) => {
        // We have signed in and have a url. Do the callback
        const { url } = result
        return this.signinAndroidCallback(url)
      })
      .catch((err: unknown) => {
        // How do we know if the user closed the browser without finishing auth?
        Log.error(err)
        apm.captureError(err as Error)
      })
  }

  signinAndroidCallback(url?: string): Promise<User> {
    // @ts-expect-error this is implemented but not typed
    return this._signinEnd(url || this._androidNavigator.url).then((user) => {
      if (user.profile && user.profile.sub) {
        Log.info(
          'UserManager.signinAndroidCallback: successful, signed in sub: ',
          user.profile.sub,
        )
      } else {
        Log.info('UserManager.signinAndroidCallback: no sub')
      }

      return user
    })
  }

  signinIos(args: Record<string, unknown> = {}): Promise<User> {
    args.request_type = 'si:i'
    args.display = 'ios'

    // @ts-expect-error This is a private method that is not declared in the type file
    return this._signinStart(args, this._iosNavigator, {})
      .then((result: { url: string }) => {
        // We have signed in and have a url. Do the callback
        const { url } = result
        return this.signinIosCallback(url)
      })
      .catch((err: unknown) => {
        Log.error(err)
        apm.captureError(err as Error)
      })
  }

  signinIosCallback(url?: string): Promise<User> {
    // @ts-expect-error this is implemented but not typed
    return this._signinEnd(url || this._iosNavigator.url).then((user) => {
      if (user.profile && user.profile.sub) {
        Log.info(
          'UserManager.signinIosCallback: successful, signed in sub: ',
          user.profile.sub,
        )
      } else {
        Log.info('UserManager.signinIosCallback: no sub')
      }

      return user
    })
  }
}
