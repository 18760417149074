import { IQuestionnaireResponse } from '@ahryman40k/ts-fhir-types/lib/R4'
import { format } from 'date-fns'
import { useMutation, useQueryClient } from 'react-query'

import request from '../../config/axios'
import { assert } from '../../utils/assert'
import { useHubId } from '../auth/AuthContext'

const useSubmitQr = () => {
  const queryClient = useQueryClient()
  const hubId = useHubId()
  const url = '/fhir/QuestionnaireResponse'

  return useMutation<IQuestionnaireResponse, unknown, IQuestionnaireResponse>(
    (qr) => {
      const containedQId = qr?.contained?.[0].id
      assert(
        !!containedQId,
        'Can not submit a QuestionnaireResponse without a contained Questionnaire.id',
      )
      assert(
        !!qr.authored,
        "Can not submit a QuestionnaireResponse without an 'authored' time",
      )

      const params = new URLSearchParams()
      params.set('questionnaire', `Questionnaire/${containedQId}`)
      params.set('authored', format(new Date(qr.authored), 'yyyy-MM-dd'))
      params.set('subject', `Patient/${hubId}`)

      return request
        .put<IQuestionnaireResponse>(url, qr, { params })
        .then((r) => r.data)
    },
    {
      onSuccess() {
        // Invalidate QuestionnaireResponse search and GET requests
        queryClient.invalidateQueries('/fhir/QuestionnaireResponse')
      },
    },
  )
}

export default useSubmitQr
