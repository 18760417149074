import { IQuestionnaireResponse } from '@ahryman40k/ts-fhir-types/lib/R4'
import { format } from 'date-fns'
import { useQuery, useQueryClient } from 'react-query'

import request from '../../config/axios'
import { useHubId } from '../auth/AuthContext'
import { FhirBundle } from '../Fhir/type-utils'

interface SearchParams {
  patientId: string
  questionnaire: string
  from?: Date
  to?: Date
  limit?: number
  sort: string
  sortDirection: 'ascending' | 'descending'
}

// TODO: Abstract some of these FHIR search params to a common util
// i.e. _sort, _count
const createSearchParams = ({
  patientId,
  from,
  to,
  questionnaire,
  limit,
  sort,
  sortDirection,
}: SearchParams) => {
  const params = new URLSearchParams()
  params.set('status', 'completed')
  params.set('patient', patientId)
  params.set('questionnaire', `Questionnaire/${questionnaire}`)

  const _sort = `${sortDirection === 'ascending' ? '' : '-'}${sort}`
  params.append('_sort', _sort)

  from && params.set('authored', `ge${format(from, 'yyyy-MM-dd')}`) // ge => greater or equal
  to && params.append('authored', `le${format(to, 'yyyy-MM-dd')}`) // le => less or equal
  limit && params.set('_count', String(limit))

  return params
}

export interface UseQuestionnaireSearchParams {
  questionnaire: string
  from?: Date
  to?: Date
  limit?: number
  sort?: string
  sortDirection?: 'ascending' | 'descending'
}

/**
 * Searches the Hub for QuestionnaireResponses that match the params passed.
 *
 * Note: Returns completed responses only, and is sorted: ascending by `authored`
 */
const useQRSearch = ({
  questionnaire,
  from,
  to,
  limit,
  sort = 'authored',
  sortDirection = 'ascending',
}: UseQuestionnaireSearchParams) => {
  const url = '/fhir/QuestionnaireResponse'
  const queryClient = useQueryClient()
  const patientId = useHubId()
  const params = createSearchParams({
    patientId,
    from,
    to,
    sort,
    sortDirection,
    limit,
    questionnaire,
  })

  return useQuery(
    [url, params.toString()],
    () =>
      request
        // Note: Applying this type percolates down to all consumers
        .get<FhirBundle<IQuestionnaireResponse>>(url, {
          params,
          headers: {
            'Cache-Control': 'no-cache',
          },
        })
        .then((r) => (r.data.entry || []).map((e) => e.resource)),
    {
      // We have the QuestionnaireResponse data, so let's go ahead and
      // store it in the cache for when we request them individually.
      // TODO: Can we do this automatically for all search results?
      onSuccess(questionnaireResponses) {
        questionnaireResponses.forEach((qr) => {
          if (!qr || !qr.id) return
          const queryKey = [url, qr.id]
          queryClient.setQueryData(queryKey, qr)
        })
      },
    },
  )
}

export default useQRSearch
