import React from 'react'
import {
  BottomNavigation,
  BottomNavigationAction,
  BottomNavigationProps,
  usePageContext,
} from '@perk-ui/core'
import { motion } from 'framer-motion'
import { BarChart2, Home, PlusCircle } from 'react-feather'
import { useLocation } from 'react-router'

import { useHistory } from '../../utils/useHistory'

const BottomNav: React.FC<BottomNavigationProps> = ({ ...rest }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { navVariant } = usePageContext()
  const variant = navVariant === 'bottom' ? 'bottom' : 'tab'

  return (
    <motion.div
      // Using a layoutId and nesting this under the AnimateSharedLayout above the AnimatedSwitch
      // tells Framer that this element does not need to be animated when a new BottomNav is
      // rendered. The animations applied here trigger only when a component with this layoutId
      // does not exist in either the current or previous render.
      layout
      layoutId="bottomNav"
      initial={{ opacity: 0, x: 15 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -15 }}
    >
      <BottomNavigation
        showLabels
        value={pathname}
        // @ts-expect-error: ts-2322 occurs after narrowing Mui's BottomNav to `component="nav"`
        // The native HTMLNavElement's `onChange` type is, for some reason, being unioned with
        // the `onChange` handler of MUI's BottomNavigation component rather than being overriden
        onChange={(_, actionValue: string) => {
          if (actionValue === pathname) return
          history.push(actionValue)
        }}
        variant={variant}
        {...rest}
      >
        <BottomNavigationAction
          label="Home"
          value="/home"
          role="link"
          icon={<Home />}
        />
        <BottomNavigationAction
          label="Journal"
          value="/daily-journal"
          role="link"
          icon={<PlusCircle />}
        />
        <BottomNavigationAction
          label="Me"
          value="/me"
          role="link"
          icon={<BarChart2 />}
        />
      </BottomNavigation>
    </motion.div>
  )
}

export default BottomNav
