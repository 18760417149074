import React, { createContext, useContext, useEffect, useState } from 'react'
import { User } from 'oidc-client'

import apm from '../../config/analytics'
import { setHubAuthHeader, setIdpAuthHeader } from '../../config/axios'
import { findBy } from '../../utils/arrays'
import { AuthService, CernerUser, SignInResult } from './AuthService'

interface AuthState {
  authReady: boolean
  isAuthenticated: boolean
  hubId?: string
  user?: CernerUser
  signIn: () => Promise<SignInResult>
  signOut: () => Promise<void>
  signinRedirectCallback: () => Promise<User>
}

const authService = new AuthService()
const initialState: AuthState = {
  authReady: false,
  isAuthenticated: false,
  hubId: undefined,
  user: undefined,
  signIn: () => Promise.reject('Authentication not yet initialized.'),
  signOut: () => Promise.reject('Authentication not yet initialized.'),
  signinRedirectCallback: () =>
    Promise.reject('Authentication not yet initialized.'),
}

export const AuthContext = createContext<AuthState>(initialState)
export const useAuth = () => useContext(AuthContext)
export const useIsAuthenticated = () => useContext(AuthContext).isAuthenticated
export const useHubId = () => useContext(AuthContext).hubId as string

const AuthProvider: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [authReady, setAuthReady] = useState(false)
  const [hubId, setHubId] = useState<string | undefined>()
  const [user, setUser] = useState<CernerUser | undefined>()

  useEffect(() => {
    authService.attachAuthListeners(
      setIsAuthenticated,
      setAuthReady,
      setHubAuthHeader,
      setIdpAuthHeader,
      setHubId,
      setUser,
    )

    // On first load, check to see if we have a user and then
    // set the auth status accordingly. Then setup listeners for
    // future changes.
    authService.initAuthenticatedSession(
      setIsAuthenticated,
      setAuthReady,
      setHubAuthHeader,
      setIdpAuthHeader,
      setHubId,
      setUser,
    )
  }, [setAuthReady, setIsAuthenticated])

  useEffect(() => {
    if (!user) return
    const email = findBy('system', 'email', user.telecom || [])?.value

    apm.setUserContext({
      id: hubId,
      email,
    })
  }, [user, hubId])

  const { signIn, signOut, signinRedirectCallback } = authService
  const contextValue = {
    authReady,
    isAuthenticated,
    hubId,
    user,
    signIn,
    signinRedirectCallback,
    signOut,
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {authReady ? children : <></>}
    </AuthContext.Provider>
  )
}

export default AuthProvider
