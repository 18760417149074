import React from 'react'
import { Box, Button, H1, H3, makeStyles, Page } from '@perk-ui/core'

import { ReactComponent as Explanation } from '../../../assets/scenes/Explanation2.svg'
import { Footer, Header } from '../../../components/Layout'
import useRedirectCleanup from '../../../features/animation/useRedirectCleanup'
import { useHistory } from '../../../utils/useHistory'

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      height: theme.spacing(34),
    },
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
  },
}))

const ThankYou: React.FC = () => {
  const { push } = useHistory()
  const classes = useStyles()
  useRedirectCleanup()

  const goToHome = () => {
    push('/home')
  }

  return (
    <Page
      title="Taper App - Thank you"
      header={<Header withNav={false} withSafeInset={false}></Header>}
      footer={
        <Footer withNav={false} withSafeInset={false} floating>
          <Box p={3}>
            <Button fullWidth onClick={goToHome}>
              Done
            </Button>
          </Box>
        </Footer>
      }
    >
      <Box pt={0} className={classes.hero}>
        <Explanation viewBox="25 0 150 150" />
      </Box>
      <Box px={5} pt={3}>
        <H1 paragraph>Thank You!</H1>
        <H3 paragraph className={classes.body} style={{}}>
          Your weekly pain scores have been recorded for your doctor to review
          at your next visit.
        </H3>
      </Box>
    </Page>
  )
}

export default ThankYou
