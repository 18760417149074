import React from 'react'
import { Body1, Box, Divider, List } from '@perk-ui/core'
import { compareDesc } from 'date-fns'

import { fancyFontFamily } from '../../../config/theme'
import { ScoreData } from './PainHistory'
import ScoreListItem from './ScoreListItem'

interface ScoreHistoryListProps {
  intensityData: ScoreData[]
  interferenceData: ScoreData[]
}

const ScoreHistoryList: React.FC<ScoreHistoryListProps> = ({
  interferenceData,
  intensityData,
}) => {
  if (!intensityData.length || !interferenceData.length) {
    return null
  }

  const scoreList = [...intensityData, ...interferenceData]

  return (
    <Box my={3}>
      <Body1 gutterBottom style={{ fontFamily: fancyFontFamily }}>
        Baseline
      </Body1>
      <Divider />
      <List>
        <ScoreListItem
          baseline
          divider
          label="Pain Intensity"
          score={intensityData[0].score}
          date={intensityData[0].authored}
        />
        <ScoreListItem
          baseline
          label="Pain Interference"
          score={interferenceData[0].score}
          date={interferenceData[0].authored}
        />
      </List>
      <Body1 gutterBottom style={{ fontFamily: fancyFontFamily }}>
        Recent
      </Body1>
      <Divider />
      <List>
        {scoreList
          .sort((a, b) => compareDesc(a.date, b.date))
          .map((data, i) => {
            const isLast = i === scoreList.length - 1
            const label =
              'intensityScore' in data ? 'Pain Intensity' : 'Pain Interference'

            return (
              <ScoreListItem
                key={data.date + label + i}
                divider={!isLast}
                label={label}
                score={data.score}
                date={data.authored}
              />
            )
          })}
      </List>
    </Box>
  )
}

export default ScoreHistoryList
