/**
 * General helper functions for dealing with FHIR data structures
 */
import {
  ICodeableConcept,
  ICoding,
  IExtension,
  IQuestionnaireResponse,
  IResource,
  IResourceList,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { compareDesc, isEqual, isSameDay } from 'date-fns'

import apm from '../../config/analytics'
import { ensureArray } from '../../utils/arrays'
import { questionnaireMap } from '../survey/questionnaire-data'

/**
 * A function to quickly find a resource in the contained by the referenced ID
 * NOTE: the ID to search on MUST begin with "#" since that is how you properly
 * reference contained resources.
 */
export function findContainedResourceById(
  id: string,
  contained: IResourceList[],
): IResource | undefined {
  if (id[0] !== '#') {
    throw new Error('Contained Resource IDs for search must begin with #')
  }

  const searchId = id.substring(1)
  const found = contained.find((x) => x.id === searchId)
  if (!found) {
    throw new Error('Could not find contained resource')
  }

  return found
}

/**
 * [findExtension description]
 * @param  {string | string[]} urls:                    A single extension url or Array of urls to search. An array represents nested data
 * @param  {IExtension[]}   exts:                    Array of extensions to search through
 * @return {IExtension}                              Returns the found extension
 */
export function findExtension(
  urls: string | string[],
  exts: IExtension[],
): IExtension {
  return _findExtensions(urls, exts, true)[0]
}

/**
 * [findExtensionArray description]
 * @param  {string | string[]} urls A single extension url or Array of urls to search. An array represents nested data
 * @param  {IExtension[]}   exts Array of extensions to search through
 * @return {IExtension[]}        Returns an array of the extensions that were found
 */
export function findExtensionArray(
  urls: string | string[],
  exts: IExtension[],
): IExtension[] {
  return _findExtensions(urls, exts, true)
}

/**
 * Find the latest QR authored on a particular day. This is helpful when performing
 * a QR search, particularly on not-super-clean data.
 *
 * @param day Day for which to find the most recent QR
 * @param responses List of QRs to search through
 */
export const latestQrOnDay = (
  day: Date,
  responses: IQuestionnaireResponse[],
): IQuestionnaireResponse | undefined => {
  const qrsOnDay = responses.filter(
    (resp) => resp.authored && isSameDay(new Date(resp.authored), day),
  )
  const [latest, ...rest] = qrsOnDay.sort((a, b) => {
    if (!a.authored || !b.authored) return -1

    const aDate = new Date(a.authored)
    const bDate = new Date(b.authored)

    if (isEqual(aDate, bDate)) {
      // An update has been performed on this QR so there are different versions
      // with the same `authored` timestamp. Sort by `meta.versionId`
      const aVersion = Number(a.meta?.versionId)
      const bVersion = Number(b.meta?.versionId)

      // Note: any comparison with `NaN` evalutes to false: this will always sort NaN lower
      return aVersion > bVersion ? 1 : -1
    } else {
      return compareDesc(new Date(a.authored), new Date(b.authored))
    }
  })

  if (
    rest.length &&
    rest[0].contained?.[0]?.id === questionnaireMap.DailyPainJournal
  ) {
    const error = new Error(
      `We should not ever have multiple DailyJournals on the same day. Investigate what happened to cause this. Subject: ${
        rest[0].subject
      }, Day: ${String(day)}`,
    )

    if (__DEV__) {
      throw error
    } else if (__PROD__) {
      apm.captureError(error)
    }
  }

  return latest
}

/**
 * findCodeableConcept
 */
export function findCodeableConceptBySystem(
  concept: ICodeableConcept,
  system: string,
): ICoding | undefined {
  if (concept?.coding) {
    return concept?.coding.find((c) => c.system === system)
  }

  return undefined
}

/**
 * _findExtensions PRIVATE: Generic method to find extensions based on an array of URLs.
 * If findArray === true, then we expect that the last url will return 1 or more
 * extensions. This is to support cases like taperStep initial and final with multiple
 * medications being referenced in the extensions
 *
 * @param  {string | string[]}    urls A single extension url or an array or URLs
 * @param  {IExtension[]} exts      The array of extensions to search
 * @param  {boolean}         findArray Boolean flag to search for a single extension or an array of them
 * @return {IExtension[]}           This will always return an array of extensions for typing simplicity
 */
function _findExtensions(
  urls: string | string[],
  exts: IExtension[],
  findArray: boolean,
): IExtension[] {
  const [url, ...rest] = ensureArray(urls)
  const ext = exts.find((x) => x.url === url)

  if (!ext) {
    throw new Error(`Did not find extension: ${url}`)
  }

  if (rest.length > 0 && ext?.extension) {
    return _findExtensions(rest, ext.extension, findArray)
  } else if (rest.length > 0 && !ext?.extension) {
    // If there's more urls to recurse, and no extension, search failed
    throw new Error('Could not follow the extension path fully')
  } else if (findArray) {
    return exts.filter((x) => x.url === url)
  } else {
    return [ext]
  }
}
