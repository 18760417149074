import React from 'react'
import { Body1, Box, Link, List, ListItem } from '@perk-ui/core'

import AppLink from '../../components/AppLink'
import config from '../../config/app-config'

export interface FAQItem {
  title: string
  content: JSX.Element
}

export const FAQs: FAQItem[] = [
  {
    title: 'Who can use this app?',
    content: (
      <Body1>
        The TAPR-CPM app is currently available for patients of doctors who use
        the doctor TAPR-CPM app in their clinic for research. After the research
        study we hope it will be available in other clinics across the U.S.
      </Body1>
    ),
  },
  {
    title: 'Does this app provide medical advice?',
    content: (
      <Body1>
        No, this app exists as a support tool for you and your doctor and is not
        intended to serve as medical advice, treatment, or care. It is important
        that treatment decisions be made by your doctor.
      </Body1>
    ),
  },
  {
    title: 'How do I access the app?',
    content: (
      <Body1 component="span">
        You can access the app via the web or by downloading the app. Please see
        links below that will take you to the correct site:
        <List dense>
          <ListItem>
            Web:{' '}
            <Link href="https://app.humanfactorsapps.com/home">
              https://app.humanfactorsapps.com
            </Link>
          </ListItem>
          <ListItem>
            Apple App:{' '}
            <Link href="https://apps.apple.com/app/id1534248576">
              https://apps.apple.com/app/id1534248576
            </Link>
          </ListItem>
          <ListItem>
            Android App:{' '}
            <Link href="https://play.google.com/store/apps/details?id=com.perkmotivation.ChronicPain">
              https://play.google.com/store/apps/details?id=com.perkmotivation.ChronicPain
            </Link>
          </ListItem>
        </List>
      </Body1>
    ),
  },
  {
    title: 'How do I sign in?',
    content: (
      <Body1>
        First navigate to the website clicking{' '}
        <Link href="https://app.humanfactorsapps.com/home">here</Link>. Then
        sign in with your patient portal account. You will then use the same
        username and password to login to TAPR-CPM on either the web site or
        app.
      </Body1>
    ),
  },
  {
    title: 'What if I do not have a patient portal?',
    content: (
      <Body1>
        f you have not set up your patient portal, you will need to follow the
        instructions{' '}
        <Link href="https://mymedstar.iqhealth.com/self-enroll/?opt_id=oeu1612981331223r0.4289869795932689">
          here
        </Link>{' '}
        and after you have created the username and password you will be able to
        log into TAPR-CPM on the app or on your desktop.
      </Body1>
    ),
  },
  {
    title: 'What if I forget my password?',
    content: (
      <Body1>
        Your password is the password for your patient portal. If you have
        forgotten your patient portal password, you will need to follow the
        instructions for “forgot my password”{' '}
        <Link href="https://cernerhealth.com/forgot-password?c=ae737c6564c345c2b9ac1294f98c75c0&r=https%3A%2F%2Fcernerhealth.com%2Fsaml%2Fsso%2Fresponse%3Fmessage_id%3D_c89925f7-5ca9-4979-b55c-d276ae271e98%26issuer%3Dhttps%253A%252F%252Fmymedstar.iqhealth.com%252Fsession-api%252Fprotocol%252Fsaml2%252Fmetadata%26">
          at this link.
        </Link>
      </Body1>
    ),
  },
  {
    title:
      'Who should I contact if I am having technical issues with the app? ',
    content: (
      <Body1>
        If you are having technical issues first try closing out of the app and
        opening it again. If that does not work please contact{' '}
        <Link href={`mailto:${config.supportEmail}`}>
          {config.supportEmail}
        </Link>
      </Body1>
    ),
  },
  {
    title: 'How often am I expected to fill out surveys?',
    content: (
      <Body1>
        You are highly encouraged to fill out the 2-minute weekly survey every
        Monday so your doctor can review your results with you at your next
        appointment. Additionally, the daily survey is optional but allows you
        to track your daily activities, emotions, pain level and location so you
        can better track your pain.
      </Body1>
    ),
  },
  {
    title: 'Do I have to fill out the Daily Journal?',
    content: (
      <Body1>
        The daily journal is designed to help you track your daily activities
        and pain level between the weekly reports. Research shows this can be
        helpful to better understand your pain, but you do not have to fill it
        out.
      </Body1>
    ),
  },
  {
    title: 'What happens if I miss taking my weekly pain assessment?',
    content: (
      <Body1>
        Your weekly survey will be available each Monday and will be the first
        thing you must complete before entering the rest of the app. If you miss
        a week you will be prompted to take the current week’s assessment. You
        will not be able to fill out any weekly surveys you miss. You can go
        back and add information to your daily journal for the previous seven
        days.
      </Body1>
    ),
  },
  {
    title: 'What information can my doctor see?',
    content: (
      <Body1>
        Your doctor will be able to see your weekly pain scores and will be able
        to see some measures on the daily journal like daily pain and daily
        activities. This doesn’t guarantee your doctor will see your scores in
        real time.
      </Body1>
    ),
  },
  {
    title: 'When can my doctor see my information?',
    content: (
      <Body1>
        The doctor can see your pain values at any time but will likely not
        review your data with you until your next appointment.
      </Body1>
    ),
  },
  {
    title:
      'What happens if what I see in the patient portal or in TAPR-CPM  is different than what my doctor and I discussed?',
    content: (
      <Body1>
        TAPR-CPM tries to pull the most up to date information from the
        information logged by your doctor but you should always go off of the
        last instructions from your doctor. If you still are not sure, contact
        your doctor’s office as soon as possible.
      </Body1>
    ),
  },
  {
    title:
      'What happens to my data if I decide to stop participating in the study?',
    content: (
      <Body1 component="span">
        If you decide to leave the study, we will stop collecting any more
        information about you, but any information we have already collected
        will still be used for the purposes of the research study.
        <br />
        <br />
        You can leave the research at any time it will not be held against you.
        You can tell the study team that you want to take away your permission
        to use and share your demographic information (e.g., age, gender,
        ethnicity, race, years in occupation) at any time. You can research the
        study team at:
        <br />
        <br />
        <Box pl={2}>
          Robin Littlejohn, MS
          <br />
          MedStar Health Research Institute
          <br />
          3007 Tilden Street NW, Suite 6N
          <br />
          Washington, DC 20008
          <br />
          Email:{' '}
          <Link href="mailto:Robin.A.Littlejohn@medstar.net">
            <u>Robin.A.Littlejohn@medstar.net</u>
          </Link>
          <br />
          Phone:{' '}
          <Link href="tel:(202)-244-9803">
            <u>(202)-244-9803</u>
          </Link>
        </Box>
      </Body1>
    ),
  },
  {
    title: 'My Pain is getting worse',
    content: (
      <Body1>
        If your pain is getting worse unexpectedly, or has persisted in a new
        location, please contact your doctor.
        <br />
        <br />
        For resources about what to expect while tapering please see this
        article on{' '}
        <AppLink to={'/resource/what-to-expect-in-your-taper'}>
          what to expect while tapering
        </AppLink>
        .
      </Body1>
    ),
  },
  {
    title: 'I’m feeling other concerning things (withdrawal)',
    content: (
      <Body1 component="span">
        Common withdrawal symptoms include:
        <List dense>
          <ListItem>Pain Areas: in the muscles</ListItem>
          <ListItem>Gastrointestinal: diarrhea, vomiting, nausea</ListItem>
          <ListItem>Whole body: restlessness or sweating</ListItem>
          <ListItem>Mood: general discontent or anxiety</ListItem>
          <ListItem>Eyes: dilated pupil or watery eyes</ListItem>
        </List>
        <br />
        Contact your doctor immediately if you are having severe symptoms.
      </Body1>
    ),
  },
  {
    title: 'How can I opt out of text messages and/or emails?',
    content: (
      <Body1>
        Please email{' '}
        <Link href={`mailto:${config.supportEmail}`}>
          {config.supportEmail}
        </Link>{' '}
        with the subject line “Opting out of messaging TAPR-CPM app” in the body
        of the email please give us your first name, last name and email
        address.
      </Body1>
    ),
  },
  {
    title: 'When should I contact my doctor directly?',
    content: (
      <Body1 component="span">
        Please contact your doctor if you are unsure how much medication you
        should be taking and/or if you are experiencing pain that is:
        <List dense>
          <ListItem>Unexplained</ListItem>
          <ListItem>uncontrolled</ListItem>
          <ListItem>in a new spot</ListItem>
          <ListItem>feels different (stabbing vs aching)</ListItem>
          <ListItem>or if you have a concern.</ListItem>
        </List>
      </Body1>
    ),
  },
  {
    title: 'Should I expect my provider to contact me through the app?',
    content: (
      <Body1>
        No, your doctor will not be able to communicate with you through the app
        they will only be able to see your survey scores. Please talk to your
        doctor if you have questions about when they will be looking at your
        scores.
      </Body1>
    ),
  },
  {
    title: 'Contact Information',
    content: (
      <Body1>
        Technical:{' '}
        <Link href={`mailto:${config.supportEmail}`}>
          {config.supportEmail}
        </Link>
        <br />
        <br />
        Medical: Your doctor or 911
        <br />
        <br />
        Other:{' '}
        <Link href={`mailto:${config.supportEmail}`}>
          {config.supportEmail}
        </Link>
      </Body1>
    ),
  },
]
