import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { Switch, useLocation } from 'react-router-dom'

import { isMobileApp } from '../../utils/platform'

export interface AnimatedSwitchProps {
  /**
   * If set to `true`, `AnimatePresence` will only render one component at a time.
   * The exiting Route will finish its exit animation before the entering Route is rendered.
   *
   * @default true
   */
  exitBeforeEnter?: boolean

  /**
   * Passthrough to AnimatePresence. Determines whether to run the animation on
   * first mount.
   * @default false
   */
  initial?: boolean
}

const AnimatedSwitch: React.FC<AnimatedSwitchProps> = ({
  children,
  exitBeforeEnter = true,
  initial = false,
}) => {
  const location = useLocation()
  const disablePageAnimations = !isMobileApp()

  const SwitchRouter = (
    <Switch location={location} key={location.pathname}>
      {children}
    </Switch>
  )

  return disablePageAnimations ? (
    SwitchRouter
  ) : (
    <AnimatePresence
      exitBeforeEnter={exitBeforeEnter}
      initial={initial}
      onExitComplete={() => {
        if (!__PROD__) {
          // If this log does not appear after switching pages, it means there is
          // an animation issue and the component that moved out likely needs to
          // use `usePrescence`'s `safeToRemove` to register when it unmounts.
          // Or our helper, `useRedirectCleanup`
          console.log('Switch exit complete')
        }
      }}
    >
      {SwitchRouter}
    </AnimatePresence>
  )
}

export default AnimatedSwitch
