import React from 'react'
import { Body1, Box, H1, List, ListItem, makeStyles } from '@perk-ui/core'

import { ReactComponent as Notice } from '../../../assets/scenes/Notice.svg'
import AppLinkButton from '../../../components/AppLinkButton'
import { Footer, Header, Page } from '../../../components/Layout'
import useRedirectCleanup from '../../../features/animation/useRedirectCleanup'
import { capitalize } from '../../../utils/strings'
import { useQueryParam } from '../../../utils/useQueryParam'

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      height: theme.spacing(34),
      marginTop: theme.spacing(-5),
    },
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
  },
  list: {
    listStyle: 'disc inside none',
    '& .MuiListItem-root': {
      minHeight: 'initial',
      display: 'list-item',
    },
  },
  listItem: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',
  },
}))

interface ScoreDiff {
  type: 'interference' | 'intensity'
  diff: string
}

export interface ChangeAlertProps {}

const ChangeAlert: React.FC<ChangeAlertProps> = () => {
  useRedirectCleanup()
  const classes = useStyles()
  const interference = useQueryParam('interference')
  const intensity = useQueryParam('intensity')
  const scoresToReport = [
    interference && { type: 'interference', diff: interference },
    intensity && { type: 'intensity', diff: intensity },
  ].filter(Boolean) as ScoreDiff[]

  return (
    <Page
      title="Taper App - Change alert"
      header={<Header withNav={false} withSafeInset={false}></Header>}
      footer={
        <Footer withNav={false} withSafeInset={false} floating>
          <Box p={3}>
            <AppLinkButton fullWidth to="/weekly-assessment/thank-you">
              Done
            </AppLinkButton>
          </Box>
        </Footer>
      }
    >
      <Box pt={0} className={classes.hero}>
        <Notice viewBox="25 0 150 150" />
      </Box>
      <Box px={5} pt={3}>
        <H1 paragraph>Take Notice</H1>
        {scoresToReport.map((score) => (
          <Body1 key={score.type} paragraph>
            Your recent Pain {capitalize(score.type)} score is {score.diff}{' '}
            points worse than your baseline score.
          </Body1>
        ))}
        <Body1 paragraph style={{}}>
          This could be a significant change.
        </Body1>
        <Body1 paragraph className={classes.body} style={{}}>
          Please consider contacting your doctor or healthcare provider if your
          pain is:
        </Body1>
        <List className={classes.list}>
          <ListItem className={classes.listItem} disableGutters>
            Unexplained
          </ListItem>
          <ListItem className={classes.listItem} disableGutters>
            Uncontrolled
          </ListItem>
          <ListItem className={classes.listItem} disableGutters>
            In a new spot
          </ListItem>
          <ListItem className={classes.listItem} disableGutters>
            Feels different (stabbing vs aching)
          </ListItem>
          <ListItem className={classes.listItem} disableGutters>
            Or, if you have concerns
          </ListItem>
        </List>
      </Box>
    </Page>
  )
}

export default ChangeAlert
