import React from 'react'
import { makeStyles, StandardTextFieldProps, TextField } from '@perk-ui/core'

export interface TextboxProps extends StandardTextFieldProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    outline: 0,
    width: '100%',
  },
  inputRoot: {
    backgroundColor: 'inherit',
    border: '1px solid transparent',
    outline: 0,
    '&:hover, &:active, &:focus': {
      border: `1px solid`,
      borderColor: theme.palette.primary.main + ' !important',
    },
  },
  input: {
    width: '100%',
    '&::placeholder': {
      fontWeight: 600, // non-standard weight
    },
  },
}))

/**
 * A styled wrapper around Core's TextField. The base TextField's
 * combination of wrappers and the base input element make it a bit
 * more complex to style, so this helps maintain consistency.
 */
const Textbox: React.FC<TextboxProps> = (props) => {
  const classes = useStyles()

  return (
    <TextField
      classes={{ root: classes.root }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.input,
        },
      }}
      {...props}
    />
  )
}

export default Textbox
