import React from 'react'
import { IValueSet_Concept } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Button,Card, CardActions, CardContent, Stack } from '@perk-ui/core'

export interface ActivityCardProps {
  concept: IValueSet_Concept
  isActive: boolean
  loading: boolean
  onClick: (concept: IValueSet_Concept, isActive: boolean) => void
}

const ActivityCard: React.FC<ActivityCardProps> = ({
  concept,
  isActive,
  loading,
  onClick,
}) => {
  const title = concept.display
  const body = concept.designation?.[0].value
  if (!title || !body)
    throw new Error('Activity concepts must have a title and body')

  return (
    <Card style={{ marginTop: 16 }}>
      <CardContent>
        <Stack title={title} body={body} />
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          variant={isActive ? 'outlined' : 'contained'}
          loading={loading}
          onClick={() => onClick(concept, isActive)}
        >
          {isActive ? 'Remove from' : 'Add to'} My Activities
        </Button>
      </CardActions>
    </Card>
  )
}

export default ActivityCard
