import React from 'react'
import { AvatarGroup, AvatarGroupProps, makeStyles } from '@perk-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    '& .MuiAvatar-root': {
      height: 28,
      width: 28,
      fontSize: 'inherit',
      marginLeft: -4,
    },
  },
  avatar: {
    fontSize: theme.typography.h3.fontSize,
    border: 'none',
  },
}))

export interface EmojiGroupProps extends AvatarGroupProps {}

const EmojiGroup: React.FC<EmojiGroupProps> = (props) => {
  const { root, avatar } = useStyles()
  return (
    <AvatarGroup
      {...props}
      classes={{
        root: clsx(root, props.classes?.root),
        avatar: clsx(avatar, props.classes?.avatar),
      }}
    />
  )
}

export default EmojiGroup
