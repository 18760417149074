import React from 'react'
import {
  Body1,
  Box,
  Button,
  H1,
  H3,
  Link,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
} from '@perk-ui/core'
import { BarChart2, CheckCircle, Mail } from 'react-feather'

import { ReactComponent as WelcomeWave } from '../../assets/scenes/WelcomeWave.svg'
import { Footer, Header, Page } from '../../components/Layout'
import { useAuth } from '../../features/auth/AuthContext'

const useStyles = makeStyles((theme) => {
  // TODO Potentially move some height-related breakpoints to the theme
  const maxHeightBreakpoint = (height: number) => {
    return `@media (max-height: ${height}px)`
  }

  return {
    hero: {
      backgroundColor: theme.palette.secondary.main,
      display: 'flex',
      justifyContent: 'center',
      paddingTop: `calc(env(safe-area-inset-top) + ${theme.spacing(2)}px)`,
      '& > svg': {
        height: 200,
        width: 200,
        // 670 matches iPhone 6/7/8 and below
        [maxHeightBreakpoint(670)]: {
          height: 175,
        },
        // 570 matches real small phones, like iPhone 5 and Galaxy s5 and below
        [maxHeightBreakpoint(570)]: {
          height: 135,
        },
        marginRight: theme.spacing(1), // The WelcomeWave svg is not naturally centered
      },
    },
    list: {
      paddingLeft: theme.spacing(2),
    },
    item: {
      alignItems: 'flex-start',
      // 645 matches Galaxy S5 but not iPhone 6/7/8
      [maxHeightBreakpoint(645)]: {
        minHeight: theme.spacing(7), // 56px, the default minHeight is 72px
      },
    },
  }
})

const Login: React.FC = React.memo(() => {
  const classes = useStyles()
  const { signIn } = useAuth()

  const handleSignIn = () => {
    signIn()
  }

  return (
    <Page
      title="Taper App - Login"
      header={<Header withNav={false} withSafeInset={false}></Header>}
      footer={
        <Footer withNav={false} withSafeInset={false} floating>
          <Box p={3}>
            <Button fullWidth onClick={handleSignIn}>
              Log In
            </Button>
          </Box>
        </Footer>
      }
    >
      <Box pt={5} className={classes.hero}>
        <WelcomeWave />
      </Box>
      <Box px={5} pt={3}>
        <H1 paragraph>Welcome!</H1>
        <H3 paragraph style={{ fontSize: 20 }}>
          This app will help you...
        </H3>
        <List className={classes.list}>
          <ListItem className={classes.item} disableGutters>
            <ListItemIcon>
              <BarChart2 height={30} width={30} />
            </ListItemIcon>
            <Body1>Track your pain over time</Body1>
          </ListItem>
          <ListItem className={classes.item} disableGutters>
            <ListItemIcon>
              <Mail height={30} width={30} />
            </ListItemIcon>
            <Body1>
              Automatically report your weekly pain scores to your doctor
            </Body1>
          </ListItem>
          <ListItem className={classes.item} disableGutters>
            <ListItemIcon>
              <CheckCircle height={30} width={30} />
            </ListItemIcon>
            <Body1>
              Track your daily medication and other activities that impact your
              pain
            </Body1>
          </ListItem>
        </List>

        <Box py={3}>
          This app does not provide medical advice, if you have any questions
          contact your doctor. In an emergency, contact 911. By continuing, you
          agree to the{' '}
          <Link
            href={`${process.env.PUBLIC_URL}/assets/Opioid_CDS_Terms_And_Conditions.pdf`}
            target="blank"
            style={{ textDecoration: 'underline' }}
          >
            terms and conditions
          </Link>
          .
        </Box>
      </Box>
    </Page>
  )
})

export default Login
