import React, { useLayoutEffect } from 'react'
import { makeStyles } from '@perk-ui/core'
import clsx from 'clsx'

import { ReactComponent as BodyMapBack } from '../assets/BodyMapBack.svg'
import { ReactComponent as BodyMapFront } from '../assets/BodyMapFront.svg'
import { BodyMapPart } from '../pages/DailyJournal/components/BodyMap'
import { allElementsFromPoint } from '../utils/allElementsFromPoint'
import { findBy } from '../utils/arrays'
import { dailyJournalScoreToColor } from '../utils/painScores'

const SELECTED_COLOR = 'rgb(249 128 120)'
const { ambient: HOVER_COLOR } = dailyJournalScoreToColor(10)

const getFillablePath = ($g: Element) =>
  $g.querySelector('.fillable') as HTMLElement | null

const getPartId = ($el: Element) => $el.getAttribute('data-part-id') || null

const useStyles = makeStyles(() => ({
  root: {
    '& path': {
      transition: 'fill 0.15s',
    },
    '& g[aria-selected="false"] .fillable': {
      fill: 'white',
    },
    '& g[aria-selected="true"] .fillable': {
      // !important overrides the fill applied on :focus
      fill: `${SELECTED_COLOR} !important`,
    },
    '& g:focus': {
      outline: 0,
    },
    '&:not($disabled) g:focus': {
      '& .fillable': {
        fill: HOVER_COLOR,
      },
    },
  },
  disabled: {
    opacity: 0.5,
  },
}))

interface InteractiveBodyMapProps
  extends Omit<React.SVGProps<SVGSVGElement>, 'onChange'> {
  /**
   * Whether to display the Front or Back of the Body SVG.
   * Some BodyParts are specific to certain perspective, like the Low Back is
   * visible on the Back body, while others are on both, such as the Left Hand.
   */
  perspective: 'front' | 'back'
  /**
   * When disabled, all click/focus listeners are disabled and the SVG is grayed out.
   */
  disabled?: boolean
  /**
   * The list of activated parts in the BodyMap.
   */
  selectedParts: BodyMapPart[]
  /**
   * Called when a part is selected, with the entire list of all selected parts.
   */
  onChange: (parts: BodyMapPart[]) => void
}

const InteractiveBodyMap: React.FC<InteractiveBodyMapProps> = ({
  perspective,
  selectedParts,
  onChange,
  disabled = false,
  width = '100%',
  ...rest
}) => {
  const classes = useStyles()
  const SvgComponent = perspective === 'front' ? BodyMapFront : BodyMapBack

  // We're using a layout effect because this code involves reading and manipulating
  // the DOM directly, and this hook ensures the callback runs after React
  // has finished with its DOM operations
  useLayoutEffect(() => {
    const $parts = document.querySelectorAll(
      `.BodyMap-${perspective} g`,
    ) as NodeListOf<HTMLElement>

    // For each of this SVG's `g` tags, ensure it's aria-selected state
    // reflects whether that body part is in the `value` list or not.
    for (const $gTag of Array.from($parts)) {
      const $path = getFillablePath($gTag)
      if (!$path) return

      const partId = getPartId($gTag)
      const wasSelected =
        $gTag.getAttribute('aria-selected') === 'true' ? true : false
      const isSelected = selectedParts.some((val) => val.id === partId)

      if (isSelected && !wasSelected) {
        $gTag.setAttribute('aria-selected', 'true')
        $path.removeAttribute('style')
      } else if (!isSelected && wasSelected) {
        $gTag.setAttribute('aria-selected', 'false')

        // When we deselect the element, it is still in a `:focus` state
        // so it's `fill` styling is still as if it was active.
        // We force the `fill` to white with this in-line style and then
        // ensure that when our focus changes, in the `handleFocusChange`
        // callback, we remove this inline style so as to not override the
        // `:focus` or `.filled` class styles.
        $path.style.fill = 'white'
      } else {
        // This tag's selected state hasn't changed
      }
    }
    // eslint-disable-next-line
  }, [JSON.stringify(selectedParts), perspective])

  const handlePartSelect = ($part: HTMLElement) => {
    const partId = getPartId($part)
    if (!partId) return

    let nextParts = [] as BodyMapPart[]
    if (findBy('id', partId, selectedParts)) {
      // If already selected, remove from list
      nextParts = selectedParts.filter((p) => p.id !== partId)
    } else {
      nextParts = [
        ...selectedParts,
        {
          id: partId,
        },
      ]
    }

    onChange(nextParts)
  }

  const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const $elementsUnderPoint = allElementsFromPoint(
      event.clientX,
      event.clientY,
    )

    const $clickedPath = findBy('tagName', 'path', $elementsUnderPoint)
    if (!$clickedPath) return

    const $selectedGTag = $clickedPath.parentElement
    if (!$selectedGTag) return

    handlePartSelect($selectedGTag)
  }

  const handleKeyPress = (event: React.KeyboardEvent<SVGSVGElement>) => {
    const SPACE_KEYS = ['Spacebar', ' ']
    const keysToTriggerOn = ['Enter', ...SPACE_KEYS]

    if (keysToTriggerOn.includes(event.key)) {
      // document.activeElement is the best way to get the element that was
      // intended to be selected
      const $selectedGTag = document.activeElement as HTMLElement | null
      if (!$selectedGTag) return

      // Prevent the default behavior of pressing spacebar: scrolling down
      if (SPACE_KEYS.includes(event.key)) event.preventDefault()

      handlePartSelect($selectedGTag)
    }
  }

  // If the element was previously de-selected, it will have a `fill: white;`
  // inline style. Ensure we remove that if we re-focus an element.
  const handleFocusChange = (_event: React.FocusEvent<SVGSVGElement>) => {
    const $path = document.activeElement
      ? getFillablePath(document.activeElement)
      : null
    if (!$path) return

    if ($path.style.fill !== '') {
      $path.style.fill = ''
    }
  }

  return (
    <SvgComponent
      className={clsx('BodyMap', `BodyMap-${perspective}`, classes.root, {
        [classes.disabled]: disabled,
      })}
      width={width}
      aria-disabled={disabled}
      onClick={disabled ? undefined : handleClick}
      onKeyPress={disabled ? undefined : handleKeyPress}
      onFocus={disabled ? undefined : handleFocusChange}
      {...rest}
    />
  )
}

export default InteractiveBodyMap
