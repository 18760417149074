import React from 'react'
import {
  Body1,
  Box,
  Button,
  H3,
  HeaderBar,
  HeaderBarButton,
} from '@perk-ui/core'

import { Footer, Header, Page } from '../../components/Layout'
import config from '../../config/app-config'
import { useAuth } from '../../features/auth/AuthContext'
import { useHistory } from '../../utils/useHistory'

export interface SettingsProps {}

const Settings: React.FC<SettingsProps> = () => {
  const { goBack } = useHistory()
  const { signOut } = useAuth()

  return (
    <Page
      title="Taper App - Settings"
      header={
        <Header withNav={false}>
          <HeaderBar
            titleContent="Settings"
            left={<HeaderBarButton variant="back" onClick={goBack} />}
          />
        </Header>
      }
      footer={<Footer withNav={false}></Footer>}
    >
      <Box p={3}>
        <Box pb={3}>
          <H3 style={{ paddingBottom: 16 }}>Logout</H3>
          <Button fullWidth onClick={signOut}>
            Logout
          </Button>
        </Box>

        <Box>
          <H3 style={{ paddingBottom: 16 }}>Discontinue Study</H3>
          <Body1 style={{ paddingBottom: 16 }}>
            You have the option to remove yourself from this study and have all
            your data deleted. Contact us if you wish to do so.
          </Body1>
          <Button
            href={`mailto:${config.supportEmail}`}
            target="_blank"
            fullWidth
          >
            Contact Us
          </Button>
        </Box>
      </Box>
    </Page>
  )
}

export default Settings
