import React from 'react'
import { Box, Card, CardContent, Stack } from '@perk-ui/core'

import { toRelativeDateOrString } from '../../utils/dates'
import MedicationDecreaseGraph from '../Fhir/CarePlan/MedicationDecreaseGraph'
import { MedicationDecreaseItem } from './FeedResponse'

export interface MedicationDecreaseFeedItemProps {
  item: MedicationDecreaseItem
}

const MedicationDecreaseFeedItem: React.FC<MedicationDecreaseFeedItemProps> = ({
  item,
}) => {
  const { description, data, time_helper } = item

  const helperText = toRelativeDateOrString(time_helper)

  return (
    <Card style={{ marginBottom: 24 }}>
      <CardContent>
        <Stack body={description} helper={helperText} />
        <Box>
          <MedicationDecreaseGraph data={data} />
        </Box>
      </CardContent>
    </Card>
  )
}

export default MedicationDecreaseFeedItem
