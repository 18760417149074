import React from 'react'
import { H2, makeStyles } from '@perk-ui/core'
import { getHours } from 'date-fns'

import { ReactComponent as GoodAfternoonSvg } from '../../../assets/scenes/GoodAfternoon.svg'
import { ReactComponent as GoodEveningSvg } from '../../../assets/scenes/GoodEvening.svg'
import { ReactComponent as GoodMorningSvg } from '../../../assets/scenes/GoodMorning.svg'
import { useAuth } from '../../../features/auth/AuthContext'
import { findBy } from '../../../utils/arrays'

const NOON_HOURS = 12
const AFTERNOON_HOURS = 18 // 6pm

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(20),
    padding: theme.spacing(4),
    marginTop: theme.spacing(-1),
    display: 'flex',
    alignItems: 'center',
    // Ensures the SVG stays within the bounds of this container
    position: 'relative',
  },
  svg: {
    position: 'absolute',
    right: 30,
    bottom: 0,

    '& path': {
      fill: 'transparent',
      strokeWidth: '1.3',
      stroke: 'rgb(0,0,0)',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  },
}))

interface HomePageHeroProps {}

const HomePageHero: React.FC<HomePageHeroProps> = () => {
  const classes = useStyles()
  const auth = useAuth()
  const nowHours = getHours(new Date())

  const userNames = auth.user?.name ?? []
  const patientName = findBy('use', 'official', userNames)?.given[0]

  const isMorning = nowHours < NOON_HOURS
  const isAfternoon = nowHours < AFTERNOON_HOURS && nowHours >= NOON_HOURS
  let greeting = 'Good Evening'
  let Svg = GoodEveningSvg
  if (isAfternoon) {
    greeting = 'Good Afternoon'
    Svg = GoodAfternoonSvg
  } else if (isMorning) {
    greeting = 'Good Morning'
    Svg = GoodMorningSvg
  }

  return (
    <div className={classes.root}>
      <H2>
        {greeting}
        {patientName ? (
          <>
            ,
            <br />
            {patientName}
          </>
        ) : (
          <div style={{ visibility: 'hidden' }}>PlaceHolder</div>
        )}
      </H2>
      <Svg className={classes.svg} />
    </div>
  )
}

export default HomePageHero
