import React from 'react'
import { Avatar, useTheme } from '@perk-ui/core'

import {
  DailyJournalPainScore,
  dailyJournalScoreToColor,
} from '../utils/painScores'

export interface PainScoreCircleProps {
  score?: DailyJournalPainScore
}

const PainScoreCircle: React.FC<PainScoreCircleProps> = ({ score }) => {
  const theme = useTheme()
  const bgDefault = theme.palette.grey['300']
  const { main = '', ambient = bgDefault } =
    score != null ? dailyJournalScoreToColor(score) : {}

  return (
    <Avatar
      style={{
        backgroundColor: ambient,
        color: main,
        fontWeight: 'bold',
        margin: '1px', // this helps give the avatars space for small screens
      }}
      size="small"
    >
      {score != null ? score : ''}
    </Avatar>
  )
}

export default PainScoreCircle
