import React from 'react'
import { Link as CoreLink, LinkProps as CoreLinkProps } from '@perk-ui/core'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

// related but not necessary: https://github.com/mui-org/material-ui/issues/16846
export type AppLinkProps = Omit<CoreLinkProps, 'component'> & LinkProps

/**
 * A PerkUI Link integrated with ReactRouter behavior, to be used for in-app navigation
 *
 * TODO: Migrate to Core once the usecases in this app are more flushed out.
 */
const AppLink: React.FC<AppLinkProps> = ({ children, to, ...rest }) => (
  <CoreLink component={RouterLink} to={to} {...rest}>
    {children}
  </CoreLink>
)

export default AppLink
