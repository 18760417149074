import React from 'react'
import {
  Body1,
  Box,
  H3,
  H5,
  HeaderBar,
  HeaderBarButton,
  makeStyles,
  Page,
} from '@perk-ui/core'
import { format } from 'date-fns'
import { useParams } from 'react-router'

import Checklist, { ChecklistItem } from '../../../components/Checklist'
import Emoji from '../../../components/Emoji'
import InteractiveBodyMap from '../../../components/InteractiveBodyMap'
import { Header } from '../../../components/Layout'
import SectionTitle from '../../../components/SectionTitle'
import {
  getDailyJournalActivities,
  getDailyJournalBodyParts,
  getDailyJournalNotes,
  getDailyJournalPainScore,
  getDailyJournalSentiments,
} from '../../../features/Fhir/dailyJournalUtils'
import useQuestionnaireResponse from '../../../features/survey/useQuestionnaireResponse'
import { noop } from '../../../utils/noop'
import { capitalize } from '../../../utils/strings'
import { useHistory } from '../../../utils/useHistory'
import PainScoreHero from '../../DailyJournal/PainScoreHero'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      padding: theme.spacing(3),
      // paddingTop: `calc(env(safe-area-inset-top))`,
      backgroundColor: theme.palette.background.paper,
    },
    questionContainer: {
      display: 'flex',
      flexDirection: 'column',
      transition: 'background-color 0.1s ease',
      height: '100%',
    },
    sectionTitle: {
      textAlign: 'left',
    },
    notesRoot: {
      outline: 0,
      width: '100%',
    },
    notesInput: {
      backgroundColor: 'inherit',
      boxShadow: theme.perk.boxShadow.card,
      border: '1px solid transparent',
      outline: 0,
      '&:hover, &:active, &:focus': {
        border: `1px solid`,
        borderColor: theme.palette.primary.main + ' !important',
      },
    },
    emojiPrompt: {
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
  { name: 'DailyJournal' },
)

const DailyJournalDetail: React.FC = () => {
  const { goBack } = useHistory()
  const classes = useStyles()
  const { id: qrId } = useParams<{ id: string }>()

  const { data: qr, isLoading } = useQuestionnaireResponse({ id: qrId })

  if (!isLoading && (!qrId || !qr || !qr.authored)) {
    throw new Error(
      'DailyJournal historical results require a Questionnaire ID and authored Date',
    )
  }

  const date = qr ? new Date(qr.authored as string) : null
  const score = getDailyJournalPainScore(qr) || 5
  const activities = getDailyJournalActivities(qr) || []
  const djSentiments = getDailyJournalSentiments(qr) || []
  const bodyParts = (getDailyJournalBodyParts(qr) || []).map((part) => ({
    id: part,
  }))
  const notes = getDailyJournalNotes(qr)

  const activityItems = activities
    .filter((act) => !act.valueString)
    .map((act) => ({
      id: act.valueCoding?.code,
      label: act.valueCoding?.display,
      selected: true,
    })) as ChecklistItem[]
  const otherItem = activities.find((act) => act.valueString)
  if (otherItem) {
    activityItems.push({
      id: 'other',
      label: otherItem.valueString || '',
      selected: true,
    })
  }

  return (
    <Page
      title="Taper App - Daily Journal Result"
      header={
        <Header withNav={false}>
          <HeaderBar
            titleContent="Pain history"
            left={<HeaderBarButton variant="back" onClick={goBack} />}
          />
        </Header>
      }
    >
      {qr && (
        <Box>
          <Box className={classes.title}>
            <H3>Pain Journal</H3>
            <H3>{format(date as Date, 'EEEE, MMMM d')}</H3>
          </Box>
          <Box className={classes.questionContainer} p={3} pt={0}>
            <PainScoreHero style={{ alignSelf: 'center' }} score={score} />

            <SectionTitle pb={1} title="Activities" />
            {activities.length ? (
              <Checklist readOnly items={activityItems} />
            ) : (
              <Body1>No activities recorded</Body1>
            )}

            <SectionTitle pb={1} title="Pain Location" />
            <Box style={{ display: 'flex' }}>
              <InteractiveBodyMap
                disabled
                perspective="front"
                selectedParts={bodyParts}
                onChange={noop}
              />
              <InteractiveBodyMap
                disabled
                style={{ marginLeft: -42 }}
                perspective="back"
                selectedParts={bodyParts}
                onChange={noop}
              />
            </Box>

            <SectionTitle pb={1} title="How did you feel?" />
            {djSentiments.length ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  width: '100%',
                }}
              >
                {djSentiments.map((sentiment) => {
                  const { code = '', display = '🙂' } = sentiment
                  const emoji = display
                  const label = capitalize(code)
                  return (
                    <Box
                      key={code}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '50%',
                      }}
                    >
                      <Emoji
                        symbol={emoji}
                        label={label}
                        style={{
                          fontSize: '2rem',
                          marginLeft: 24,
                          marginRight: 8,
                        }}
                      />
                      <H5>{label}</H5>
                    </Box>
                  )
                })}
              </Box>
            ) : (
              <Body1>No sentiments recorded</Body1>
            )}

            <SectionTitle pb={1} title="Notes" />
            {notes ? <Body1>{notes}</Body1> : <Body1>No notes recorded</Body1>}
          </Box>
        </Box>
      )}
    </Page>
  )
}

export default DailyJournalDetail
