import { Plugins } from '@capacitor/core'
import axios from 'axios'
import {
  User,
  UserManagerEvents,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client'

import config from '../../config/app-config'
import request, { BaseAxiosConfig } from '../../config/axios'
import { SignInError, UserManager } from './UserManager'

const { Device } = Plugins

export enum SignInResult {
  success = 'success',
  userCancelled = 'userCancelled',
  failed = 'failed',
}

enum launchType {
  ios = 'ios',
  android = 'android',
  popup = 'popup',
  redirect = 'redirect',
}

enum signinMethods {
  ios = 'signinIos',
  android = 'signinAndroid',
  popup = 'signinPopup',
  redirect = 'signinRedirect',
}

interface HubUser {
  hub_id: string
  access_token: string
}

interface CernerUserName {
  use: 'old' | 'official'
  /** First and middle names */
  given: string[]
  /** Last names */
  family: string[]
  /**
   * Single-string representation of the User's name, typically
   * `${family.join(' ')}, ${given.join(' ')}`
   */
  text: string

  period: {
    /** `new Date()`-able */
    start: string
  }
}

interface CernerTelecom {
  system: 'phone' | 'email'
  use: 'mobile' | 'home'
  value: string
  period: {
    /** `new Date()`-able */
    start: string
  }
}

export interface CernerUser {
  active: never
  address: never
  birthDate: never
  careProvider: never[]
  communication: never[]
  extension: never[]
  gender: never
  id: never
  identifier: never[]
  maritalStatus: never
  meta: never
  name: CernerUserName[]
  resourceType: 'Patient'
  telecom?: CernerTelecom[]
  text: never
}

/** Note:
 *  it is very important that we only create 1 UserManager and that UserManager
 *  does all of the login/logout work. This is because events like UserLoaded only fire
 *  on the UserManager that did the work
 *
 * TODO:
 *  - [ ] implement resize observer so that if user on desktop goes to fullscreen,
 *        we adjust our launchType accordingly
 */
export class AuthService {
  userManager: Promise<UserManager>
  hubUser?: HubUser

  constructor() {
    this.userManager = this.buildUserManager()
    this.hubUser = undefined
  }

  // Setup authentication status listeners
  public attachAuthListeners = async (
    setIsAuthenticated: (arg0: boolean) => void,
    setAuthReady: (arg0: boolean) => void,
    setHubAuthHeader: (arg0?: string) => void,
    setIdpAuthHeader: (arg0?: string) => void,
    setHubId: (arg0: string) => void,
    setUser: (arg0?: CernerUser) => void,
  ): Promise<void> => {
    const events = await this.events()

    events.addUserLoaded((user) => {
      setIdpAuthHeader(user.access_token)

      this.getUserProfile(user).then(setUser)

      this.getHubUser(user)
        .then((hubUser) => {
          setHubId(hubUser.hub_id)
          setHubAuthHeader(hubUser.access_token)
          setIsAuthenticated(true)
        })
        .catch(() => {
          this.removeUserAndState(
            setAuthReady,
            setIsAuthenticated,
            setHubAuthHeader,
            setIdpAuthHeader,
          )
        })
    })

    events.addUserUnloaded(() => {
      setIdpAuthHeader(undefined)
      setHubAuthHeader(undefined)
      setIsAuthenticated(false)
    })

    events.addAccessTokenExpired(() => {
      setIdpAuthHeader(undefined)
      setHubAuthHeader(undefined)
      setIsAuthenticated(false)
    })

    events.addAccessTokenExpiring(() => {
      // The refresh will trigger addUserLoaded method
      // to continue federated login
      this.refreshAccessToken().catch(() => {
        this.removeUserAndState(
          setAuthReady,
          setIsAuthenticated,
          setHubAuthHeader,
          setIdpAuthHeader,
        )
      })
    })
  }

  // This method loads the currently existing user data
  // into the UserManager. This should be called once on
  // initial startup.
  public initAuthenticatedSession = (
    setIsAuthenticated: (arg0: boolean) => void,
    setAuthReady: (arg0: boolean) => void,
    setHubAuthHeader: (arg0?: string) => void,
    setIdpAuthHeader: (arg0?: string) => void,
    setHubId: (arg0: string) => void,
    setUser: (arg0?: CernerUser) => void,
  ): void => {
    this.getUser().then((idpUser) => {
      if (idpUser && !idpUser.expired) {
        setIdpAuthHeader(idpUser.access_token)

        this.getUserProfile(idpUser).then(setUser)

        this.getHubUser(idpUser)
          .then((hubUser) => {
            setHubId(hubUser.hub_id)
            setHubAuthHeader(hubUser.access_token)
            setIsAuthenticated(true)
            setAuthReady(true)
          })
          .catch(() => {
            this.removeUserAndState(
              setAuthReady,
              setIsAuthenticated,
              setHubAuthHeader,
              setIdpAuthHeader,
            )
          })
      } else {
        this.removeUserAndState(
          setAuthReady,
          setIsAuthenticated,
          setHubAuthHeader,
          setIdpAuthHeader,
        )
      }
    })
  }

  public signIn = async (): Promise<SignInResult> => {
    const manager = await this.userManager
    const lt = await this._determineLaunchType()
    const method = signinMethods[lt]

    return (
      manager[method]()
        // has signatures, but none of those signatures are compatible with each other.  TS2349
        // @ts-expect-error really unsure about this error TS2349
        .then(() => SignInResult.success)
        .catch((err: SignInError) => {
          if (err.result === 'USER_CANCELLED') {
            return SignInResult.userCancelled
          } else {
            return SignInResult.failed
          }
        })
    )
  }

  public signOut = (): Promise<void> => {
    return this.userManager.then((manager) => manager.signoutRedirect())
  }

  public async refreshAccessToken(): Promise<void> {
    const manager = await this.userManager
    const user = await this.getUser()

    if (!user) {
      return Promise.reject(new Error('There is no user to refresh'))
    }

    // @ts-expect-error this method exists
    return manager._useRefreshToken({
      refresh_token: user.refresh_token,
      client_id: manager.settings.client_id,
    })
  }

  public removeUserAndState = async (
    setAuthReady: (arg0: boolean) => void,
    setIsAuthenticated: (arg0: boolean) => void,
    setHubAuthHeader: (arg0?: string) => void,
    setIdpAuthHeader: (arg0?: string) => void,
  ): Promise<void> => {
    this.removeUser()
      .then(() => this.clearStaleState())
      .then(() => {
        setIdpAuthHeader(undefined)
        setHubAuthHeader(undefined)
        setIsAuthenticated(false)
        setAuthReady(true)
      })
  }

  // ======================================================
  // These Functions just pass through to the UserManager
  // ======================================================
  public signinPopupCallback(_url?: string): Promise<User | undefined> {
    return this.userManager.then((manager) => manager.signinPopupCallback())
  }

  public signinRedirectCallback = (_url?: string): Promise<User> => {
    return this.userManager.then((manager) => manager.signinRedirectCallback())
  }

  public events(): Promise<UserManagerEvents> {
    return this.userManager.then((manager) => manager.events)
  }

  public getUser(): Promise<User | null> {
    return this.userManager.then((manager) => manager.getUser())
  }

  public removeUser(): Promise<void> {
    return this.userManager.then((manager) => manager.removeUser())
  }

  public clearStaleState(): Promise<void> {
    return this.userManager.then((manager) => manager.clearStaleState())
  }

  // Private Functions
  // ========================================
  private async buildUserManager(): Promise<UserManager> {
    const lt = await this._determineLaunchType()
    const config = this._buildConfig(lt)
    const manager = this._buildUserManager(config)

    return Promise.resolve(manager)
  }

  // This creates an ephemeral OIDC User. We store it for access, but
  // do not hook it into the underlying userManager events. This is because
  // we delegate token expiration and refreshing to the IDP User. This
  // prevents a situation where the access token from the IDP has expired,
  // but the Hub Access token hasn't.
  private getHubUser = (idpUser: User): Promise<HubUser> => {
    return request
      .post('/auth/federate_identity', {
        id_token: idpUser.id_token,
        access_token: idpUser.access_token,
      })
      .then((response) => {
        console.log('Logged in with hubId: ', response.data.hub_id)
        this.hubUser = response.data
        return response.data
      })
  }

  private getUserProfile = (idpUser: User): Promise<CernerUser> => {
    if (!idpUser.profile.profile) return Promise.reject()

    // Our axios interceptors strip auth headers for external requests.
    // Here, we do need to send the Authorization header when talking with
    // Cerner, so let's skip the interceptors
    const uninterceptedRequest = axios.create(BaseAxiosConfig)

    return uninterceptedRequest
      .get<CernerUser>(idpUser.profile.profile, {
        headers: {
          Authorization: request.defaults.headers['Authorization'],
        },
      })
      .then((resp) => resp.data)
  }

  private async _determineLaunchType(): Promise<launchType> {
    const info = await Device.getInfo()
    const fullWidth = window.innerWidth === window.screen.width

    let lt

    if (info.platform === 'ios') {
      lt = launchType.ios
    } else if (info.platform === 'android') {
      lt = launchType.android
    } else if (fullWidth) {
      lt = launchType.redirect
    } else {
      lt = launchType.popup
    }

    return Promise.resolve(lt)
  }

  private _buildConfig(lt: launchType): UserManagerSettings {
    return {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      stateStore: new WebStorageStateStore({ store: window.localStorage }),

      client_id: config.oauthConfig[lt].clientId,
      redirect_uri: config.oauthConfig[lt].redirectUrl,
      popupWindowFeatures:
        lt === launchType.popup
          ? config.oauthConfig.popup.windowOptions
          : undefined,
      authority: config.oauthConfig.shared.authority,
      response_type: 'code',
      scope: config.oauthConfig.shared.scope,
      loadUserInfo: false,
      extraQueryParams: {
        aud: config.oauthConfig.shared.aud,
      },
      accessTokenExpiringNotificationTime: 60,
      metadata: {
        issuer: config.oauthConfig.shared.iss,
        authorization_endpoint: config.oauthConfig.shared.authorizationBaseUrl,
        token_endpoint: config.oauthConfig.shared.accessTokenEndpoint,
        userinfo_endpoint: '',
        end_session_endpoint: '',
        jwks_uri: config.oauthConfig.shared.jwksUri,
      },
    }
  }

  private _buildUserManager(config: UserManagerSettings): UserManager {
    return new UserManager(config)
  }
}
