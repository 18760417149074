import { IQuestionnaire } from '@ahryman40k/ts-fhir-types/lib/R4'
import deepmerge from 'deepmerge'

import { DeepPartial } from '../../utils/type-helpers'

const buildQuestionnaire = (
  id: string,
  overrides: DeepPartial<IQuestionnaire> = {},
): IQuestionnaire => {
  return deepmerge<IQuestionnaire>(
    {
      resourceType: 'Questionnaire',
      id,
      // @ts-expect-error: This is a possible value in the hl7.org schema.
      // The types are likely wrong
      status: 'active',
      subjectType: ['Patient'],
    },
    overrides,
  )
}

export default buildQuestionnaire
