import { IQuestionnaire } from '@ahryman40k/ts-fhir-types/lib/R4'
import { useQuery } from 'react-query'

import request from '../../config/axios'

const useQuestionnaire = (id: string) => {
  const url = `/fhir/Questionnaire/${id}`
  return useQuery<IQuestionnaire>(url, () =>
    request.get(url).then((r) => r.data),
  )
}

export default useQuestionnaire
