import React, { ReactNode } from 'react'
import { Body2, makeStyles } from '@perk-ui/core'

export interface FeedLabelProps {
  /** The label to display. Wrapped in a bold, body2 Typography element */
  label: string
  /** An optional Avatar/Icon/generic content placed to the left of the label */
  avatar?: ReactNode
}

const useStyles = makeStyles((theme) => ({
  feedLabel: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.spacing(1),
    width: '100%',
    paddingBottom: theme.spacing(3),
  },
}))

const FeedLabel: React.FC<FeedLabelProps> = ({ label, avatar }) => {
  const classes = useStyles()

  return (
    <div className={classes.feedLabel}>
      {avatar}
      <Body2>{label}</Body2>
    </div>
  )
}

export default FeedLabel
