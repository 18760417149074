import React from 'react'
import { Card, CardContent, Stack } from '@perk-ui/core'

import WeeklyPainScores from './custom-sections/WeeklyPainScores'
import { PainAssessmentScoresItem } from './FeedResponse'

export interface PainAssessmentScoresCardProps {
  item: PainAssessmentScoresItem
}

const PainAssessmentScoresCard: React.FC<PainAssessmentScoresCardProps> = ({
  item,
}) => {
  const { title, data } = item

  return (
    <Card style={{ marginBottom: 24 }}>
      <CardContent>
        <Stack title={title} />
        <WeeklyPainScores data={data} />
      </CardContent>
    </Card>
  )
}

export default PainAssessmentScoresCard
