import React, { useEffect, useState } from 'react'
import {
  Body1,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  H2,
  Icon,
  makeStyles,
} from '@perk-ui/core'
import { FallbackProps } from 'react-error-boundary'
import { AlertOctagon } from 'react-feather'

import config from '../config/app-config'
import AppLinkButton from './AppLinkButton'

export interface ErrorAlertCardProps extends FallbackProps {}

const useStyles = makeStyles((theme) => ({
  redirectWarning: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
  },
}))

const ErrorAlertCard: React.FC<ErrorAlertCardProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const classes = useStyles()

  const [tick, setTick] = useState(5)
  useEffect(() => {
    if (tick === 0) {
      window.location.assign(config.authenticatedRootUrl)
      return
    }

    const tId = setTimeout(() => {
      setTick(tick - 1)
    }, 1000)

    return () => clearTimeout(tId)
  }, [tick])

  return (
    <Card role="alert">
      <CardContent>
        <H2 paragraph>Error</H2>
        <Body1>We&apos;re sorry, something went wrong.</Body1>
        <Body1>We have logged this error</Body1>
        <Body1>Details: {error.message}</Body1>

        <Box className={classes.redirectWarning}>
          <Icon size="x-large">
            <AlertOctagon style={{ paddingRight: 8 }} />
          </Icon>
          <Body1>This page will reload in {tick}</Body1>
        </Box>
      </CardContent>
      <CardActions>
        <Button fullWidth onClick={resetErrorBoundary} variant="outlined">
          Contact
        </Button>
        <AppLinkButton fullWidth to="/home" onClick={resetErrorBoundary}>
          Home
        </AppLinkButton>
      </CardActions>
    </Card>
  )
}

export default ErrorAlertCard
