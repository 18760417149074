export interface Resource {
  id: string
  title: string
  description?: string
  body: BodyItem[]
}

export type BodyItem = TextItem | LinkItem | VideoItem | ImageItem

export interface ItemMeta {
  bold?: boolean
}

export interface TextItem {
  type: 'text'
  value: string
  meta?: ItemMeta
}

export interface LinkItem {
  type: 'link'
  value: string
}

export interface VideoItem {
  type: 'video'
  value: string
  title: string
}

export interface ImageItem {
  type: 'image'
  source: {
    webp: string
    jpg: string
  }
  title: string
  alt: string
}

export const resourcesById = {
  'learning-more-about-pain': {
    id: 'learning-more-about-pain',
    title: 'Learning more about Pain',
    description: 'Pain is complicated click here for more information.',
    body: [
      {
        type: 'text',
        value:
          'Pain is complicated, find out more about the different kinds of pain and look for more resources at:',
      },
      {
        type: 'link',
        value: 'https://uspainfoundation.org/',
      },
      {
        type: 'link',
        value: 'https://www.theacpa.org/',
      },
      {
        type: 'link',
        value: 'https://uspainfoundation.org/resources/',
      },
    ],
  },
  'emotional-well-being-resources': {
    id: 'emotional-well-being-resources',
    title: 'Emotional Well-being Resources',
    description:
      'Chronic pain, especially severe pain, can have an enormous impact on your emotional health. Click here for resources to help.',
    body: [
      {
        type: 'text',
        value:
          'Chronic pain, especially severe pain, can have an enormous impact on your emotional health. Below are some strategies and resources for your mental health:',
      },
      {
        type: 'link',
        value:
          'https://uspainfoundation.org/living-with-pain/emotional-well-being',
      },
    ],
  },
  'withdrawal-symptoms-can-be-scary': {
    id: 'withdrawal-symptoms-can-be-scary',
    title: 'Withdrawal Symptoms can be scary',
    description:
      'Click to learn more about symptoms and when to seek professional help',
    body: [
      {
        type: 'text',
        value:
          'You may experience some withdrawal symptoms during your medication taper. These usually resolve within 7- 10 days after dosage change. If you do experience significant withdrawal symptoms, contact your doctor. There may be treatments available to help you manage your symptoms.',
      },
      {
        type: 'text',
        value:
          'If you are experiencing severe withdrawal symptoms please contact your doctor immediately or 911',
      },
      {
        type: 'text',
        value: 'Common withdrawal symptoms include:',
      },
      {
        type: 'image',
        source: {
          webp: `${process.env.PUBLIC_URL}/images/withdrawal-symptoms-table.webp`,
          jpg: `${process.env.PUBLIC_URL}/images/withdrawal-symptoms-table.jpg`,
        },
        alt:
          'Table describing early, late, and prolonged symtpoms of Opioid withdrawal',
      },
      {
        type: 'text',
        value:
          'This table was adapted from U.S. Department of Veterans Affairs Pain Management Opioid Taper Decision Tool:',
      },
      {
        type: 'link',
        value:
          'https://www.pbm.va.gov/AcademicDetailingService/Documents/Pain_Opioid_Taper_Tool_IB_10_939_P96820.pdf',
      },
    ],
  },
  'what-to-expect-in-your-taper': {
    id: 'what-to-expect-in-your-taper',
    title: 'What to expect in your taper',
    description:
      'Watch two doctors discuss their suggestions on the tapering journey.',
    body: [
      {
        type: 'text',
        value:
          'Starting a taper can be hard. Below are a set of physicians and someone who has gone through the tapering process sharing information on what to expect when you begin tapering.',
      },
      {
        type: 'video',
        value: 'https://www.youtube.com/embed//9dKeo-qq1Vo',
        title: 'Opioid Withdrawal',
      },
      {
        type: 'text',
        value:
          'Watch two doctors understand the difficulties of stopping opioids including what the symptoms of withdrawal are and their suggestions on the tapering journey.',
      },
      {
        type: 'video',
        value: 'https://www.youtube.com/embed/5kOGQkt3gKI',
        title: 'How long do opiate withdraws last',
      },
      {
        type: 'text',
        value:
          'Watch to learn more about Josh’s experience and hear his words of encouragement.',
      },
    ],
  },
  'find-people-who-understand-chronic-pain': {
    id: 'find-people-who-understand-chronic-pain',
    title: 'Find others who understand chronic pain',
    description:
      'Connecting with others who understand what it’s like to live with pain can be helpful. Here are resources that can help connect you.',
    body: [
      {
        type: 'text',
        value:
          'Connecting with others who understand what it’s like to live with pain can be helpful. Peer support groups often provide education and coping skills for chronic illness and come in many forms: in person, online, and over the phone. The resources below may help your find a group that is close to you.',
      },
      {
        type: 'link',
        value: 'https://www.theacpa.org/about-us/support-groups/ ',
      },
      {
        type: 'link',
        value: 'https://painconnection.org/support-groups/',
      },
    ],
  },
  'resources-to-help-you-reduce-your-pain': {
    id: 'resources-to-help-you-reduce-your-pain',
    title: 'Resources to help you reduce your pain',
    description:
      'Click here to see resources other than medication to help reduce your pain.',
    body: [
      {
        type: 'text',
        value:
          'Medication isn’t the only treatment for chronic pain. Learn more about other treatments for chronic pain at:',
      },
      {
        type: 'link',
        value: 'https://uspainfoundation.org/living-with-pain/treatment/ ',
      },
      {
        type: 'link',
        value:
          'https://uspainfoundation.org/living-with-pain/complementary-therapies/',
      },
    ],
  },
  'share-your-story': {
    id: 'share-your-story',
    title: 'Share your story',
    description:
      'Sharing your successes and difficulties can help you and others who are going through similar struggles. Click here to learn how to share your stories.',
    body: [
      {
        type: 'text',
        value:
          'Sharing your successes and difficulties can help you and others who are going through similar struggles: Share your story at:',
      },
      {
        type: 'link',
        value: 'https://uspainfoundation.org/get-involved/share-your-story/',
      },
    ],
  },
  'searching-for-more-help': {
    id: 'searching-for-more-help',
    title: 'Searching for more help?',
    description:
      'Click here to find additional resources for your journey including inpatient care.',
    body: [
      {
        type: 'text',
        value: 'If in an immediate crisis call 911 now',
      },
      {
        type: 'text',
        value:
          'If in need of more help less urgently The SAMHSA hotline is here to help you:',
      },
      {
        type: 'link',
        value: 'https://www.samhsa.gov/find-help/national-helpline',
      },
      {
        type: 'link',
        value: '1-800-662-HELP (4357)',
      },
      {
        type: 'link',
        value: 'https://findtreatment.samhsa.gov/',
      },
    ],
  },
  'another-way-to-think-about-pain': {
    id: 'another-way-to-think-about-pain',
    title: 'Another way to think about pain',
    description:
      'Click here to watch a video that frames chronic pain in a different lens',
    body: [
      {
        type: 'text',
        value:
          'Chronic Pain can feel a lot like being a car with four flat tires. Click here to watch a video that frames chronic pain in a different lens and gives more information about how to help fill up those tires:',
      },
      {
        type: 'link',
        value: 'https://www.theacpa.org/acpa-car-with-four-flat-tires/',
      },
    ],
  },
  'hear-about-other-people’s-tapering-journey': {
    id: 'hear-about-other-people’s-tapering-journey',
    title: 'Hear about Other People’s Tapering Journey',
    description:
      'Click here to listen to people who knows from experience what tapering is like',
    body: [
      {
        type: 'text',
        value:
          'Want to listen to someone who knows from experience what tapering is like?',
      },
      {
        type: 'text',
        value:
          'Listen to Green Bay Packers Hall of Famer Brett Farve talk about his experience with opioids',
      },
      {
        type: 'video',
        value: 'https://www.youtube.com/embed//1RMJIV3o6uI',
        title: 'Brett Favre on quitting Vicodin: I shook every night',
      },
      {
        type: 'text',
        value:
          'Watch to learn more about Josh’s experience and hear his words of encouragement.',
      },
      {
        type: 'video',
        value: 'https://www.youtube.com/embed//5kOGQkt3gKI',
        title: 'How long do opiate withdraws last',
      },
    ],
  },
  'learn-how-healthy-living-can-improve-your-pain': {
    id: 'learn-how-healthy-living-can-improve-your-pain',
    title: 'Learn how healthy living can improve your pain',
    body: [
      {
        type: 'text',
        value: 'From the US Pain Foundation:',
        meta: {
          bold: true,
        },
      },
      {
        type: 'text',
        value:
          '“When treating chronic pain, it is important to look at your overall health and well-being. Diet and pain can be connected. Make sure you are eating a healthy, balanced diet and that you are getting enough sleep each night. Symptoms of pain can sometimes worsen if you are not well rested.”',
      },
      {
        type: 'text',
        value: 'For more information on sleep and diet visit:',
      },
      {
        type: 'link',
        value: 'https://uspainfoundation.org/living-with-pain/self-management/',
      },
    ],
  },
  'learn-how-exercise-may-be-able-to-help-your-pain': {
    id: 'learn-how-exercise-may-be-able-to-help-your-pain',
    title: 'Learn how Exercise may be able to help your pain',
    body: [
      {
        type: 'text',
        value: 'From the US Pain Foundation:',
        meta: {
          bold: true,
        },
      },
      {
        type: 'text',
        value:
          '“Exercise is one of the most important ways you can improve your health and even manage pain. No matter how much pain you are in, there is almost always some type of exercise you can do–even if its just gentle ankle movements or hand squeezes. Some good examples of exercise for those with chronic pain include: walking; yoga; swimming; pilates; strength training. Talk to your doctor, a physical therapist, an occupational therapist, and/or a personal trainer about what is best for you.”',
      },
      {
        type: 'link',
        value:
          'https://uspainfoundation.org/living-with-pain/complementary-therapies/',
      },
      {
        type: 'text',
        value:
          "Here's a video with more information on exercising for chronic pain",
      },
      {
        type: 'video',
        value: 'https://www.youtube.com/embed/-4DMwbmQtkQ',
        title: 'The Power of Exercise',
      },
    ],
  },
  'how-mindfulness-and-meditation-can-help-your-pain': {
    id: 'how-mindfulness-and-meditation-can-help-your-pain',
    title: 'How meditation and mindfulness can help',
    body: [
      {
        type: 'text',
        value: 'From the US Pain Foundation:',
        meta: {
          bold: true,
        },
      },
      {
        type: 'text',
        value:
          '“Meditation quiets the nervous system and lowers stress, which decreases muscle tension, and can lead to lower pain levels.”',
      },
      {
        type: 'link',
        value:
          'https://uspainfoundation.org/living-with-pain/complementary-therapies/',
      },
      {
        type: 'link',
        value: 'http://www.freemindfulness.org',
      },
    ],
  },
  'learn-how-music-therapy-may-be-able-to-help-your-pain': {
    id: 'learn-how-music-therapy-may-be-able-to-help-your-pain',
    title: 'Learn how music therapy may be able to help your pain',
    body: [
      {
        type: 'text',
        value: 'From the US Pain Foundation:',
        meta: {
          bold: true,
        },
      },
      {
        type: 'text',
        value:
          '“Music therapy is using music to address physical, emotional, cognitive, and social needs of an individual. Treatment includes creating, singing, moving to, and/or listening to music.”',
      },
      {
        type: 'link',
        value:
          'https://uspainfoundation.org/living-with-pain/complementary-therapies/',
      },
      {
        type: 'link',
        value: 'https://www.musictherapy.org/about/musictherapy/',
      },
    ],
  },
  'learn-how-yoga-may-be-able-to-help-your-pain': {
    id: 'learn-how-yoga-may-be-able-to-help-your-pain',
    title: 'Learn how yoga may be able to help your pain',
    body: [
      {
        type: 'text',
        value: 'From the US Pain Foundation:',
        meta: {
          bold: true,
        },
      },
      {
        type: 'text',
        value:
          '“The ultimate goal of yoga is to reach the state of absolute peace and tranquility existing between mind and body. By practicing slow poses that require proper breathing, flexibility will increase along with a new sense of serenity.”',
      },
      {
        type: 'link',
        value:
          'https://uspainfoundation.org/living-with-pain/complementary-therapies/',
      },
      {
        type: 'text',
        value: 'Here is a video that can help guide you at home.',
      },
      {
        type: 'video',
        value: 'https://www.youtube.com/embed/BFaXfQpxnps',
        title: 'Yoga For Chronic Pain | Yoga With Adriene',
      },
    ],
  },
  'learn-how-art-therapy-may-be-able-to-help-your-pain': {
    id: 'learn-how-art-therapy-may-be-able-to-help-your-pain',
    title: 'Learn how art therapy may be able to help your pain',
    body: [
      {
        type: 'text',
        value: 'From the US Pain Foundation:',
        meta: {
          bold: true,
        },
      },
      {
        type: 'text',
        value:
          '“Art therapy is a creative method of expression used as a therapeutic technique. An art therapist works with a client—using many kinds of materials—to initiate a creative process that elicits feelings and realizations. The therapist helps the client process those feelings, draw conclusions, develop appropriate actions, and more.”',
      },
      {
        type: 'link',
        value:
          'https://uspainfoundation.org/living-with-pain/complementary-therapies/',
      },
      {
        type: 'link',
        value: 'https://arttherapy.org/about-art-therapy/',
      },
    ],
  },
  'learn-how-massage-may-be-able-to-help-your-pain': {
    id: 'learn-how-massage-may-be-able-to-help-your-pain',
    title: 'Learn how massage may be able to help your pain',
    body: [
      {
        type: 'text',
        value: 'From the US Pain Foundation:',
        meta: {
          bold: true,
        },
      },
      {
        type: 'text',
        value:
          '“One of the oldest healing arts known to mankind is massage. Massage therapy has been proven beneficial for many chronic pain conditions.”',
      },
      {
        type: 'link',
        value:
          'https://uspainfoundation.org/living-with-pain/complementary-therapies/',
      },
    ],
  },
  'learn-how-tai-chi-may-be-able-to-help-your-pain': {
    id: 'learn-how-tai-chi-may-be-able-to-help-your-pain',
    title: 'Learn how Tai Chi may be able to help your pain',
    body: [
      {
        type: 'text',
        value: 'From the US Pain Foundation:',
        meta: {
          bold: true,
        },
      },
      {
        type: 'text',
        value:
          '“T’ai Chi Chih is a simpler form of ancient T’ai chi based on twenty postures. It is referred to as a “moving meditation.” The main intention is to increase circulation while balancing the body’s own vital energy. Moving chi (energy) through the body’s meridians (main energy points) centers and calms the spirit. These slow, gentle movements create a soft, flowing, continual motion aligning the body, mind, and soul. T’ai Chi Chih has the power to heal. Good health, better flexibility, mental clarity, weight control, the balance of mind-body connection is all possible while practicing T’ai Chi Chih.”',
      },
      {
        type: 'link',
        value:
          'https://uspainfoundation.org/living-with-pain/complementary-therapies/',
      },
      {
        type: 'video',
        value: 'https://www.youtube.com/embed/FqkQqcSK1TY',
        title: 'Veterans Tai Chi for Pain and Stress',
      },
    ],
  },
  'learn-how-acupuncture-may-be-able-to-help-your-pain': {
    id: 'learn-how-acupuncture-may-be-able-to-help-your-pain',
    title: 'Learn how acupuncture may be able to help your pain',
    body: [
      {
        type: 'text',
        value: 'From the US Pain Foundation:',
        meta: {
          bold: true,
        },
      },
      {
        type: 'text',
        value:
          '“Acupuncture common in Chinese medicine, involves inserting thin, tiny needles into certain points of the body. Traditional Chinese practitioners believes acupuncture balances the flow of energy or life force — known as qi or chi. Western practitioners see it as a way to stimulate nerves and muscles in a way that promotes pain relief and healing.”',
      },
      {
        type: 'link',
        value:
          'https://uspainfoundation.org/living-with-pain/complementary-therapies/',
      },
      {
        type: 'link',
        value: 'https://www.nccih.nih.gov/health/acupuncture-in-depth',
      },
    ],
  },
  'learn-how-water-therapy-may-be-able-to-help-your-pain': {
    id: 'learn-how-water-therapy-may-be-able-to-help-your-pain',
    title: 'Learn how water therapy may be able to help your pain',
    body: [
      {
        type: 'text',
        value: 'From the US Pain Foundation:',
        meta: {
          bold: true,
        },
      },
      {
        type: 'text',
        value:
          '“Water therapy or aquatic therapy, is designed to improve or sustain gait, muscle strength and endurance, balance, agility, function, coordination, flexibility, function, and body mechanics and posture. Therapists can tailor treatments for each patient using underwater treadmills and orthotic or other assistive tools designed for water.”',
      },
      {
        type: 'link',
        value:
          'https://uspainfoundation.org/living-with-pain/complementary-therapies/',
      },
      {
        type: 'link',
        value: 'https://www.cdc.gov/arthritis/interventions/programs/afap.htm',
      },
    ],
  },
  'learn-how-relaxation-techniques-may-be-able-to-your-pain': {
    id: 'learn-how-relaxation-techniques-may-be-able-to-your-pain',
    title: 'How Relaxation Techniques can help your pain',
    body: [
      {
        type: 'text',
        value: 'From the US Pain Foundation:',
        meta: {
          bold: true,
        },
      },
      {
        type: 'text',
        value:
          '“Relaxation Techniques comes in many forms (imagery, progressive muscle relaxation, deep breathing). These exercises reduce the stress and muscle tension that are common with pain.”',
      },
      {
        type: 'text',
        value: 'See below for more information and relaxation audio.',
      },
      {
        type: 'link',
        value:
          'https://www.va.gov/PAINMANAGEMENT/Veteran_Public/Complementary_Treatments.asp',
      },
      {
        type: 'link',
        value:
          'https://www.theacpa.org/pain-management-tools/relaxation-audio/',
      },
      {
        type: 'video',
        value: 'https://www.youtube.com/embed/I2rdstg0XMo',
        title: 'Relaxation Guide',
      },
    ],
  },
} as { [key: string]: Resource }

export default Object.keys(resourcesById).map(
  (k) => resourcesById[k],
) as Resource[]

/*
{ type: 'text', value: '' },

{ type: 'link', value: '' },

{ type: 'video', value: '' },
*/
