import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import { useHistory } from '../../utils/useHistory'
import useRedirectCleanup from '../animation/useRedirectCleanup'
import { useInterruptManager } from './InterruptProvider'
import { InterruptRules } from './interrupts'

/**
 * All of the functionality of this component is encapsulated in a hook.
 * This component exists so that the Interrupts functionality can be added
 * in a more explicit way than a naked hook in a top-level component like `Routes`
 */
const InterruptManager: React.FC = () => {
  useRedirectCleanup()
  const { currentInterrupt } = useInterruptManager()

  // TODO: listen on Ionic Platform 'foreground'/'background' events
  const { push } = useHistory()
  const { pathname } = useLocation()

  // Navigate to the correct location in the app
  useEffect(() => {
    if (currentInterrupt) {
      // Prevent routing to a completed interrupt. This could happen after the
      // interrupt is completed, but before the new list has returned from the server
      if (currentInterrupt.state === 'completed') {
        return
      }

      const data = InterruptRules[currentInterrupt.kind]

      if (!data) {
        throw new Error(`Unknown Interrupt kind: ${currentInterrupt.kind}`)
      }

      if (!data.validLocations.some((vl: string) => pathname.startsWith(vl))) {
        push(data.initialPath)
      }
    }
  }, [push, pathname, currentInterrupt])

  return null
}

export default InterruptManager
