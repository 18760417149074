import React from 'react'

import CallToActionCard from './CallToActionCard'
import DailyJournalFeedItem from './DailyJournalFeedItem'
import {
  CallToActionItem,
  DailyJournalItem,
  FeedItem as IFeedItem,
  MedicationChangeItem,
  MedicationDecreaseItem,
  PainAssessmentScoresItem,
  PatientResourceItem,
} from './FeedResponse'
import MedicationChangeFeedItem from './MedicationChangeFeedItem'
import MedicationDecreaseFeedItem from './MedicationDecreaseFeedItem'
import PainAssessmentScoresCard from './PainAssessmentScoresCard'
import PatientResourceFeedItem from './PatientResourceFeedItem'

export interface FeedItemProps {
  /** The data for this FeedItem */
  item: IFeedItem
}

const FeedItem: React.FC<FeedItemProps> = React.memo(({ item }) => {
  switch (item.type) {
    case 'FeedSystem::DailyJournalFeedItem':
      return <DailyJournalFeedItem item={item as DailyJournalItem} />
    case 'FeedSystem::CallToActionFeedItem':
      return <CallToActionCard item={item as CallToActionItem} />
    case 'FeedSystem::MedicationDecreaseFeedItem':
      return (
        <MedicationDecreaseFeedItem item={item as MedicationDecreaseItem} />
      )
    case 'FeedSystem::WeeklyPainSurveyFeedItem':
      return (
        <PainAssessmentScoresCard item={item as PainAssessmentScoresItem} />
      )
    case 'FeedSystem::MedicationChangeFeedItem':
      return <MedicationChangeFeedItem item={item as MedicationChangeItem} />
    case 'FeedSystem::PatientResourceFeedItem':
      return <PatientResourceFeedItem item={item as PatientResourceItem} />
    default:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      throw new Error(`Unknown FeedItem type: ${(item as any).type}`)
  }
})

export default FeedItem
