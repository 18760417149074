import { IValueSet } from '@ahryman40k/ts-fhir-types/lib/R4'
import { QueryObserverResult, useQueries } from 'react-query'

import request from '../../../config/axios'
import { FhirBundle } from '../type-utils'

/**
 * Accepts a list of ValueSet @param urls to search for, returning
 * an array of responses that each look like `useQuery`'s return value.
 *
 * HAPI's search endpoint does not support multiple `url` queryparams so
 * we have to send off separate search queries for each url passed.
 * `useQueries` is kind of analogous to `Promise.all`, and simply wraps
 * what would otherwise be multiple `useQuery` calls together.
 *
 * @example
 * const urls = ['https://perkhealth.me/url-1']
 * const valueSetQueries = useValueSets(urls)
 * const valueSets = valueSetQueries.map((q) => q.data)
 * //=> valueSets[0].url === url[0]
 *
 * @param urls The ValueSet URLs to search for
 */
const useValueSets = (urls: string[]) => {
  const baseKey = '/fhir/ValueSet'

  const queries = urls.map((url) => {
    return {
      queryKey: [baseKey, url],
      staleTime: Infinity, // ValueSets change very rarely
      queryFn: () =>
        request
          .get<FhirBundle<IValueSet>>(baseKey, { params: { url } })
          .then((r) => {
            const data = r.data
            if (data.total !== 1) {
              throw new Error(
                'useValueSet search params must resolve to a single ValueSet entry',
              )
            }

            return data.entry?.[0].resource
          }),
    }
  })

  return useQueries(queries) as QueryObserverResult<IValueSet, unknown>[]
}

export default useValueSets
