import React, { useState } from 'react'
import {
  IQuestionnaire,
  IQuestionnaire_AnswerOption,
  IQuestionnaire_Item,
  IQuestionnaireResponse,
  IQuestionnaireResponse_Item,
  QuestionnaireResponseStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { tail } from '@perk-ui/core'
import deepmerge from 'deepmerge'
import { AnimatePresence, motion } from 'framer-motion'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router'

import AnimatedRedirect from '../../features/animation/AnimatedRedirect'
import { getQrScoreFromTheta } from '../../features/Fhir/painAssessmentUtils'
import useNextQuestion from '../../features/survey/useNextQuestion'
import { qrInitCacheKey } from '../../features/survey/useQRInit'
import useQuestionnaireResponse from '../../features/survey/useQuestionnaireResponse'
import { useHistory } from '../../utils/useHistory'
import { WeeklyAssessmentLocationState } from './Intro/WeeklyAssessmentIntro'
import Survey from './Survey'

interface SurveyControllerProps {
  questionnaireIds: string[]
}

const SurveyController: React.FC<SurveyControllerProps> = ({
  questionnaireIds,
}) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { state, pathname } = useLocation<WeeklyAssessmentLocationState>()
  const [goingBack, setGoingBack] = useState(false)
  const [activeQrId, setActiveQrId] = useState(questionnaireIds[0])
  const { mutateAsync: nextQuestion } = useNextQuestion()

  const [activeQrData, setActiveQrData] = useState<
    IQuestionnaireResponse | undefined
  >()

  // Fetch the initial Qr data remotely or from cache when activeQrId changes
  // Updates component state with setActiveQrData
  useQuestionnaireResponse(
    { id: activeQrId },
    {
      staleTime: 60 * 60 * 1000,
      initialData: () => {
        const cacheKey = qrInitCacheKey(activeQrId)
        const cacheData = queryClient.getQueryData<IQuestionnaireResponse>(
          cacheKey,
        )
        if (cacheData) {
          setActiveQrData(cacheData)
          return cacheData
        }
      },
      // Data loaded from cache does not hit the onSuccess cb
      onSuccess: (data: IQuestionnaireResponse) => {
        setActiveQrData(data)
      },
    },
  )

  const isStale = state.staleAt < new Date().getTime()
  const hasScores =
    !isStale && state.intensity.score && state.interference.score
  if (hasScores) {
    return (
      <AnimatedRedirect
        to={{ pathname: '/weekly-assessment/results', state }}
      />
    )
  }

  const hasValidState =
    !isStale &&
    state.intensity.questionnaireId &&
    state.interference.questionnaireId
  if (!hasValidState) {
    return (
      <AnimatedRedirect
        to={{ pathname: '/weekly-assessment/intro', state: undefined }}
      />
    )
  }

  const handleQrCompletion = (data: IQuestionnaireResponse) => {
    const nextState = deepmerge(state, {
      [state.activeAssessment]: {
        score: getQrScoreFromTheta(data) || 0,
      },
      activeAssessment:
        state.activeAssessment === 'intensity' ? 'interference' : 'intensity',
    })

    const isFinished = activeQrId === tail(questionnaireIds)
    if (isFinished) {
      // We've finished all Assessments asked of us
      // go to Results and with scores in the location state
      history.push('/weekly-assessment/results', nextState)
      return
    }

    // We just finished one Assessment - update scores and start the next
    const nextQrId = questionnaireIds[questionnaireIds.indexOf(activeQrId) + 1]
    setActiveQrId(nextQrId) // Triggers a fetching of the next Assessment
    history.replace(pathname, nextState)

    queryClient.invalidateQueries('/fhir/QuestionnaireResponse')
  }

  const fetchNextQuestion = async (
    questionnaireRequest: IQuestionnaireResponse,
  ): Promise<void> => {
    const data = await nextQuestion(questionnaireRequest)
    const isComplete =
      data.status === QuestionnaireResponseStatusKind._completed

    return isComplete ? handleQrCompletion(data) : setActiveQrData(data)
  }

  // TODO: this signature could be cleaned up
  const handleAnswer = (
    questionGroup: IQuestionnaire_Item,
    question: IQuestionnaire_Item,
    answerOption: IQuestionnaire_AnswerOption,
  ) => {
    if (!activeQrData) return Promise.reject('No Active QR to answer')

    const responseItem: IQuestionnaireResponse_Item = {
      extension: questionGroup.extension,
      linkId: questionGroup.linkId?.split('/')[0],
      item: [
        {
          linkId: question.linkId,
          text: question.text,
          answer: [answerOption],
        },
      ],
    }

    // Append this answer to the end of the other QuestionnaireResponse answers
    const questionnaireRequest = deepmerge<IQuestionnaireResponse>(
      activeQrData,
      {
        item: [responseItem],
      },
    )

    return fetchNextQuestion(questionnaireRequest)
  }

  const questions: IQuestionnaire_Item[] =
    (activeQrData?.contained?.[0] as IQuestionnaire)?.item || []

  const currentQuestion = questions[questions.length - 1]
  if (!currentQuestion || !currentQuestion.item) {
    return null
  }

  const prompt =
    currentQuestion.item.find((i) => i.type === 'display')?.text || ''
  const question = currentQuestion.item.find((i) => i.type === 'choice')
  const answerOptions = question?.answerOption || []

  if (!question || !answerOptions.length) {
    throw new Error(
      `Failed to parse question and options from QR: ${activeQrId}`,
    )
  }

  if (currentQuestion.type !== 'group') {
    // I'm sure this is a possible scenario, we just haven't run into it yet
    throw new Error('Current question is not a group')
  }

  // If we have any answers for the activeQr, we can go back
  const canGoBack = activeQrData && (activeQrData.item || []).length >= 1
  const goBack = () => {
    if (!activeQrData) return Promise.resolve()
    ;(activeQrData.item || []).pop()
    setGoingBack(true)
    return fetchNextQuestion(activeQrData).finally(() => setGoingBack(false))
  }

  return (
    <AnimatePresence initial>
      <motion.div
        key={currentQuestion.id}
        transition={{
          bounce: 0,
        }}
        initial={{
          opacity: 0.6,
          x: goingBack ? '-100vw' : '100vw',
        }}
        animate={{
          opacity: 1,
          x: 0,
        }}
        exit={{
          opacity: 0.4,
          position: 'absolute',
          x: goingBack ? '100vw' : '-100vw',
        }}
      >
        <Survey
          prompt={prompt}
          question={question?.text || ''}
          answerOptions={answerOptions}
          goBack={canGoBack ? goBack : undefined}
          onAnswer={(option) => handleAnswer(currentQuestion, question, option)}
        />
      </motion.div>
    </AnimatePresence>
  )
}

export default SurveyController
