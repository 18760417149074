import React from 'react'
import { HTMLMotionProps, motion } from 'framer-motion'
import { Target } from 'framer-motion/types/types'

import { useHistory } from '../../utils/useHistory'

export interface SlideProps extends HTMLMotionProps<'div'> {
  /** When true, disables the animation */
  disable?: boolean

  inFrom: Target['x']

  outTo: Target['x']
}

// TODO: Slide should support opacity
// TODO: Slide should support transition config changes
const Slide: React.FC<SlideProps> = ({
  children,
  inFrom,
  outTo,
  disable,
  ...rest
}) => {
  const { action } = useHistory()
  const reverse = action === 'POP'

  return (
    <motion.div
      transition={{
        bounce: 0,
      }}
      initial={
        disable
          ? undefined
          : {
              x: reverse ? outTo : inFrom,
            }
      }
      animate={
        disable
          ? undefined
          : {
              x: 0,
            }
      }
      exit={
        disable
          ? undefined
          : {
              x: reverse ? inFrom : outTo,
            }
      }
      {...rest}
    >
      {children}
    </motion.div>
  )
}

export default Slide
