import { ICarePlan } from '@ahryman40k/ts-fhir-types/lib/R4'
import { useQuery } from 'react-query'

import request from '../../../config/axios'
import { useHubId } from '../../auth/AuthContext'
import { PERK_HEALTH_SYSTEM } from '../../survey/questionnaire-data'
import { FhirBundle } from '../type-utils'

const usePrimaryCarePlan = () => {
  const url = 'fhir/CarePlan'
  const patientId = useHubId()

  return useQuery([url, 'primaryCarePlan'], () =>
    request
      .get<FhirBundle<ICarePlan>>(url, {
        params: {
          subject: `Patient/${patientId}`,
          category: `${PERK_HEALTH_SYSTEM}|primaryCarePlan`,
        },
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
      .then((resp) => (resp.data.entry || []).map((e) => e.resource)[0]),
  )
}

export default usePrimaryCarePlan
