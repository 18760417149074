import './config/analytics' // initializes the APM agent

import React from 'react'
import { Plugins } from '@capacitor/core'
import { PerkThemeProvider, PickersUtilsProvider } from '@perk-ui/core'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import AppErrorBoundary from './components/AppErrorBoundary'
import ScrollToTop from './components/ScrollToTop'
import queryClient from './config/react-query'
import { opioidTheme } from './config/theme'
import AuthProvider from './features/auth/AuthContext'
import InterruptManager from './features/interrupts/InterruptManager'
import InterruptProvider from './features/interrupts/InterruptProvider'
import Routes from './pages/Routes'

const { SplashScreen } = Plugins
SplashScreen.hide()

const App: React.FC = () => (
  <PerkThemeProvider theme={opioidTheme}>
    <PickersUtilsProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <InterruptProvider>
            <Router>
              <AppErrorBoundary>
                <InterruptManager />
                <ScrollToTop />
                <Routes />
              </AppErrorBoundary>
            </Router>
          </InterruptProvider>
        </AuthProvider>
      </QueryClientProvider>
    </PickersUtilsProvider>
  </PerkThemeProvider>
)

if (!__PROD__) {
  // For debugging and convenience purposes, we make the theme available
  // on the `window`
  // eslint-disable-next-line
  ;(window as any).theme = opioidTheme
  import('date-fns').then((dateFns) => {
    // eslint-disable-next-line
    ;(window as any).dateFns = dateFns
  })
}

export default App
