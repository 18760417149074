import deepmerge from 'deepmerge'

import { EnvConfig } from './config-interfaces'
import devConfig from './env-config.development'

const stagingConfig: EnvConfig = deepmerge(devConfig, {
  baseApiPath: 'https://hub.perk-demo.com',
  oauthConfig: {
    // This is for our Testflight and Firebase Beta apps as well as
    // chronicpain.perk-demo.com
    popup: {
      clientId: '8cf87058-eef5-4091-a92e-20e22e75a846',
      redirectUrl: 'http://chronic-pain.perk-demo.com/oauthPopupCallback',
      windowOptions: 'height=800,left=0,top=0',
    },
    // This is for our Testflight and Firebase Beta apps as well as
    // chronicpain.perk-demo.com
    redirect: {
      clientId: '94ea9d8c-32b8-4466-8f4d-3266082763ed',
      redirectUrl: 'https://chronic-pain.perk-demo.com/oauthRedirectCallback',
    },
  },
} as EnvConfig)

export default stagingConfig
