import React from 'react'
import { Box,Dialog, H5, IconButton, useToggle } from '@perk-ui/core'
import { HelpCircle } from 'react-feather'

import AppLink from '../../../components/AppLink'

export interface ActivityInfoProps {}

/**
 * TODO: Consider making this a generic component for '?' help icons
 */
const ActivityInfo: React.FC<ActivityInfoProps> = () => {
  const [isOpen, toggle] = useToggle(false)
  return (
    <>
      <IconButton
        aria-label="Help"
        size="small"
        disabled={isOpen}
        onClick={() => toggle()}
      >
        <HelpCircle />
      </IconButton>
      <Dialog open={isOpen} onClose={() => toggle()} fullWidth>
        <Box p={3}>
          <H5 style={{ marginBottom: '0.5rem' }}>Where do these come from?</H5>
          They are added by your clinician or yourself in the{' '}
          <AppLink style={{ fontWeight: 'bold' }} to="/me/manage-activities">
            My Activities
          </AppLink>{' '}
          page.
        </Box>
      </Dialog>
    </>
  )
}

export default ActivityInfo
