import React, { useEffect, useState } from 'react'

import config from '../../config/app-config'
import AnimatedRedirect from '../../features/animation/AnimatedRedirect'
import useRedirectCleanup from '../../features/animation/useRedirectCleanup'
import { useAuth } from '../../features/auth/AuthContext'

const OAuthRedirectCallback: React.FC = React.memo(() => {
  const [authFailed, setAuthFailed] = useState(false)
  const { signinRedirectCallback } = useAuth()

  // `signInRedirectCallback`'s redirect happens before AnimatePresence
  // is able to register this component as having mounted, causing
  // it's exiting animation to silently never complete.
  // Manually inform AnimatePresence we can unmount.
  useRedirectCleanup()

  useEffect(() => {
    signinRedirectCallback().catch(() => {
      setAuthFailed(true)
    })
  }, [signinRedirectCallback])

  return authFailed ? (
    <AnimatedRedirect to={config.unAuthenticatedRootUrl} />
  ) : null
})

export default OAuthRedirectCallback
