import React from 'react'
import { Card, CardActions, CardContent, Stack } from '@perk-ui/core'

import AppLinkButton from '../../components/AppLinkButton'
import CardImage from '../../components/CardImage'
import { toRelativeDateOrString } from '../../utils/dates'
import ReadButton from './custom-sections/ReadButton'
import { CallToActionItem } from './FeedResponse'

export interface CallToActionCardProps {
  item: CallToActionItem
}

const CallToActionCard: React.FC<CallToActionCardProps> = ({ item }) => {
  const { title, description, action, media, time_helper } = item

  const image = media?.image
  const isReadAction = action.type === 'read'
  const isBottomAction =
    action.type === 'start_survey' || action.type === 'see_options'

  const helperText = toRelativeDateOrString(time_helper)

  return (
    <Card style={{ marginBottom: 24 }}>
      {image && (
        <CardImage src={image?.src} title={image?.title} alt={image?.alt} />
      )}
      <CardContent>
        <Stack
          title={title}
          body={description}
          helper={helperText}
          action={
            isReadAction ? (
              <ReadButton target={action.path} variant="text" iconSize="medium">
                Read
              </ReadButton>
            ) : undefined
          }
        />
      </CardContent>
      {isBottomAction && (
        <CardActions>
          <AppLinkButton size="medium" fullWidth to={action.path}>
            {action.title}
          </AppLinkButton>
        </CardActions>
      )}
    </Card>
  )
}

export default CallToActionCard
