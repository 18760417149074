import React from 'react'
import { Button, ButtonGroup, ButtonGroupProps } from '@perk-ui/core'

interface ControlledButtonGroupProps extends ButtonGroupProps {
  onButtonSelect(id: string): Promise<void>
}

const ControlledButtonGroup: React.FC<ControlledButtonGroupProps> = ({
  children,
  onButtonSelect,
  ...rest
}) => {
  const [loading, setLoading] = React.useState<string | undefined>()
  const [selected, setSelected] = React.useState<string | undefined>()

  const handleClick = async (id: string) => {
    setSelected(undefined)
    if (id !== selected) {
      setLoading(id)
      try {
        await onButtonSelect(id)
      } finally {
        setLoading(undefined)
      }
    }
  }

  const processedChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child) || child.type !== Button) {
      return child
    }

    const { id } = child.props
    return React.cloneElement(child, {
      loading: loading !== undefined && id === loading,
      selected: selected !== undefined && id === selected,
      onClick: (event: React.MouseEvent) => {
        handleClick(id)
        if (child.props.onClick) {
          child.props.onClick(event)
        }
      },
    })
  })

  return <ButtonGroup {...rest}>{processedChildren}</ButtonGroup>
}

export default ControlledButtonGroup
