import React from 'react'
import {
  Body1,
  Box,
  Dialog,
  H1,
  Icon,
  makeStyles,
  usePageContext,
} from '@perk-ui/core'
import { ArrowDown, ArrowUp } from 'react-feather'
import { useHistory } from 'react-router'

import { fancyFontFamily } from '../../../config/theme'

const useStyles = makeStyles((theme) => ({
  // Applied to the Dialog on non-mobile app platforms
  container: {
    height: '85vh',
  },
  // Applied to the Dialog container in mobile apps
  mobileAppContainer: {
    height: 'calc(100vh - 100px - 80px)',
  },
  paper: {
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr 3fr 1fr',
  },
  icon: {
    stroke: theme.palette.grey[600],
  },
}))

const DailyJournalPrompt: React.FC = () => {
  const classes = useStyles()
  const { replace, location } = useHistory()
  const { navVariant } = usePageContext()
  const pointUp = navVariant === 'tab'
  const dirToJournalButton = pointUp ? 'above' : 'below'

  const handleClose = () => {
    const currentUrl = new URLSearchParams(location.search)
    currentUrl.delete('prompt')

    replace({
      ...location,
      search: `?${String(currentUrl)}`,
    })
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      style={{
        // 999 displays above page content but below the Navigation bar.
        zIndex: 999,
        top: 0,
        marginTop: pointUp ? 120 : 100,
      }}
      classes={{
        paper: classes.paper,
        container: pointUp ? classes.container : classes.mobileAppContainer,
      }}
      PaperProps={{
        style: { flexDirection: pointUp ? 'column-reverse' : 'column' },
      }}
    >
      <Box style={{ gridRowStart: pointUp ? 3 : 1 }}></Box>
      <Box
        style={{
          display: 'flex',
          gridRowStart: 2,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <H1>Daily Journal</H1>
      </Box>
      <Box
        px={6}
        py={2}
        style={{
          gridRowStart: pointUp ? 1 : 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: pointUp ? 'column-reverse' : 'column',
        }}
      >
        <Body1 style={{ textAlign: 'center', fontFamily: fancyFontFamily }}>
          Click on &quot;Journal&quot; {dirToJournalButton} to track your daily
          pain and activities.
        </Body1>
        <Icon color="secondaryText" size="x-large">
          {pointUp ? (
            <ArrowUp className={classes.icon} style={{ marginBottom: 16 }} />
          ) : (
            <ArrowDown className={classes.icon} style={{ marginTop: 16 }} />
          )}
        </Icon>
      </Box>
    </Dialog>
  )
}

export default DailyJournalPrompt
