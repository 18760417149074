"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tail = void 0;
/**
 * Returns the last element of @param array
 */
function tail(array) {
    return array[array.length - 1];
}
exports.tail = tail;
