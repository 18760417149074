import { IQuestionnaireResponse } from '@ahryman40k/ts-fhir-types/lib/R4'
import { useQuery, UseQueryOptions } from 'react-query'

import request from '../../config/axios'

const useQuestionnaireResponse = (
  { id, version }: { id: string | null; version?: string },
  useQueryOptions?: UseQueryOptions<IQuestionnaireResponse>,
) => {
  const baseKey = '/fhir/QuestionnaireResponse'
  const baseUrl = `${baseKey}/${id}`
  const key = version ? [baseKey, id, version] : [baseKey, id]

  const url = version ? `${baseUrl}/_history/${version}` : baseUrl

  return useQuery<IQuestionnaireResponse>(
    key,
    () => request.get(url).then((r) => r.data),
    useQueryOptions,
  )
}

export default useQuestionnaireResponse
