import React from 'react'
import { Card, CardContent, H3, H5, makeStyles,Skeleton } from '@perk-ui/core'

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(3),
  },
}))

const FeedItemSkeleton: React.FC = () => {
  const classes = useStyles()

  return (
    <Card className={classes.item}>
      <CardContent>
        <Skeleton width={'80%'}>
          <H3>Hi</H3>
        </Skeleton>
        <Skeleton width={'30%'}>
          <H5>Hi</H5>
        </Skeleton>
        <br />
        <Skeleton variant="rect" height={80} />
      </CardContent>
    </Card>
  )
}

export default FeedItemSkeleton
