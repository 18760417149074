import React from 'react'
import { Button, ButtonProps } from '@perk-ui/core'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

export type AppLinkButtonProps = Omit<ButtonProps, 'component'> & LinkProps

/**
 * A Button which uses an underlying React-Router Link component for in-app navigation
 *
 * TODO: Should be merged with the AppLink (perhaps as a 'button' prop). The types
 * for that will be pretty complex, so for now I've kept them separate.
 * TODO: Migrate to Core once the usecases in this app are more flushed out.
 * TODO: rename
 */
const AppLinkButton: React.FC<AppLinkButtonProps> = ({
  children,
  to,
  ...rest
}) => (
  <Button component={RouterLink} to={to} {...rest}>
    {children}
  </Button>
)

export default AppLinkButton
