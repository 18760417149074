import React from 'react'
import { Body1, H5, makeStyles } from '@perk-ui/core'

import {
  intensityScoreMap,
  interferenceScoreMap,
  scoreToLabel,
  weeklyAssessmentScoreToColor,
} from '../../../utils/painScores'
import { PainAssessmentScoresItem } from '../FeedResponse'

interface WeeklyPainScoresProps {
  /** The FeedItem-specific data to render  */
  data: PainAssessmentScoresItem['data']
}

const useStyles = makeStyles((theme) => ({
  painScore: {
    paddingTop: theme.spacing(2),
  },
  painScoreModerate: {
    color: 'orange',
  },
  painScoreHigh: {
    color: 'red',
  },
}))

const WeeklyPainScores: React.FC<WeeklyPainScoresProps> = ({ data }) => {
  const classes = useStyles()
  const { intensity, interference } = data.scores
  const { main: intensityColor } = weeklyAssessmentScoreToColor(
    intensityScoreMap,
    intensity.value,
  )
  const { main: interferenceColor } = weeklyAssessmentScoreToColor(
    interferenceScoreMap,
    interference.value,
  )

  return (
    <div>
      <div className={classes.painScore}>
        <Body1>Pain Intensity</Body1>
        <H5
          style={{
            color: intensityColor,
          }}
        >
          {intensity.value} {scoreToLabel(intensityScoreMap, intensity.value)}
        </H5>
      </div>
      <div className={classes.painScore}>
        <Body1>Pain Interference</Body1>
        <H5
          style={{
            color: interferenceColor,
          }}
        >
          {interference.value}{' '}
          {scoreToLabel(interferenceScoreMap, interference.value)}
        </H5>
      </div>
    </div>
  )
}

export default WeeklyPainScores
