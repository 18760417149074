import {
  ICarePlan,
  IExtension,
  IMedicationRequest,
  IResourceList,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { isAfter, isValid } from 'date-fns'

import {
  findContainedResourceById,
  findExtension,
  findExtensionArray,
} from '../utils'

// TaperStep Specific /features/fhir/CarePlan/taperStep.ts
// getInitialValues(resource:) {}
// getFinalValues(resource:) {} // => { mme: Integer, medications: Medication[] }
// getSettings(resource:) {}

interface TaperStepUrls {
  primaryUrl: string
  mmeUrl: string
  medicationUrl: string
}

interface TaperStepValues {
  mme: number
  medications: IMedicationRequest[]
}

const baseExtensionUrl = __STAGING__
  ? 'http://hub.perk-demo.com/StructureDefinition'
  : 'https://perkhealth.me/StructureDefinition'

export const taperExtensionUrls = {
  initial: {
    primaryUrl: `${baseExtensionUrl}/taper-step-initial`,
    mmeUrl: `${baseExtensionUrl}/taper-step-initial_mme`,
    medicationUrl: `${baseExtensionUrl}/taper-step-initial_medications`,
  },
  final: {
    primaryUrl: `${baseExtensionUrl}/taper-step-final`,
    mmeUrl: `${baseExtensionUrl}/taper-step-final_mme`,
    medicationUrl: `${baseExtensionUrl}/taper-step-final_medications`,
  },
}

// Get the initial TaperStep Values
export function getInitialTaperStepValues(
  resource: ICarePlan,
): TaperStepValues {
  return _getTaperStepValues(resource, taperExtensionUrls.initial)
}

// Get the final TaperStep Values
export function getFinalTaperStepValues(resource: ICarePlan): TaperStepValues {
  return _getTaperStepValues(resource, taperExtensionUrls.final)
}

export function getTaperStepMedications(
  resource: ICarePlan,
): IMedicationRequest[] {
  if (!resource) return []

  const stepValues = getFinalTaperStepValues(resource)

  return stepValues.medications
}

// Local Functions
// ========================
function _getTaperStepValues(
  resource: ICarePlan,
  urls: TaperStepUrls,
): TaperStepValues {
  const ext = resource.extension

  if (!ext) {
    throw new Error('TaperStep missing extensions')
  }

  const initialExt = findExtension(urls.primaryUrl, ext)
  if (!initialExt || !initialExt.extension) {
    throw new Error('TaperStep missing step value extension ' + urls.primaryUrl)
  }

  const mme = findExtension(urls.mmeUrl, initialExt.extension)
    .valueDecimal as number

  const medications = getMedications(
    resource,
    initialExt.extension,
    urls.medicationUrl,
  )

  return { mme, medications }
}

function getMedications(
  resource: ICarePlan,
  exts: IExtension[],
  url: string,
): IMedicationRequest[] {
  // Find the medicationIds from the extension
  const medicationExtensions: IExtension[] = findExtensionArray(url, exts)

  if (resource.contained) {
    return medicationExtensions
      .map((ext) => {
        return ext.valueReference?.reference as string
      })
      .filter((i) => !!i)
      .map((id) => {
        return findContainedResourceById(
          id,
          resource.contained as IResourceList[],
        ) as IMedicationRequest
      })
  }

  return []
}

export const findCurrentTaperStep = (taperSteps: ICarePlan[]) => {
  return taperSteps.find((step) => {
    const startDate = new Date(step.period?.start || '')
    const endDate = new Date(step.period?.end || '')
    if (!isValid(startDate) || !isValid(endDate)) return false

    const now = new Date()
    return isAfter(now, startDate) && !isAfter(now, endDate)
  })
}
