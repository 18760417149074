import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { FeedItem, FeedItemSkeleton } from '../../features/feed'
import useFeedSearch from '../../features/feed/useFeedSearch'

const HomeFeed: React.FC = () => {
  const { isLoading, data } = useFeedSearch({ feedName: 'homefeed' })

  const feedItems = data?.feed_items || []

  return (
    <div>
      {isLoading ? (
        <>
          <FeedItemSkeleton />
          <FeedItemSkeleton />
          <FeedItemSkeleton />
        </>
      ) : (
        <AnimatePresence initial exitBeforeEnter={false}>
          {feedItems.map((item) => (
            <motion.div
              key={item.id}
              initial={{
                transform: 'scale(0.8)',
                translateY: '20px',
                opacity: 0.8,
              }}
              animate={{ transform: 'scale(1)', translateY: '0px', opacity: 1 }}
            >
              <FeedItem key={item.id} item={item} />
            </motion.div>
          ))}
        </AnimatePresence>
      )}
    </div>
  )
}

export default HomeFeed
