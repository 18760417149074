import React, { Fragment } from 'react'
import { IQuestionnaireResponse } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Divider } from '@perk-ui/core'
import { isBefore, isSameDay, isValid } from 'date-fns'

import LabeledAvatar from '../../../components/LabeledAvatar'
import PainScoreCircle from '../../../components/PainScoreCircle'
import { getDailyJournalPainScore } from '../../../features/Fhir/dailyJournalUtils'
import { latestQrOnDay } from '../../../features/Fhir/utils'
import { LAST_7_DAYS, toDayAbbreviation, toDayName } from '../../../utils/dates'
import { DailyJournalPainScore } from '../../../utils/painScores'

export interface PastDailyJournalSelectorProps {
  pastDailyJournals: IQuestionnaireResponse[]
  /**
   * The date to display as currently selected
   */
  selectedDate: Date
  /**
   * The score to display for the current selectedDate.
   */
  selectedScore?: DailyJournalPainScore
  /**
   * Disable selecting dates before this date
   */
  disableBefore?: Date
  /**
   * A callback fired when a new day is clicked
   */
  onDaySelect: (date: Date, qrId?: string) => void
}

const PastDailyJournalSelector: React.FC<PastDailyJournalSelectorProps> = React.memo(
  ({
    selectedDate,
    selectedScore,
    onDaySelect,
    pastDailyJournals,
    disableBefore,
  }) => {
    if (isBefore(selectedDate, LAST_7_DAYS[0])) {
      throw new Error(
        `PastDailyJournalSelector's selectedDate can't be earlier than 7 days ago. Was ${selectedDate}`,
      )
    }
    const disabledBeforeDate =
      disableBefore && isValid(disableBefore)
        ? disableBefore
        : new Date('1-1-1900')

    return (
      <>
        {LAST_7_DAYS.map((date, idx) => {
          const qr = latestQrOnDay(date, pastDailyJournals)
          const qrScore = getDailyJournalPainScore(qr) ?? undefined
          const isSelected = isSameDay(date, selectedDate)
          const showDividerBar = toDayName(date) === 'Sunday' && idx !== 6
          const disabled = isBefore(date, disabledBeforeDate)

          return (
            <Fragment key={String(date)}>
              <div
                role="button"
                aria-label={toDayName(date)}
                style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                onClick={() =>
                  !isSelected && !disabled && onDaySelect(date, qr?.id)
                }
              >
                <LabeledAvatar
                  label={toDayAbbreviation(date)}
                  ariaLabel={toDayName(date)}
                  isHighlighted={isSelected}
                >
                  <PainScoreCircle
                    score={isSelected ? selectedScore : qrScore}
                  />
                </LabeledAvatar>
              </div>
              {showDividerBar && (
                <Divider
                  orientation="vertical"
                  style={{
                    // Fix for a Safari bug: the Divider does not expand to
                    // fill the avilable vertical space
                    height: 'auto',
                  }}
                />
              )}
            </Fragment>
          )
        })}
      </>
    )
  },
)

export default PastDailyJournalSelector
