import React, { useState } from 'react'
import {
  IQuestionnaireResponse_Item,
  IValueSet_Concept,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Body2,
  Box,
  Button,
  Dialog,
  H5,
  HeaderBar,
  HeaderBarButton,
  Icon,
  makeStyles,
  useTheme,
} from '@perk-ui/core'
import clsx from 'clsx'
import { ArrowRight } from 'react-feather'

import Emoji from '../../../components/Emoji'
import EmojiGroup from '../../../components/EmojiGroup'
import { Footer, Header } from '../../../components/Layout'
import { findBy } from '../../../utils/arrays'
import { capitalize } from '../../../utils/strings'

export interface SentimentsProps {
  item: IQuestionnaireResponse_Item
  sentiments: IValueSet_Concept[]
  value: IValueSet_Concept[]
  onChange: (value: IValueSet_Concept[]) => void
}

const useStyles = makeStyles((theme) => ({
  emojiPrompt: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    maxHeight: 60,
  },
  emojiCard: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    width: `calc(50% - ${theme.spacing(1)}px)`,
    '&:nth-child(odd)': {
      marginRight: theme.spacing(1),
    },
    '&:nth-child(even)': {
      marginLeft: theme.spacing(1),
    },
  },
  selectedEmoji: {
    backgroundColor: theme.palette.primary.main,
  },
}))

const Sentiments: React.FC<SentimentsProps> = ({
  sentiments,
  value,
  onChange,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleBackButtonClick = () => {
    setIsOpen(false)
  }

  const handleItemSelect = (selected: IValueSet_Concept) => {
    let nextValue = null
    if (findBy('code', selected.code, value)) {
      nextValue = value.filter((v) => v.code !== selected.code)
    } else {
      nextValue = [...value, selected]
    }

    onChange(nextValue)
  }

  return (
    <>
      <Button
        className={classes.emojiPrompt}
        variant="outlined"
        fullWidth
        onClick={handleOpen}
        startIcon={
          <Icon color="secondaryText" size="x-large">
            <ArrowRight stroke={theme.palette.grey[800]} />
          </Icon>
        }
      >
        <Box
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <H5 color="textPrimary" style={{ fontWeight: 'normal' }}>
            Select
          </H5>
          {value.length > 0 ? (
            <EmojiGroup max={4}>
              {value.map((sentiment) => {
                const label = capitalize(sentiment.code || 'Lost label')
                const symbol = sentiment.display || ':('

                return (
                  <Emoji
                    key={symbol}
                    style={{ fontSize: '1.9rem' }}
                    symbol={symbol}
                    label={label}
                  />
                )
              })}
            </EmojiGroup>
          ) : (
            <Emoji
              symbol="🤗"
              label="Hugging face"
              style={{ fontSize: '1.9rem' }}
            />
          )}
        </Box>
      </Button>

      <Dialog
        fullScreen
        aria-labelledby="emoji-picker-title"
        open={isOpen}
        onClose={handleClose}
        style={{
          maxWidth: 620,
          width: '100%',
          margin: 'auto',
        }}
      >
        {/* TODO Add a fade animation */}
        <Header withNav={false}>
          <HeaderBar
            titleContent={
              <H5 id="emoji-picker-title" align="center">
                Today
              </H5>
            }
            left={
              <HeaderBarButton variant="back" onClick={handleBackButtonClick} />
            }
          />
        </Header>
        <Box p={3}>
          <H5 style={{ marginBottom: 16 }}>How do you feel?</H5>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            {sentiments.map((sentiment) => {
              const label = capitalize(sentiment.code || 'Lost label')
              const symbol = sentiment.display || ':('
              const isSelected = !!findBy('code', sentiment.code, value)

              return (
                <Button
                  key={symbol}
                  className={clsx(classes.emojiCard, {
                    [classes.selectedEmoji]: isSelected,
                  })}
                  color={isSelected ? 'primary' : 'inherit'}
                  onClick={() => handleItemSelect(sentiment)}
                >
                  <Emoji
                    style={{ paddingLeft: 16 }}
                    symbol={symbol}
                    label={label}
                  />
                  <Body2 color="inherit" style={{ paddingLeft: 8 }}>
                    {label}
                  </Body2>
                </Button>
              )
            })}
          </Box>
          <Footer withNav={false} floating>
            <Box p={3}>
              <Button fullWidth onClick={handleClose}>
                Done
              </Button>
            </Box>
          </Footer>
        </Box>
      </Dialog>
    </>
  )
}

export default Sentiments
