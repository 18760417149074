import React from 'react'
import { RouteProps, useLocation } from 'react-router-dom'

import config from '../config/app-config'
import useRouteChangeEvent from '../features/analytics/useRouteChangeEvent'
import AnimatedRedirect from '../features/animation/AnimatedRedirect'
import RouteTransition from '../features/animation/RouteTransition'
import { useAuth } from '../features/auth/AuthContext'

export type PrivateRouteProps = RouteProps

/**
 * A Route which requires the user to be authenticated before mounting the Route's children.
 *
 * If the user is not authenticated, redirects back to `/login` with info about what the user
 * was trying to access.
 *
 * TODO: Because the AuthFlow triggers an opening of a browser or changing the
 * current tab's path, we can't rely on location state to keep track of what
 * page they were heading to. We should probably use something more persistent
 * to track it, like localStorage.
 */
const PrivateRoute: React.FC<PrivateRouteProps> = React.memo(
  ({ children, ...rest }) => {
    const { pathname } = useLocation()
    const { isAuthenticated } = useAuth()

    useRouteChangeEvent()

    if (!isAuthenticated) {
      return (
        <AnimatedRedirect
          to={{
            pathname: config.unAuthenticatedRootUrl,
            state: { from: pathname, isAuthRedirect: true },
          }}
        />
      )
    }

    return <RouteTransition {...rest} />
  },
)

export default PrivateRoute
