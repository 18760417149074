import React from 'react'
import { Avatar, useTheme } from '@perk-ui/core'
import { Check } from 'react-feather'

export interface ActivityAvatarProps {
  hadActivities?: boolean
}

const ActivityAvatar: React.FC<ActivityAvatarProps> = ({ hadActivities }) => {
  const theme = useTheme()
  const backgroundColor = hadActivities
    ? theme.palette.success.main
    : theme.palette.grey[300]

  return (
    <Avatar
      style={{
        backgroundColor,
        margin: 1, // breathing room for extra small screens
      }}
      size="small"
    >
      {hadActivities && <Check />}
    </Avatar>
  )
}

export default ActivityAvatar
