import React from 'react'
import {
  Body1,
  Box,
  Card,
  CardContent,
  H3,
  H5,
  HeaderBar,
  HeaderBarButton,
  HorizontalScroll,
  HorizontalScrollItem,
  Icon,
  Skeleton,
  Stack,
} from '@perk-ui/core'
import { motion } from 'framer-motion'
import { Settings } from 'react-feather'

import AppLink from '../../components/AppLink'
import { Footer, Header, Page } from '../../components/Layout'
import ReadButton from '../../features/feed/custom-sections/ReadButton'
import { findCurrentTaperStep } from '../../features/Fhir/CarePlan/taperStepUtils'
import useTaperStepSearch from '../../features/Fhir/CarePlan/useTaperStepSearch'
import DailyJournalActivities from './ActivityHistory/components/DailyJournalActivities'
import DailyJournalScores from './components/DailyJournalScores'
import MedicationDecreaseCard from './components/MedicationDecreaseCard'
import ResourceFeed from './components/ResourceFeed'
import TaperStepMedications from './components/TaperStepMedications'

const Me: React.FC = React.memo(() => {
  // Load and prepare Medication Decrease Graph Data
  const {
    isLoading: isLoadingTaperData,
    data: taperStepData = [],
  } = useTaperStepSearch()
  const currentTaperStep = findCurrentTaperStep(taperStepData)

  return (
    <Page
      title="Taper App - Me"
      header={
        <Header withNav>
          <HeaderBar
            titleContent={
              <motion.div
                transition={{ bounce: 0 }}
                initial={{
                  x: 16,
                  opacity: 0.6,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                }}
                exit={{
                  x: -40,
                  opacity: 0.4,
                }}
              >
                <H5 align="center">Me</H5>
              </motion.div>
            }
            right={
              <HeaderBarButton aria-label="Settings">
                <AppLink to="/settings">
                  <Icon color="primaryText">
                    <Settings style={{ marginTop: 6 }} />
                  </Icon>
                </AppLink>
              </HeaderBarButton>
            }
          />
        </Header>
      }
      footer={<Footer withNav withSafeInset={false} />}
    >
      {!isLoadingTaperData ? (
        <>
          <Box p={3} pb={0}>
            <H5 gutterBottom>Resources</H5>
          </Box>

          <Box px={3} pt={1} mb={3}>
            <ResourceFeed />
          </Box>

          <Box px={3} pt={1}>
            <H5>My History</H5>
          </Box>

          <Box p={3}>
            <Card style={{ marginBottom: 24 }}>
              <CardContent>
                <Stack
                  title="My Pain"
                  action={
                    <ReadButton iconSize="medium" target="/me/pain">
                      View
                    </ReadButton>
                  }
                />
                <DailyJournalScores />
              </CardContent>
            </Card>

            <Card style={{ marginBottom: 24 }}>
              <CardContent>
                <Stack
                  title="My Activities"
                  action={
                    <ReadButton iconSize="medium" target="/me/activity">
                      View
                    </ReadButton>
                  }
                />
                <DailyJournalActivities />
              </CardContent>
            </Card>

            { currentTaperStep && <Card style={{ marginBottom: 24 }}>
              <CardContent>
                <Stack
                  title="My Medications"
                  action={
                    <ReadButton iconSize="medium" target="/me/taper">
                      View
                    </ReadButton>
                  }
                />
                {currentTaperStep ? (
                  <Box mt={2}>
                    <TaperStepMedications
                      taperStep={currentTaperStep}
                      withDate={false}
                    />
                  </Box>
                ) : null}
              </CardContent>
            </Card> }

            {taperStepData.length > 1 ? (
              <MedicationDecreaseCard data={taperStepData} />
            ) : null}
          </Box>
        </>
      ) : (
        /*
         * ------ LOADING SKELETON ------
         */
        <Box m={3} mb={0}>
          <Skeleton style={{ marginBottom: 4 }}>
            <Box p={3} pb={0}>
              <H5>Resources</H5>
            </Box>
          </Skeleton>

          <Box mb={3}>
            <HorizontalScroll offsetSpacing={3}>
              {Array.from(new Array(3)).map((_, idx) => (
                <HorizontalScrollItem key={idx}>
                  <Card style={{ height: '100%' }}>
                    <Skeleton variant="rect" height={70}></Skeleton>
                    <CardContent style={{ padding: 12 }}>
                      <Skeleton>
                        <Body1 paragraph>Lorem ipsum dolor sit amet</Body1>
                      </Skeleton>
                    </CardContent>
                  </Card>
                </HorizontalScrollItem>
              ))}
            </HorizontalScroll>
          </Box>

          <Skeleton>
            <Box p={3} pb={0}>
              <H3>My History</H3>
            </Box>
          </Skeleton>

          {Array.from(new Array(6)).map((_, idx) => (
            <Card key={idx} style={{ marginBottom: 24, marginTop: 24 }}>
              <CardContent>
                <Skeleton width={'50%'}>
                  <H3>Hi</H3>
                </Skeleton>
                <Skeleton width={'100%'}>
                  <H5>Hi</H5>
                </Skeleton>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
    </Page>
  )
})

export default Me
