import { EnvConfig } from './config-interfaces'

const devConfig: EnvConfig = {
  baseApiPath: 'http://localhost:5000',
  // Starting up a local APM server requires a bit of setup. If it is needed,
  // work through the README in modular-house/docker and talk to Doug and Jonathan
  // to get it set up.
  // By default, it is disabled in development so you shouldn't need this.
  apmUrl: 'http://localhost:8200',
  oauthConfig: {
    popup: {
      clientId: '8ef8d1e0-b91e-4706-8828-7a2176abc4c9',
      redirectUrl: 'http://localhost:3000/oauthPopupCallback',
      windowOptions:
        'location=no,toolbar=no,width=500,height=800,left=100,top=100',
    },
    redirect: {
      clientId: 'f82badf9-97ce-4d66-a1a9-50df348ce538',
      redirectUrl: 'http://localhost:3000/oauthRedirectCallback',
    },
    ios: {
      clientId: '7bac19d3-935d-4dab-be68-eb7490655af4',
      redirectUrl: 'com.perkmotivation.ChronicPain://oauthCallback',
      callbackScheme: 'com.perkmotivation.ChronicPain://',
      callbackPath: 'oauthCallback',
    },
    android: {
      clientId: 'd6a33b79-c4ae-4d7a-9a7e-bf4504bca9f8',
      redirectUrl: 'com.perkmotivation.ChronicPain://oauthCallback',
      callbackScheme: 'com.perkmotivation.chronicpain://', // android callback must be lowercase
      callbackPath: 'oauthCallback',
    },
    shared: {
      authorizationBaseUrl:
        'https://authorization.cerner.com/tenants/ec2458f2-1e24-41c8-b71b-0e701af7583d/protocols/oauth2/profiles/smart-v1/personas/patient/authorize',
      accessTokenEndpoint:
        'https://authorization.cerner.com/tenants/ec2458f2-1e24-41c8-b71b-0e701af7583d/protocols/oauth2/profiles/smart-v1/token',
      scope:
        'patient/Patient.read patient/Condition.read patient/MedicationOrder.read patient/MedicationStatement.read patient/Observation.read openid fhirUser profile online_access launch/patient',
      aud:
        'https://fhir-myrecord.cerner.com/dstu2/ec2458f2-1e24-41c8-b71b-0e701af7583d',
      iss:
        'https://authorization.cerner.com/tenants/ec2458f2-1e24-41c8-b71b-0e701af7583d/oidc/idsps/ec2458f2-1e24-41c8-b71b-0e701af7583d-ch/',
      authority:
        'https://authorization.cerner.com/tenants/ec2458f2-1e24-41c8-b71b-0e701af7583d/oidc/idsps/ec2458f2-1e24-41c8-b71b-0e701af7583d-ch/.well-known/openid-configuration',
      jwksUri: 'https://authorization.cerner.com/jwk',
    },
  },
}

export default devConfig
