import { ICarePlan } from '@ahryman40k/ts-fhir-types/lib/R4'
import { useMutation, useQueryClient } from 'react-query'

import request from '../../../config/axios'
import {
  OptimisticUpdateContext,
  simpleOptimisticUpdater,
} from '../../../utils/simpleOptimisticUpdater'

const useCarePlanUpdate = (id?: string) => {
  const url = `/fhir/CarePlan/${id}`
  const queryClient = useQueryClient()
  const targetQueryKey = ['fhir/CarePlan', 'primaryCarePlan']
  const optimisticUpdateOptions = simpleOptimisticUpdater<ICarePlan>(
    queryClient,
    targetQueryKey,
  )

  return useMutation<
    ICarePlan,
    unknown,
    ICarePlan,
    OptimisticUpdateContext<ICarePlan>
  >(
    (vars) => request.put(url, vars).then((r) => r.data),
    optimisticUpdateOptions,
  )
}

export default useCarePlanUpdate
