import React, { useEffect, useState } from 'react'
import {
  Body1,
  Box,
  Button,
  Dialog,
  Divider,
  H3,
  H5,
  Link,
  List,
  ListItem,
  makeStyles,
} from '@perk-ui/core'
import { useHistory } from 'react-router'

import { ReactComponent as WelcomeWave } from '../../assets/scenes/WelcomeWave.svg'
import { Footer, Header, Page } from '../../components/Layout'
import MedStarLogo from './components/MedStarLogo'
import Section from './components/Section'

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: 'disc inside none',
    '& .MuiListItem-root': {
      minHeight: 'initial',
      display: 'list-item',
    },
  },
  hero: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(16),

    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingTop: `calc(env(safe-area-inset-top) + ${theme.spacing(2)}px)`,

    '& > h2': {
      marginBottom: theme.spacing(8),
      paddingRight: theme.spacing(4),
    },

    '& > svg': {
      height: 100,
      minWidth: 100,
      alignSelf: 'flex-end',
    },
  },
}))

export interface StudySummaryProps {}

const StudySummary: React.FC<StudySummaryProps> = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const [hasRead, setHasRead] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    const detectPageBottom = () => {
      const currentPageBottom = window.innerHeight + window.pageYOffset
      const totalPageHeight = document.body.offsetHeight - 2

      if (currentPageBottom >= totalPageHeight) {
        setHasRead(true)
      }
    }

    document.addEventListener('scroll', detectPageBottom, { passive: true })
    return () => document.removeEventListener('scroll', detectPageBottom)
  }, [])

  const handleContinue = () => {
    if (!hasRead) {
      return setShowAlert(true)
    }

    push('/consent/signature')
  }

  return (
    <Page
      title="Taper App - Study Summary"
      header={<Header withNav={false}></Header>}
      footer={
        <Footer withNav={false} floating>
          <Box p={3}>
            <Button
              onClick={handleContinue}
              fullWidth
              style={{
                transition: 'filter',
                transitionDuration: '0.33s',
                filter: hasRead ? 'grayscale(0)' : 'grayscale(0.8)',
              }}
            >
              Continue to signature
            </Button>
          </Box>
        </Footer>
      }
    >
      <Box p={3} pb={2} className={classes.hero}>
        <H3>Research Consent</H3>
        <WelcomeWave />
      </Box>
      <Box p={3} pb={0}>
        <MedStarLogo />
        <Body1>
          Permission to Take Part in a Human Research Study And Authorization To
          Use And Disclose Protected Health Information
        </Body1>
        <p>
          <Link
            href={`${process.env.PUBLIC_URL}/assets/Opioid_CDS_Consent.pdf`}
            target="blank"
            style={{ textDecoration: 'underline' }}
          >
            Download this document
          </Link>
        </p>
        <Section title="Title of research study:">
          Clinical Decision Support for Chronic Pain Management – Part 2
        </Section>
        <Section title="Sponsor:">
          Agency for Healthcare Research and Quality (AHRQ)
        </Section>
        <Section title="Protocol Number:">Pro00049922</Section>

        <Section title="Principal Investigator (Study Doctor):">
          Aaron Hettinger, MD, MS
        </Section>
        <Section title="Telephone:">(202) 244-9803 (24 Hours)</Section>
        <Section title="Address:">
          <div>6525 Belcrest Rd. #700</div>
          <div>Hyattsville, MD 20782</div>
        </Section>

        <Divider style={{ marginBottom: 12, height: 16 }} />
        <Section title="Key Information:">
          The following is a short summary of this study to help you decide
          whether or not to be a part of this study. More detailed information
          is listed later on in this form.
        </Section>
        <Section title="Why am I being invited to take part in a research study?">
          We invite you to take part in a research study because you are a
          person with chronic pain using opioid medications in your pain
          management regimen. In addition, you are a potential candidate for
          opioid tapering as discussed with your regular doctor on your recent
          visit.
        </Section>
        <Section title="What should I know about a research study?">
          <List className={classes.list} dense>
            <ListItem>
              Someone will explain this research study to you.
            </ListItem>
            <ListItem>Whether or not you take part is up to you.</ListItem>
            <ListItem>You can choose not to take part.</ListItem>
            <ListItem>
              You can agree to take part and later change your mind.
            </ListItem>
            <ListItem>Your decision will not be held against you.</ListItem>
            <ListItem>
              You can ask all the questions you want before you decide.
            </ListItem>
          </List>
        </Section>
        <Section title="Why is this research being done?">
          The purpose of this research to understand the needs, preferences, and
          experiences of people with chronic pain, with the intention of
          ultimately designing an electronic tool to assist with opioid tapering
          and management. In addition, this research aims to collect feedback on
          the implementation toolkit used to support the use of the electronic
          tool.
        </Section>
        <Section title="How long will the research last and what will I need to do?">
          We expect that you will be in this research study for up to 24 weeks.
          <br />
          <br />
          You will be asked to use a study app to enter patient reported
          outcomes (PRO) data related to your physical functioning and pain. In
          addition, you will be asked to use the study app to monitor your pain
          treatment plan as discussed with your regular doctor. Within the study
          app, you will answer questions about your pain and physical function
          at least once per week before your regularly scheduled appointments
          with your regular doctor. It is anticipated that interacting with the
          study app will take approximately <b>ten minutes per week</b>. Any
          data used for research purposes will be de-identified and aggregated,
          such that your information will not be linked to your participation or
          answers.
          <br />
          <br />
          Following use of the study app (after 24 weeks), you will be asked to
          complete an online survey requiring no more than <b>15 minutes</b> of
          your time to provide feedback, preferences, and recommendations for
          the study app. You may be asked to complete a post-use phone interview
          that will require no more than <b>30 minutes</b> of your time to
          provide a more detailed account of your experiences and perceptions of
          the study app. Your interview may be audio recorded. The information
          you provide will be used to inform subsequent interface design as well
          as revision to any instructional materials. All study data will be
          de-identified and aggregated, so that your information will not be
          linked to your participation or feedback.
          <br />
          <br />
          More detailed information about the study procedures can be found
          under{' '}
          <b>
            <i>
              “What happens if I say yes, I want to be part of this research?”
            </i>
          </b>
        </Section>
        <Section title="Is there any way being in this study could be bad for me?">
          All research studies involve some risks. The main risk of this study
          is the loss of confidentiality, but we will take every step to keep
          your information safe and secure, and any potentially identifying
          information will be kept separate to the study data and will only be
          accessible to the study team. There are no physical risks to being in
          this study. Your physician will use accepted national guidelines to
          help you reduce your use of opioids while treating your chronic pain
          and withdrawal symptoms. If you have a medical emergency, you must
          call your study doctor or call 911. The information collected in this
          study app will not be immediately communicated to your study doctor.
          <br />
          <br />
          More detailed information about the risks of this study can be found
          under{' '}
          <b>
            <i>
              “Is there any way being in this study could be bad for me?
              (Detailed Risks)”
            </i>
          </b>
        </Section>
        <Section title="Will being in this study help me in any way?">
          There are no benefits to you from your taking part in this research.
          We cannot promise any benefits to others from your taking part in this
          research. However, possible benefits include that through
          participating you may help the field to better engage patients and
          doctors in productive conversations about pain management.
        </Section>
        <Section title="What happens if I do not want to be part of this research?">
          Participation in research is completely voluntary. You can decide to
          participate or not to participate. Your alternative to participating
          in this research study is to not participate.
        </Section>
        <Divider style={{ marginBottom: 12, height: 16 }} />
        <Section title="Detailed Information:">
          The following is more detailed information about this study in
          addition to the information listed above.
        </Section>
        <Section title="Whom to Contact About this Study">
          An institutional review board (IRB) is an independent committee
          established to help protect the rights of research participants. If
          you have any questions about your rights as a research participant,
          and/or concerns or complaints regarding this research study, contact:
          <List className={classes.list} dense>
            <ListItem>
              By mail:
              <br />
              Study Subject Adviser
              <br />
              Advarra IRB
              <br />
              6100 Merriweather Dr., Suite 600
              <br />
              Columbia, MD 21044
            </ListItem>
            <ListItem>
              or call{' '}
              <b>
                <u>toll free</u>
              </b>
              : 877-992-4724
            </ListItem>
            <ListItem>
              or by{' '}
              <b>
                <u>email</u>
              </b>
              : <a href="mailto:adviser@advarra.com">adviser@advarra.com</a>
            </ListItem>
          </List>
          Please reference the following number when contacting the Study
          Subject Adviser: <u>Pro00049922</u>.
        </Section>
        <Section title="How many people will be studied?">
          We expect up to 10 participants from this study site to be in this
          research study out of 150 people in the entire study nationally.
        </Section>
        <Section title="What happens if I say yes, I want to be in this research?">
          Prescription opioid pain medication overuse, misuse, and abuse have
          been a significant contributing factor in the opioid epidemic.
          Increased concern for the safety of opioid prescribing for patients
          with chronic pain has led healthcare systems to work on optimizing
          pain therapy and consider opioid-dose reductions. The proposed
          research involves working with patients and their regular doctors to
          understand how they would utilize and interact with a study app
          designed for patient reported outcomes (PROs) and its outputs
          regarding the treatment of patients with chronic pain through
          prescribed opioids.
          <br />
          <br />
          If you agree to join the study, here is what will happen:
          <List className={classes.list} dense>
            <ListItem>
              You must read and complete this electronic consent and HIPAA
              authorization by providing your electronic signature and date. An
              electronic copy of the consent document will be provided after
              your signature and date.
            </ListItem>
            <ListItem>
              After completing the online informed consent, you may be provided
              electronic training documents.
            </ListItem>
            <ListItem>
              As a part of the standard care you receive from your regular
              doctor, you will be asked to utilize the study app to manage your
              pain treatment plan and share information about your pain and
              physical function with your regular doctor. Note that your regular
              doctor will continue to use standard care practices when treating
              you while using the study app. In addition, the study app does not
              replace standard communication with your regular doctor or medical
              treatment instructions from your regular doctor.
            </ListItem>
            <ListItem>
              For appointments, please feel free to use the data you entered
              into the study app to promote open communication with your regular
              doctor.
            </ListItem>
            <ListItem>
              You will be asked to utilize the study app for a period of at
              least 24 weeks.
            </ListItem>
            <ListItem>
              At the conclusion of the data collection period, you will be asked
              to complete a survey (requiring approximately 15 minutes) to
              provide feedback, preferences, and recommendations for the study
              app.
            </ListItem>
            <ListItem>
              If selected, you will be asked to participate in a follow-up phone
              interview (lasting no longer than 30 minutes) to provide a more
              detailed account of your experiences and perceptions of the study
              app.
            </ListItem>
          </List>
        </Section>
        <Section title="What happens if I say yes, but I change my mind later?">
          You can leave the research at any time it will not be held against
          you. You can tell the study team that you want to take away your
          permission to use and share your demographic information (for example,
          age, gender, ethnicity, race, years in practice), electronic health
          record information (for example, prescriptions, diagnosis, medication
          orders, referrals), PRO data (in-study app survey responses), and
          study appl use statistics (for example, how many times you accessed
          the study app) at any time. You can research the study team at the
          number listed on page one of this form.
          <br />
          <br />
          If you decide to leave the study, we will stop collecting any more
          information about you, but any information we have already collected
          will still be used for the purposes of the research study. By agreeing
          to be a part of this study, you give us permission to use your
          demographic information (for example, age, gender, ethnicity, race,
          years in practice), electronic health record information (for example,
          prescriptions, diagnosis, medication orders, referrals), PRO data
          (in-study app survey responses), and study appl use statistics (for
          example, how many times you accessed the study app).
        </Section>
        <Section title="Is there any way being in this study could be bad for me? (Detailed Risks)">
          There are no physical risks to you from participating in the study.
          Participation in the study involves the potential risk of a breach of
          confidentiality of your information that is collected. Taking part in
          this research study may involve providing information that you
          consider confidential or private. Efforts, such as coding research
          records, keeping research records secure, and allowing only authorized
          people to have access to research records, will be made to keep your
          information safe. There are no costs for participating in the study.
          <br />
          <br />
          By agreeing to this you do not give up your rights to seek
          compensation in the courts.
        </Section>
        <Section title="What happens to the information collected for the research?">
          Efforts will be made to limit the use and disclosure of your personal
          and protected health information, including research study and medical
          records, to people who have a need to review this information. We
          cannot promise complete secrecy. Organizations that may inspect and
          copy your information include the Institutional Review Board for
          medical research and other representatives of this organization.
          <br />
          <br />
          The aggregated results of this research study may be presented at
          scientific or medical meetings or published in scientific journals.
          Your identity and/or your personal information will not be disclosed
          unless it is authorized by you, required by law, or necessary to
          protect the safety of yourself or others. There is always some risk
          that even de-identified information might be re-identified.
          <br />
          <br />
          If identifiers are removed from your identifiable private information
          that are collected during this research, that information could be
          used for future research studies or distributed to another
          investigator for future research studies without your additional
          informed consent.
          <br />
          <br />
          Federal law provides additional protections of your medical records
          and related health information. These are described below.
        </Section>
        <Section title="Can I be removed from the research without my consent?">
          The person in charge of the research study or the sponsor can remove
          you from the research study without your approval. Possible reasons
          for removal include because new information becomes available, or
          because the entire study has been stopped.
          <br />
          <br />
          Any new important information that is discovered during the study and
          which may influence your willingness to continue participation in the
          study will be provided to you.
        </Section>
        <Section title="What else do I need to know?">
          This research is being funded by the Agency for Healthcare Research
          and Quality. The sponsor is providing money or other support to
          MedStar Health to help conduct this study. The researchers do not,
          however, hold a direct financial interest in the sponsor or the
          product being studied.
          <br />
          <br />
          You will receive no payment or other compensation for taking part in
          this study.
          <br />
          <br />
          Your information (both identifiable and de-identified) may be used to
          create products or to deliver services, including some that may be
          sold and/or make money for others The findings from this research may
          result in the future development of products that are of commercial
          value. If this happens, there are no plans to tell you, or to pay you,
          or to give any compensation to you or your family.
        </Section>
        <Section title="HIPAA Authorization">
          We are committed to respecting your privacy and to keeping your
          personal information confidential. When choosing to take part in this
          study, you are giving us the permission to use your personal health
          information including the health information in your medical records
          and information that can identify you. For example, personal health
          information may include your name, address, phone number or social
          security number. The health information we may collect from you and
          use for this research includes:
          <List className={classes.list} dense>
            <ListItem>
              All information in a medical record regarding the treatment of
              your pain
            </ListItem>
            <ListItem>Medical history</ListItem>
            <ListItem>Lab tests</ListItem>
            <ListItem>Records about prescriptions</ListItem>
            <ListItem>
              Substance abuse information specific to opioid medications
            </ListItem>
          </List>
          <br />
          Once we have the health information listed above, we may share some of
          this information with the following offices or entities outside of
          MedStar Health and its clinical partners (or affiliates): the MedStar
          Health Research Institute Office for Research Integrity; the US Office
          of Research Integrity; the US Office for Human Research Protections;
          the US Food and Drug Administration.
          <br />
          <br />
          Any research information shared with outside entities will not contain
          your name, address, telephone or social security number or any other
          personal identifier unless disclosure of the identifier is necessary
          for review by such parties or is required by law or MedStar Health
          policy [except that such information may be viewed by the Study
          sponsor and its partners or contractors at the Principal
          Investigator’s office].
          <br />
          <br />
          The following entities may receive your health information:
          <List className={classes.list} dense>
            <ListItem>
              Authorized members of the MedStar Health workforce, who may need
              to see your information, such as administrative staff members from
              the MedStar Health Research Institute, Office for Research
              Integrity and members of the Institutional Review Board.
            </ListItem>
            <ListItem>
              Laboratories and other individuals and organizations that may need
              to see your health information in connection with this study.
            </ListItem>
            <ListItem>
              Other MedStar Health research centers and MedStar Health
              contractors who are also working on the study.
            </ListItem>
            <ListItem>Records about prescriptions</ListItem>
            <ListItem>
              Study monitors and auditors who make sure that the study is being
              done properly.
            </ListItem>
          </List>
          <br />
          Those persons who get your health information may not be required by
          Federal privacy laws (such as the Privacy Rule) to protect it. Some of
          those people may be able to share your information with others without
          your separate permission.
          <br />
          <br />
          The results of this study may also be used for teaching, publications,
          or for presentation at scientific meetings.
          <br />
          <br />
          Your consent will not expire unless you revoke it.
          <br />
          <br />
          After the expiration date, MedStar Health may not gather new
          information about you, or use or disclose your personal health
          information collected in this study for any purpose other than the
          research study described in this consent unless MedStar Health obtains
          permission to do so from you.
          <br />
          <br />
          Although you may revoke consent to participation in this research at
          any time and in any format, you must revoke authorization for use or
          disclosure of your health information in writing. To revoke your
          authorization, write to:
          <br />
          <br />
          <b>PI’s Name</b>: Kristen Miller, DrPH, CPPS and Aaron Zachary
          Hettinger, MD, MS
          <br />
          <b>Institution</b>: MedStar Health Research Institute
          <br />
          <b>Department</b>: MedStar Health National Center for Human Factors in
          Healthcare
          <br />
          <b>Address</b>: 3007 Tilden St. NW Suite 6N Washington, DC 20008
          <br />
          <br />
          You do not have to authorize the use or disclosure of your health
          information; however, you will not be allowed to take part in this
          research study if you do not allow this. If you do not authorize the
          use or disclosure of your health information, it will not affect your
          treatment by health care providers, or the payment or enrollment in
          any health plans, or affect your eligibility for benefits.
          <br />
          <br />A copy of this signed consent document, information about this
          study, and the results of any test or procedure done may be included
          in your medical records and may be seen by your insurance company.
        </Section>
        <Dialog open={showAlert} onClose={() => setShowAlert(false)} fullWidth>
          <Box p={5}>
            <H5 style={{ textAlign: 'center' }}>
              Please read the study details before continuuing
            </H5>
          </Box>
        </Dialog>
      </Box>
    </Page>
  )
}

export default StudySummary
