import React, { useEffect } from 'react'
import { AnimateSharedLayout } from 'framer-motion'
import { Route, Switch, useLocation } from 'react-router'

import PrivateRoute from '../components/PrivateRoute'
import config from '../config/app-config'
import AnimatedRedirect from '../features/animation/AnimatedRedirect'
import AnimatedSwitch from '../features/animation/AnimatedSwitch'
import { useAuth } from '../features/auth/AuthContext'
import { useHistory } from '../utils/useHistory'
import StudySignature from './Consent/StudySignature'
import StudySummary from './Consent/StudySummary'
import DailyJournal from './DailyJournal/DailyJournal'
import FAQ from './FAQ/FAQ'
import Home from './Home/Home'
import Login from './Login/Login'
import ManageActivities from './ManageActivities/ManageActivities'
import ActivityHistory from './Me/ActivityHistory/ActivityHistory'
import Me from './Me/Me'
import DailyJournalDetail from './Me/PainHistory/DailyJournalDetail'
import PainHistory from './Me/PainHistory/PainHistory'
import TaperHistory from './Me/TaperHistory/TaperHistory'
import OAuthPopupCallback from './OAuth/OAuthPopupCallback'
import OAuthRedirectCallback from './OAuth/OAuthRedirectCallback'
import PatientResource from './PatientResource/PatientResource'
import Settings from './Settings/Settings'
import ChangeAlert from './WeeklyAssessment/ChangeAlert/ChangeAlert'
import WeeklyAssessmentIntro from './WeeklyAssessment/Intro/WeeklyAssessmentIntro'
import WeeklyAssessmentResults from './WeeklyAssessment/Results/WeeklyAssessmentResults'
import ThankYou from './WeeklyAssessment/ThankYou/ThankYou'
import WeeklyAssessment from './WeeklyAssessment/WeeklyAssessment'

const callbackRoutes = ['/oauthRedirectCallback', '/oauthPopupCallback']

const Routes: React.FC = React.memo(() => {
  const location = useLocation()
  const { push, replace } = useHistory()
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      if (callbackRoutes.includes(location.pathname)) {
        // If we're on the callback routes, replace the location and go to home
        replace(config.authenticatedRootUrl)
      }
      if (config.unAuthenticatedRootUrl === location.pathname) {
        push(config.authenticatedRootUrl)
      }
    } else {
      if (
        !callbackRoutes.includes(location.pathname) &&
        config.unAuthenticatedRootUrl === location.pathname
      ) {
        // If we are NOT authenticated && NOT on the auth routes, go to auth
        push(config.unAuthenticatedRootUrl)
      }
    }
    // TODO add correct dependencies
    // eslint-disable-next-line
  }, [isAuthenticated])

  return (
    <AnimateSharedLayout type="crossfade">
      <AnimatedSwitch exitBeforeEnter={false} initial={false}>
        <Switch location={location} key={location.pathname}>
          {/**
           * Authentication
           */}
          <Route path={config.unAuthenticatedRootUrl} component={Login} />
          <Route path="/oauthPopupCallback" component={OAuthPopupCallback} />
          <Route
            path="/oauthRedirectCallback"
            component={OAuthRedirectCallback}
          />

          {/**
           * Study Consent
           */}
          <PrivateRoute path="/consent/summary" component={StudySummary} />
          <PrivateRoute path="/consent/signature" component={StudySignature} />

          {/**
           * Home
           */}
          <PrivateRoute path="/home" component={Home} />

          {/**
           * Me Page
           */}
          <PrivateRoute
            path="/me/pain/journal/:id"
            component={DailyJournalDetail}
          />
          <PrivateRoute path="/me/pain" component={PainHistory} />
          <PrivateRoute path="/me/activity" component={ActivityHistory} />
          <PrivateRoute
            path="/me/manage-activities"
            component={ManageActivities}
          />
          <PrivateRoute path="/me/taper" component={TaperHistory} />
          <PrivateRoute path="/me" component={Me} />

          {/**
           * Settings and FAQ
           *
           * Note: FAQ is a public page
           */}
          <PrivateRoute path="/settings" component={Settings} />
          <Route path="/faq" component={FAQ} />

          {/**
           * Patient Resources cards
           */}
          <PrivateRoute path="/resource/:id" component={PatientResource} />

          {/**
           * Weekly Assessments
           */}
          <PrivateRoute
            path="/weekly-assessment/intro"
            component={WeeklyAssessmentIntro}
          />
          <PrivateRoute
            path="/weekly-assessment/results"
            component={WeeklyAssessmentResults}
          />
          <PrivateRoute
            path="/weekly-assessment/thank-you"
            component={ThankYou}
          />
          <PrivateRoute
            path="/weekly-assessment/change-alert"
            component={ChangeAlert}
          />
          <PrivateRoute
            path="/weekly-assessment/assessment"
            component={WeeklyAssessment}
          />

          {/**
           * DailyJournal assessment
           */}
          <PrivateRoute path="/daily-journal" component={DailyJournal} />

          {/**
           * Catch-all
           */}
          <Route
            path="*"
            render={() => (
              <AnimatedRedirect
                to={
                  isAuthenticated
                    ? config.authenticatedRootUrl
                    : config.unAuthenticatedRootUrl
                }
              />
            )}
          />
        </Switch>
      </AnimatedSwitch>
    </AnimateSharedLayout>
  )
})

export default Routes
