import React, { useState } from 'react'
import {
  ICoding,
  IQuestionnaireResponse,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { Box, H3, H5, HeaderBar, HeaderBarButton } from '@perk-ui/core'
import {
  addWeeks,
  endOfMonth,
  parseISO,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns'

import Emoji from '../../../components/Emoji'
import InteractiveBodyMap from '../../../components/InteractiveBodyMap'
import { Header, Page } from '../../../components/Layout'
import useTaperStepSearch from '../../../features/Fhir/CarePlan/useTaperStepSearch'
import {
  getDailyJournalBodyParts,
  getDailyJournalSentiments,
} from '../../../features/Fhir/dailyJournalUtils'
import { getQrScoreFromTheta } from '../../../features/Fhir/painAssessmentUtils'
import { questionnaireMap } from '../../../features/survey/questionnaire-data'
import useQRSearch from '../../../features/survey/useQRSearch'
import { groupBy } from '../../../utils/arrays'
import { noop } from '../../../utils/noop'
import { capitalize } from '../../../utils/strings'
import { useHistory } from '../../../utils/useHistory'
import DailyJournalCalendar from './DailyJournalCalendar'
import PainHistoryChart from './PainHistoryChart'
import ScoreHistoryList from './ScoreHistoryList'

export interface ScoreData {
  date: number
  authored: number
  score: number | null
  interferenceScore?: number | null
  intensityScore?: number | null
  questionnaireId: string
}

const toChartData = (qr: IQuestionnaireResponse) => {
  // As our Weekly Assessment is a weekly occurance, let's move everything to have a
  // conistent baseline (startOfWeek), then turn into a number which recharts can easily
  // interpret for x-axis placement
  const date = startOfWeek(parseISO(qr.authored as string)).getTime()
  const score = getQrScoreFromTheta(qr) || null
  return {
    questionnaireId: qr.id as string,
    date,
    authored: parseISO(qr.authored as string).getTime(),
    score,
  }
}

/**
 * TODO: Start calendar on Mondays
 */
const PainHistory: React.FC = () => {
  const { goBack, push } = useHistory()
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date()) // a Date within the month intended

  const { data: taperSteps = [] } = useTaperStepSearch()
  // const taperSteps = taperStepCarePlanData

  const { data: dailyJournalQrs = [] } = useQRSearch({
    from: subWeeks(startOfMonth(currentMonth), 1),
    to: addWeeks(endOfMonth(currentMonth), 1),
    questionnaire: questionnaireMap.DailyPainJournal,
  })
  const bodyParts = dailyJournalQrs
    .flatMap(getDailyJournalBodyParts)
    .filter(Boolean) as string[]
  const uniqueParts = Array.from(new Set(bodyParts)).map((part) => ({
    id: part,
  }))
  const djSentiments = dailyJournalQrs
    .flatMap(getDailyJournalSentiments)
    .filter(Boolean) as ICoding[]
  const sentimentFrequency = groupBy(
    (sentiment) => sentiment.code || null,
    djSentiments,
  )

  const { data: intensityQrs = [] } = useQRSearch({
    questionnaire: questionnaireMap.intensity,
    from: startOfMonth(subMonths(currentMonth, 2)),
    to: endOfMonth(currentMonth),
  })
  const intensityData: ScoreData[] = intensityQrs.map(toChartData).map((d) => ({
    ...d,
    // give the Intensity scores a unique name so that the PainHistoryChart
    // can assign this data to its own Line
    intensityScore: d.score,
  }))

  // Get the InterferenceQR Data and convert to Graph Data
  const { data: interferenceQrs = [] } = useQRSearch({
    questionnaire: questionnaireMap.interference,
    from: startOfMonth(subMonths(currentMonth, 2)),
    to: endOfMonth(currentMonth),
  })
  const interferenceData: ScoreData[] = interferenceQrs
    .map(toChartData)
    .map((d) => ({
      ...d,
      // give the Interference scores a unique name so that the PainHistoryChart
      // can assign this data to its own Line
      interferenceScore: d.score,
    }))

  // We only show the graph if we have either >= 3 weeks of assessments or >= 2 taperSteps
  const assessmentsByWeek = groupBy('authored', [
    ...intensityData,
    ...interferenceData,
  ])
  const showPainChart =
    taperSteps.length >= 2 || Object.keys(assessmentsByWeek).length >= 3

  const handleCalendarDateChange = (date: Date | null, qrId: string | null) => {
    if (date) {
      setSelectedDate(date)
      push(`/me/pain/journal/${qrId}`)
    }
  }

  return (
    <Page
      title="Taper App - Pain History"
      header={
        <Header withNav={false}>
          <HeaderBar
            titleContent="Pain History"
            left={<HeaderBarButton variant="back" onClick={goBack} />}
          />
        </Header>
      }
    >
      <Box p={3} pt={0}>
        <DailyJournalCalendar
          value={selectedDate}
          month={currentMonth}
          onChange={handleCalendarDateChange}
          onMonthChange={(date) => {
            date && setCurrentMonth(date)
          }}
        />

        <Box
          my={3}
          mt={2.5}
          display="flex"
          justifyContent="space-around"
          flexWrap="wrap"
        >
          {Object.values(sentimentFrequency)
            .sort((a, b) => b.length - a.length)
            .map((sentiments) => {
              const { code = '', display = '🙂' } = sentiments[0]
              const emoji = display
              const label = capitalize(code)
              return (
                <Box
                  key={code}
                  display="flex"
                  alignItems="center"
                  width="50%"
                  justifyContent="center"
                >
                  <Emoji
                    symbol={emoji}
                    label={label}
                    style={{ fontSize: '1.5rem' }}
                  />
                  <H5 style={{ paddingLeft: 8 }}>
                    {label} x{sentiments.length}
                  </H5>
                </Box>
              )
            })}
        </Box>
        <Box my={3} display="flex">
          <InteractiveBodyMap
            disabled
            perspective="front"
            selectedParts={uniqueParts}
            onChange={noop}
          />
          <InteractiveBodyMap
            disabled
            style={{ marginLeft: -42 }}
            perspective="back"
            selectedParts={uniqueParts}
            onChange={noop}
          />
        </Box>

        <Box my={3}>
          <H3 paragraph>Pain History</H3>
          <Box
            mx={-2} // inset the Graph, allowing the Chart to apply inset margin
          >
            {showPainChart && (
              <PainHistoryChart
                taperSteps={taperSteps}
                intensityData={intensityData}
                interferenceData={interferenceData}
                margin={{ left: 24, right: 24 }}
              />
            )}
          </Box>
        </Box>

        <ScoreHistoryList
          interferenceData={interferenceData}
          intensityData={intensityData}
        />
      </Box>
    </Page>
  )
}

export default PainHistory
