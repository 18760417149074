import { useQuery } from 'react-query'

import request from '../../config/axios'
import { FeedResponse } from './FeedResponse'

export interface UseFeedSearchParams {
  feedName: string
}

const useFeedSearch = ({ feedName }: UseFeedSearchParams) => {
  const cacheKey = ['feed_system/feeds', feedName]
  const url = `feed_system/feeds/${feedName}`

  return useQuery<FeedResponse>(cacheKey, () =>
    request.get(url).then((r) => r.data),
  )
}

export default useFeedSearch
