import React from 'react'

import { resourcesById } from '../../pages/PatientResource/patient-resource-data'
import CallToActionCard from './CallToActionCard'
import { CallToActionItem,PatientResourceItem } from './FeedResponse'

export interface PatientResourceFeedItemProps {
  item: PatientResourceItem
}

const PatientResourceFeedItem: React.FC<PatientResourceFeedItemProps> = ({
  item,
}) => {
  const { resource_id } = item
  const patientResource = resourcesById[resource_id]

  const ctaItem: CallToActionItem = {
    type: 'FeedSystem::CallToActionFeedItem',
    created_at: String(new Date().getTime()),
    is_read: false,
    id: resource_id,
    title: patientResource.title,
    description: patientResource.description,
    action: {
      type: 'read',
      title: 'Read',
      path: `/resource/${resource_id}`,
    },
    time_helper: item.time_helper,
    media: {
      image: {
        src: `${process.env.PUBLIC_URL}/images/${patientResource.id}.jpg`,
        title: 'foo',
        alt: patientResource.title,
      },
    },
  }

  return <CallToActionCard item={ctaItem} />
}

export default PatientResourceFeedItem
