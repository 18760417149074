import { AppUrlOpen, Plugins } from '@capacitor/core'
import { Log } from 'oidc-client'

const { Browser, App } = Plugins
interface LoginResult {
  url: string
}

/** This interface is unknown */
interface NavigateParams {
  url?: string
  [k: string]: unknown
}

// Note:
// If we want to use the regular SafariViewController for auth,
// this class would work well for iOS. You would just have to close
// the browser on iOS
export class AndroidNavigator {
  prepare() {
    return Promise.resolve(this)
  }

  navigate(params?: NavigateParams): Promise<LoginResult> {
    if (!params || !params.url) {
      Log.error('AndroidNavigator.navigate: No url provided')
      return Promise.reject(new Error('No url provided'))
    }

    return new Promise((resolve) => {
      Browser.open({ url: params.url as string }).then(() => {
        App.addListener('appUrlOpen', (data: AppUrlOpen) => {
          // TODO On android, you are unable to call Browser.close()
          // Therefore we just continue on which seems to close the
          // browser well enough. This is probably bad but will
          // work for now.
          //
          // The fix for this likely involves implementing custom code that
          // is very similar to the capacitor-oauth java code. We could then
          // merge this and the IosNavigator back into the NativeNavigator
          resolve({ url: data.url })
        })
      })
    })
  }
}
