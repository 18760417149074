import React from 'react'
import { Box, H5, HeaderBar } from '@perk-ui/core'
import { motion } from 'framer-motion'

import { Footer,Header, Page } from '../../components/Layout'
import useRedirectCleanup from '../../features/animation/useRedirectCleanup'
import { INITIAL_JOURNAL_PROMPT } from '../../features/interrupts/interrupts'
import { useQueryParam } from '../../utils/useQueryParam'
import DailyJournalPrompt from './components/DailyJournalPrompt'
import HomePageHero from './components/HomePageHero'
import HomeFeed from './HomeFeed'

const Home: React.FC = React.memo(() => {
  useRedirectCleanup()
  const showDjPrompt = useQueryParam('prompt') === INITIAL_JOURNAL_PROMPT

  return (
    <Page
      title="Taper App - Home"
      header={
        <Header withNav>
          <HeaderBar
            titleContent={
              <motion.div
                transition={{ bounce: 0 }}
                initial={{
                  x: 16,
                  opacity: 0.6,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                }}
                exit={{
                  x: -40,
                  opacity: 0.4,
                }}
              >
                <H5 align="center">Home</H5>
              </motion.div>
            }
          />
        </Header>
      }
      footer={<Footer withNav withSafeInset={false} />}
    >
      <Box>
        <HomePageHero />
        <Box padding={3}>
          <HomeFeed />
        </Box>
        {showDjPrompt && <DailyJournalPrompt />}
      </Box>
    </Page>
  )
})

export default Home
