import { toRelativeDateString } from '@perk-ui/core'
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  isValid as isValidDate,
  parseISO,
  startOfWeek,
  subDays,
} from 'date-fns'

export type DayName =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday'

export type DayAbbreviation = 'Mo' | 'Tu' | 'We' | 'Th' | 'Fr' | 'Sa' | 'Su'

/**
 * Dates representing the days of this week, starting on Sunday.
 *
 * @note To change the start of the week from Sunday, use the `weekStartsOn`
 * param for `startOfWeek` and `endOfWeek`.
 */
export const DAYS_THIS_WEEK = eachDayOfInterval({
  start: startOfWeek(new Date(), { weekStartsOn: 1 }),
  end: endOfWeek(new Date(), { weekStartsOn: 1 }),
})

/**
 * Dates representing the 7 most recent days, including today.
 */
export const LAST_7_DAYS = eachDayOfInterval({
  start: subDays(new Date(), 6),
  end: new Date(),
})

/**
 * Determines whether @param str is a string that can be parsed and converted
 * into a Date object.
 */
export const isDateString = (str: string | undefined): str is string =>
  isValidDate(toDateFromString(str))

/**
 * The output of Ruby's `Time.zone.now.to_s` is not recognized by Safari or
 * Firefox as a valid datetime string (`new Date(<str>)` returns Invalid Date)
 *
 * A sample `Time.zone.now.to_s` output is: "2021-04-02 00:12:21 UTC"
 *
 * @param str A datetime formatted by Ruby's `Time.zone.now.to_s`
 * @returns Either an Invalid Date object, or a valid Date
 */
export const dateFromRubyTimeZoneNow = (str: string | undefined): Date => {
  if (!str || !str.endsWith('UTC')) return new Date('invalid')

  const [date, time, _utc] = str.split(' ')
  const newFormat = `${date}T${time}.000Z`

  return parseISO(newFormat)
}

/**
 * Could have been named simple toDate, but one of date-fns' core functions
 * is a called `toDate`, so didn't want to overload it.
 * @returns a Date! Could be invalid - be sure to check with date-fns' `isValid`!
 */
export const toDateFromString = (str: string | undefined): Date => {
  if (!str) return new Date('invalid')

  const newDate = new Date(str)
  const dateFromRb = dateFromRubyTimeZoneNow(str)

  return isValidDate(newDate)
    ? newDate
    : isValidDate(dateFromRb)
    ? dateFromRb
    : new Date('invalid')
}

/**
 * Attempts to convert @param str into a Date and then format it to a
 * relative time string. If @param str is not understood by `date-fns`,
 * it simply returns the string.
 *
 * @see https://date-fns.org/v2.16.1/docs/formatDistanceStrict
 */
export const toRelativeDateOrString = (str: string | undefined) => {
  return isDateString(str) ? toRelativeDateString(toDateFromString(str)) : str
}

/**
 * Format a Date into an ISO String with the current timezone. This should
 * be used anytime that we're storing a DateTime object in HAPI. The output
 * format is of the shape 2021-01-12T14:23:47-06:00
 *
 * @see https://stackoverflow.com/questions/17415579/how-to-iso-8601-format-a-date-with-timezone-offset-in-javascript
 */
export const toIsoStringWithTz = (date: Date): string => {
  const tzo = -date.getTimezoneOffset()
  const diff = tzo >= 0 ? '+' : '-'
  const pad = function (num: number) {
    const norm = Math.floor(Math.abs(num))
    return (norm < 10 ? '0' : '') + norm
  }

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    diff +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  )
}

/**
 * @returns the two-character formatted day of the week
 * @example
 * toDayAbbreviation(new Date('2020-1-1') // => 'We'
 */
export const toDayAbbreviation = (date: number | Date) =>
  format(date, 'iiiiii') as DayAbbreviation

/**
 * @returns the full name of the day of the week
 * @example
 * toDayName(new Date('2020-1-1') // => 'Wednesday'
 */
export const toDayName = (date: number | Date) =>
  format(date, 'iiii') as DayName
