"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var styles_1 = require("../styles");
/**
 * A convenient way to apply standard colors and sizes to an icon.
 * (This is not an extension of Material UI's Icon component.)
 */
var Icon = function (_a) {
    var children = _a.children, _b = _a.color, colorProp = _b === void 0 ? 'inherit' : _b, _c = _a.size, sizeProp = _c === void 0 ? 'large' : _c;
    var _d = styles_1.useTheme(), perk = _d.perk, palette = _d.palette;
    var iconSize = perk.icon.size;
    /* We only set the new props if they are present and have valid values. This
       prevents undefined or invalid props from overriding props that were set
       directly on the icon child (e.g., color="inherit"). */
    var newIconProps = {};
    var colorMap = {
        disabled: palette.action.disabled,
        error: palette.error.main,
        primary: palette.primary.main,
        primaryText: palette.text.primary,
        secondaryText: palette.text.secondary,
        success: palette.success.main,
    };
    if (Object.keys(colorMap).includes(colorProp)) {
        newIconProps.color = colorMap[colorProp];
    }
    // Verify that the specified iconSize property exists on the theme.
    if (iconSize != undefined && Object.keys(iconSize).includes(sizeProp)) {
        var size = iconSize[sizeProp];
        newIconProps.height = size;
        newIconProps.width = size;
    }
    if (React.isValidElement(children)) {
        return React.cloneElement(children, newIconProps);
    }
};
exports.default = Icon;
