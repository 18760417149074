import React from 'react'
import { Box } from '@perk-ui/core'
import { subDays } from 'date-fns'

import LabeledAvatar from '../../../../components/LabeledAvatar'
import { getDailyJournalActivities } from '../../../../features/Fhir/dailyJournalUtils'
import { latestQrOnDay } from '../../../../features/Fhir/utils'
import { questionnaireMap } from '../../../../features/survey/questionnaire-data'
import useQRSearch from '../../../../features/survey/useQRSearch'
import {
  DAYS_THIS_WEEK,
  toDayAbbreviation,
  toDayName,
} from '../../../../utils/dates'
import ActivityAvatar from './ActivityAvatar'

export interface DailyJournalActivitiesProps {}

const DailyJournalActivities: React.FC<DailyJournalActivitiesProps> = () => {
  const { data: djQrs = [] } = useQRSearch({
    questionnaire: questionnaireMap.DailyPainJournal,
    from: subDays(new Date(), 7),
    to: new Date(),
  })

  return (
    <Box display="flex" justifyContent="space-between" mt={1}>
      {DAYS_THIS_WEEK.map((day) => {
        const qr = latestQrOnDay(day, djQrs)
        const hadActivities = qr
          ? Boolean(getDailyJournalActivities(qr)?.length)
          : false

        return (
          <LabeledAvatar
            key={String(day)}
            label={toDayAbbreviation(day)}
            ariaLabel={toDayName(day)}
          >
            <ActivityAvatar hadActivities={hadActivities} />
          </LabeledAvatar>
        )
      })}
    </Box>
  )
}

export default DailyJournalActivities
