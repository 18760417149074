import {
  ICoding,
  IQuestionnaireResponse,
} from '@ahryman40k/ts-fhir-types/lib/R4'

import { findBy } from '../../utils/arrays'
import { DailyJournalPainScore } from '../../utils/painScores'

export const PAIN_SCORE_LINK = 'PAIN_SCORE_LINK'
export const ACTIVITIES_LINK = 'ACTIVITIES_LINK'
export const SENTIMENT_LINK = 'SENTIMENT_LINK'
export const BODY_MAP_LINK = 'BODY_MAP_LINK'
export const NOTES_LINK = 'NOTES_LINK'

export const getDailyJournalPainScore = (qr?: IQuestionnaireResponse) => {
  const item = findBy('linkId', PAIN_SCORE_LINK, qr?.item || [])
  if (!item) return null

  const score = item.answer?.[0].valueInteger as
    | DailyJournalPainScore
    | undefined

  return score ?? null
}

export const getDailyJournalActivities = (qr?: IQuestionnaireResponse) => {
  if (!qr) return null

  const activities = findBy('linkId', ACTIVITIES_LINK, qr?.item || [])
  if (!activities || !activities.answer) return null

  return activities.answer
}

export const getDailyJournalSentiments = (qr?: IQuestionnaireResponse) => {
  if (!qr) return null

  const item = findBy('linkId', SENTIMENT_LINK, qr.item || [])
  if (!item) return null

  return (item.answer || [])
    .map((answer) => answer?.valueCoding)
    .filter(Boolean) as ICoding[]
}

export const getDailyJournalNotes = (qr?: IQuestionnaireResponse) => {
  if (!qr) return null

  const item = findBy('linkId', NOTES_LINK, qr?.item || [])
  const notes = item?.answer?.[0].valueString
  if (!notes) return null

  return notes
}

export const getDailyJournalBodyParts = (qr?: IQuestionnaireResponse) => {
  if (!qr) return null

  const item = findBy('linkId', BODY_MAP_LINK, qr?.item || [])
  if (!item) return null

  return (item.answer || [])
    .map((answer) => answer.valueString)
    .filter(Boolean) as string[]
}
