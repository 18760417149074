import React, { useEffect, useState } from 'react'
import { IQuestionnaireResponse } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Body1, Box, H2, makeStyles } from '@perk-ui/core'
import { addHours } from 'date-fns'
import { useQueryClient } from 'react-query'

import { ReactComponent as DoctorSvg } from '../../../assets/scenes/Doctor.svg'
import AppLinkButton from '../../../components/AppLinkButton'
import { Footer, Header,Page } from '../../../components/Layout'
import { useHubId } from '../../../features/auth/AuthContext'
import buildQuestionnaire from '../../../features/survey/buildQuestionnaire'
import buildQuestionnaireResponse from '../../../features/survey/buildQuestionnaireResponse'
import { questionnaireMap } from '../../../features/survey/questionnaire-data'
import useNextQuestion from '../../../features/survey/useNextQuestion'
import useQRInit, { qrInitCacheKey } from '../../../features/survey/useQRInit'
import { assert } from '../../../utils/assert'

export interface WeeklyAssessmentLocationState {
  activeAssessment: 'intensity' | 'interference'
  staleAt: number
  intensity: {
    questionnaireId: string
    score: number | null
  }
  interference: {
    questionnaireId: string
    score: number | null
  }
}

export const createBasePainAssessmentQR = (
  patientId: string,
  questionnaireId: string,
) => {
  const baseQ = buildQuestionnaire(questionnaireId, {
    meta: {
      profile: [
        'http://hl7.org/fhir/uv/sdc/StructureDefinition/sdc-questionnaire-adapt',
      ],
    },
  })

  return buildQuestionnaireResponse(
    {
      patientId,
      containedQuestionnaire: baseQ,
    },
    {
      meta: {
        profile: [
          'http://hl7.org/fhir/uv/sdc/StructureDefinition/sdc-questionnaireresponse-adapt',
        ],
      },
    },
  )
}

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(30),
    paddingTop: 'calc(env(safe-area-inset-top) + 32px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heroSvg: {
    height: '120%',
    marginTop: theme.spacing(5),
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  startButton: {
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5,
    },
  },
}))

const WeeklyAssessment: React.FC = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const [intensityId, setIntensityId] = useState<string | null>(null)
  const [interferenceId, setInterferenceId] = useState<string | null>(null)

  //
  // Prefetch the first question for each of the Questionnaires
  //
  const patientId = useHubId()
  const initQr = useQRInit()
  const nextQuestion = useNextQuestion()
  const isPrefetching =
    initQr.isIdle ||
    initQr.isLoading ||
    nextQuestion.isLoading ||
    nextQuestion.isIdle
  useEffect(() => {
    // mutation is only idle before being called
    if (!initQr.isIdle) return

    const intensityQr = createBasePainAssessmentQR(
      patientId,
      questionnaireMap.intensity,
    )
    const interferenceQr = createBasePainAssessmentQR(
      patientId,
      questionnaireMap.interference,
    )

    const cacheForLater = (qId: string, data: IQuestionnaireResponse) => {
      const cacheKey = qrInitCacheKey(qId)
      queryClient.setQueryData(cacheKey, data)
    }

    initQr
      .mutateAsync(intensityQr)
      .then(nextQuestion.mutateAsync)
      .then((data) => {
        assert(!!data.id, 'Failed to create QuestionnaireResponse with id')
        setIntensityId(data.id)
        cacheForLater(data.id, data)
      })
    initQr
      .mutateAsync(interferenceQr)
      .then(nextQuestion.mutateAsync)
      .then((data) => {
        assert(!!data.id, 'Failed to create QuestionnaireResponse with id')
        setInterferenceId(data.id)
        cacheForLater(data.id, data)
      })
  }, [patientId, initQr, queryClient, nextQuestion.mutateAsync])

  return (
    <Page
      title="Taper App - Weekly Assessment"
      header={<Header withNav={false} withSafeInset={false} />}
      footer={
        <Footer withNav={false} withSafeInset={false} floating>
          <Box p={4}>
            <AppLinkButton
              to={{
                pathname: '/weekly-assessment/assessment',
                state: {
                  intensity: {
                    questionnaireId: intensityId,
                    score: null,
                  },
                  interference: {
                    questionnaireId: interferenceId,
                    score: null,
                  },
                  activeAssessment: 'intensity',
                  staleAt: addHours(new Date(), 2).getTime(),
                } as WeeklyAssessmentLocationState,
              }}
              fullWidth
              loading={isPrefetching}
              disabled={isPrefetching}
              className={classes.startButton}
            >
              Start
            </AppLinkButton>
          </Box>
        </Footer>
      }
    >
      <Box p={3} className={classes.hero}>
        <DoctorSvg className={classes.heroSvg} />
      </Box>
      <Box p={3} className={classes.textContent}>
        <H2 paragraph style={{ fontSize: '2.5rem' }}>
          Weekly Pain Assessment
        </H2>
        <Body1 paragraph style={{ fontSize: '1.25rem' }}>
          Your doctor would like you to answer some questions about your pain.
        </Body1>
        <Body1 style={{ fontWeight: 'bold' }}>
          It should take less than two minutes to complete.
        </Body1>
      </Box>
    </Page>
  )
}

export default WeeklyAssessment
