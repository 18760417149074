import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  H3,
  HeaderBar,
  HeaderBarButton,
  Link,
  makeStyles,
} from '@perk-ui/core'
import { ChevronDown } from 'react-feather'

import { Footer, Header, Page } from '../../components/Layout'
import { useIsAuthenticated } from '../../features/auth/AuthContext'
import { useHistory } from '../../utils/useHistory'
import { FAQs } from './faq-items'

export interface FAQProps {}

const titleToId = (title: string) => title.split(/\s/g).join('-')

const useStyles = makeStyles(() => ({
  contentStyles: {
    '& .MuiList-root': {
      listStyle: 'disc inside none',
      '& .MuiListItem-root': {
        minHeight: 'initial',
        display: 'list-item',
        wordBreak: 'break-word',
      },
    },
  },
}))

const FAQ: React.FC<FAQProps> = () => {
  const { goBack } = useHistory()
  const classes = useStyles()
  const isAuthenticated = useIsAuthenticated()

  return (
    <Page
      title="Taper App - FAQ & Help"
      header={
        <Header withNav={false}>
          <HeaderBar
            titleContent="FAQ & Help"
            left={
              isAuthenticated && (
                <HeaderBarButton variant="back" onClick={goBack} />
              )
            }
          />
        </Header>
      }
      footer={<Footer withNav={false}></Footer>}
    >
      <Box p={3}>
        <Box pb={2}>
          <H3 gutterBottom>Terms, Conditions, and Disclaimers.</H3>
          <Link
            href={`${process.env.PUBLIC_URL}/assets/Opioid_CDS_Terms_And_Conditions.pdf`}
            target="blank"
            style={{ textDecoration: 'underline' }}
          >
            Click here to view
          </Link>
        </Box>

        <H3 gutterBottom>Frequently Asked Questions</H3>
        <Box className={classes.contentStyles}>
          {FAQs.map(({ title, content }) => {
            const id = titleToId(title)
            const contentId = `${id}-content`
            const headerId = `${id}-header`
            return (
              <Accordion key={title}>
                <AccordionSummary
                  aria-controls={contentId}
                  id={headerId}
                  expandIcon={<ChevronDown />}
                >
                  {title}
                </AccordionSummary>
                <AccordionDetails id={contentId}>{content}</AccordionDetails>
              </Accordion>
            )
          })}
        </Box>
      </Box>
    </Page>
  )
}

export default FAQ
