import React from 'react'
import {
  makeStyles,
  Page as CorePage,
  PageProps as CorePageProps,
} from '@perk-ui/core'
import { motion } from 'framer-motion'

import Slide from '../../features/animation/Slide'
import { isMobileApp } from '../../utils/platform'

interface PageProps extends Omit<CorePageProps, 'navVariant'> {}

const useStyles = makeStyles((theme) => ({
  desktopConstraints: {
    boxShadow: theme.perk.boxShadow.card,
    maxWidth: 620,
    margin: 'auto',
  },
}))

/**
 *
 * Notes on having the header title text animate separately:
 *  - The incoming Header (specifially the AppBar) is stacked over the leaving
 *    header when the animation begins, obfuscating the leaving header's exit animation
 *  - Setting the incoming AppBar's background to transparent allows you to see the
 *    exiting header, but also of course shows the other things below it
 *  - The Header title could be moved up in the stacking order via z-index and
 *    position: absolute, but the incoming AppBar creates its own stacking context,
 *    making it difficult (I haven't figured out how) to place an item ontop of it.
 *      - Perhaps the AppBars could be rendered in Portals?
 */
const Page: React.FC<PageProps> = ({ children, style, ...rest }) => {
  const classes = useStyles()
  const navVariant = isMobileApp() ? 'bottom' : 'tab'
  const disableAnimations = !isMobileApp()

  return (
    <motion.div
      animate={
        disableAnimations
          ? undefined
          : {
              position: 'absolute',
              transitionEnd: { position: undefined }, // removes the `position` attribute
            }
      }
      exit={{
        position: 'absolute',
      }}
    >
      <CorePage
        navVariant={navVariant}
        className={classes.desktopConstraints}
        style={{
          // Constrain the page so that it doesn't overflow during transitions
          // i.e. the Me Page's Horizontal gallery
          width: '100vw',
          ...style,
        }}
        {...rest}
      >
        <Slide inFrom="100vw" outTo="-100vw" disable={disableAnimations}>
          {children}
        </Slide>
      </CorePage>
    </motion.div>
  )
}

export default Page

// App name: Taper App
// 1. DJ-like pages: change header color to grey
// 2. Left align the title
// 3. Home and Me pages show app name rather than page title
// 4. Add settings icon to Home page
// 5. Try to 24px blur
