/**
 * TODO Refactor this file to something like app constants
 */

export const questionnaireMap: Record<string, string> = {
  interference: '6CAD2A91-2182-408F-BAF8-1F1F10F9D6BD',
  intensity: 'A8F51229-D1C3-4DA2-A661-203A93DC1F99',
  DailyPainJournal: '3e5c8018-fbff-4ccd-ab6c-031aaa046e20',
  '3e5c8018-fbff-4ccd-ab6c-031aaa046e20': 'DailyPainJournal',
  '6CAD2A91-2182-408F-BAF8-1F1F10F9D6BD': 'interference',
  'A8F51229-D1C3-4DA2-A661-203A93DC1F99': 'intensity',
}

export const PERK_HEALTH_SYSTEM = 'https://perkhealth.me'

export const SENTIMENT_VALUESET_ID = '502edb9c-d8d2-4513-aa36-edb6a8efb199'
export const ACTIVITIES_VALUESET_ID = '2e9276a6-b470-4c9d-a95f-edcb5c0bcc82'

export const ACTIVITIES_VALUESET_URL =
  'https://perkhealth.me/fhir/r4/ValueSet/chronic-pain-activities'
export const SENTIMENTS_VALUESET_URL =
  'https://perkhealth.me/fhir/r4/ValueSet/chronic-pain-sentiments'

export const SYNONYM_DESIGNATION_CODE = '900000000000013009'
export const FULLY_SPECIFIED_DESIGNATION_CODE = '900000000000003001'
