import React from 'react'
import { Body2, Box, makeStyles } from '@perk-ui/core'

export interface LabeledAvatarProps {
  label: string
  ariaLabel: string
  isHighlighted?: boolean
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  labelContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '26px', // Ensure the radial gradient isn't cropped
    justifyContent: 'center',
    marginBottom: 4,
  },
  highlightCircle: {
    position: 'absolute',
    background: 'green',
    borderRadius: '100%',
    width: '26px',
    height: '26px',
    zIndex: 1,
  },
}))

const LabeledAvatar: React.FC<LabeledAvatarProps> = ({
  label,
  ariaLabel,
  isHighlighted,
  children,
}) => {
  const classes = useStyles()

  return (
    <Box textAlign="center" className={classes.root}>
      <Box className={classes.labelContainer}>
        {isHighlighted && <Box className={classes.highlightCircle}></Box>}
        <Body2
          style={{ color: isHighlighted ? 'white' : '', zIndex: 2 }}
          aria-label={ariaLabel}
        >
          {label}
        </Body2>
      </Box>
      {children}
    </Box>
  )
}

export default LabeledAvatar
