import React from 'react'
import { CardMedia, CardMediaProps, Skeleton } from '@perk-ui/core'

import ConditionalWrap from '../utils/ConditionalWrap'

interface CardImageProps extends CardMediaProps<'img'> {
  /**
   * Height of the image and skeleton placeholder
   * @default 140px
   */
  height?: string

  /**
   * Alternative text that could be used if the image doesn't load or the user agent prevents it
   * "It is supposed to contain replacement text that could be used by users instead of the image; it is not meant to supplement the image."
   *
   * We've overriden the `img` tag's base types here to ensure an `alt` tag is provided
   */
  alt: string
}

/**
 * A wrapper around the CardMedia component that renders a Skeleton over the image while it is loading
 */
const CardImage: React.FC<CardImageProps> = ({
  height = '140px',
  alt,
  ...rest
}) => {
  const [loaded, setLoaded] = React.useState(false)

  return (
    <ConditionalWrap
      condition={!loaded}
      wrapper={(children) => (
        <Skeleton variant="rect" style={{ maxWidth: 'unset' }}>
          {children}
        </Skeleton>
      )}
    >
      <CardMedia
        component="img"
        height={height}
        alt={alt}
        src={rest.src}
        onLoad={(evt: React.SyntheticEvent<HTMLImageElement>) => {
          setLoaded(true)
          rest?.onLoad?.(evt)
        }}
        {...rest}
      />
    </ConditionalWrap>
  )
}

export default CardImage
