/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Returns an array of @param thing
 */
export function ensureArray<T = any>(thing: T | T[]) {
  return Array.isArray(thing) ? thing : [thing]
}

/**
 * Returns the first element in @param array that has a @param key with value @param value
 *
 * i.e.
 * const list = [{color: 'red'}, {color: 'blue'}]
 * findBy('color', 'blue', list) //=> {color: 'blue'}
 */
export function findBy<T = any>(key: string, value: any, array: T[] = []) {
  return array.find((item: any) => item[key] === value)
}

/**
 * Returns an object with values from @param items grouped by a key or predicate fn.
 * A simpler version of the groupBy available in lodash/alternatives.
 *
 * @example
 * const sentiments = [{ label: 'height', value: 67}, { label: 'height', value: 62 }]
 * groupBy('label', sentiments)
 * // => { height: [{ label: 'height', value: 67}, { label: 'height', value: 62 }] }
 *
 * @see https://lodash.com/docs/#groupBy
 *
 * @param keyOrFn A string key or function with which to group the items. If the predicate
 *                returns null, it is excluded from the @return grouping
 * @param items The items to process for grouping
 */
export function groupBy<T extends { [key: string]: any }>(
  keyOrFn: string | ((arg: T) => string | null),
  items: T[],
) {
  return items.reduce((groups, item) => {
    const key = typeof keyOrFn === 'function' ? keyOrFn(item) : item[keyOrFn]
    if (key === null) return groups

    if (key in groups) {
      groups[key].push(item)
    } else {
      groups[key] = [item]
    }

    return groups
  }, {} as { [a: string]: T[] })
}
