// TODO All of these service interfaces should be generated from .proto files

export const WEEKLY_ASSESSMENT_DUE = 'weekly_assessment_due'
export const INITIAL_JOURNAL_PROMPT = 'initial_journal_prompt'
export const INITIAL_PATIENT_CONSENT = 'initial_patient_consent'

export type InterruptKind =
  | typeof WEEKLY_ASSESSMENT_DUE
  | typeof INITIAL_JOURNAL_PROMPT
  | typeof INITIAL_PATIENT_CONSENT

export type InterruptState = 'inactive' | 'active' | 'complete' | 'canceled'

export interface InterruptSearchData {
  id: string
  state: string
  kind: InterruptKind
  priority: number
  user_id: string
  /** `new Date()`-able */
  created_at: string
  /** `new Date()`-able */
  updated_at: string
}

export interface InterruptRule {
  validLocations: string[]
  initialPath: string
}

export const InterruptRules: Record<InterruptKind, InterruptRule> = {
  [WEEKLY_ASSESSMENT_DUE]: {
    validLocations: ['/auth', '/weekly-assessment'],
    initialPath: '/weekly-assessment/intro',
  },
  [INITIAL_JOURNAL_PROMPT]: {
    validLocations: [
      '/auth',
      `/home?prompt=${INITIAL_JOURNAL_PROMPT}`,
      '/daily-journal',
      '/me/manage-activities',
    ],
    initialPath: `/home?prompt=${INITIAL_JOURNAL_PROMPT}`,
  },
  [INITIAL_PATIENT_CONSENT]: {
    validLocations: ['/auth', '/consent'],
    initialPath: '/consent/summary',
  },
}
