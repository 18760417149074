import React, { useEffect } from 'react'

import { AuthService } from '../../features/auth/AuthService'

const OAuthPopupCallback: React.FC = React.memo(() => {
  useEffect(() => {
    // This is an OK usage of new AuthService b/c this is always in
    // a new window. Otherwise we should always avoid using the AuthService
    // directly except within the AuthContext
    const authService = new AuthService()
    const url = window.location.href
    authService.signinPopupCallback(url)
  }, [])

  return null
})

export default OAuthPopupCallback
