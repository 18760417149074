import React from 'react'
import { Body1, H3, makeStyles, Typography } from '@perk-ui/core/dist'

import {
  scoreToLabel,
  WeeklyAssessmentScoreRangeMap,
  weeklyAssessmentScoreToColor,
} from '../../../../utils/painScores'
import PainScoreScale from '../PainScoreScale'

interface ResultCardProps {
  title: string
  description: string
  score: number
  scoreMap: WeeklyAssessmentScoreRangeMap
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  score: {
    fontSize: '4rem',
    fontWeight: 'bold',
    lineHeight: 1,
  },
}))

const ResultCard: React.FC<ResultCardProps> = ({
  title,
  description,
  score,
  scoreMap,
}) => {
  const classes = useStyles()
  const { main: scoreColor } = weeklyAssessmentScoreToColor(scoreMap, score)
  const scoreLabel = scoreToLabel(scoreMap, score)

  return (
    <>
      <H3>{title}</H3>
      <Body1 paragraph>{description}</Body1>
      <div>
        <Typography className={classes.label} style={{ color: scoreColor }}>
          {scoreLabel}
        </Typography>
        <Typography className={classes.score} style={{ color: scoreColor }}>
          {score}
        </Typography>
        <PainScoreScale score={score} scoreMap={scoreMap} />
      </div>
    </>
  )
}

export default ResultCard
