import React from 'react'

import ImgWithFallback from '../../../components/ImgWithFallback'

const MedStarLogo: React.FC = () => (
  <ImgWithFallback
    src={`${process.env.PUBLIC_URL}/assets/MedStar_Consent_Logo.webp`}
    fallback={`${process.env.PUBLIC_URL}/assets/MedStar_Consent_Logo.jpg`}
    alt="MedStar Health Research Institute logo"
    minHeight="60px"
    style={{
      justifyContent: 'initial',
      marginLeft: -16, // Neutralize some whitespace in the logo image
    }}
  />
)

export default MedStarLogo
