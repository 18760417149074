import React from 'react'
import { useLocation } from 'react-router'

import { Page } from '../../components/Layout'
import { WeeklyAssessmentLocationState } from './Intro/WeeklyAssessmentIntro'
import SurveyController from './SurveyController'

const WeeklyAssessment: React.FC = () => {
  const { state } = useLocation<WeeklyAssessmentLocationState>()
  const { intensity, interference } = state
  const _v = ['']

  // If intensity has a score, move on to interference, or redirect to results
  const questionnaires = [intensity, interference]
    .filter((qr) => !qr.score)
    .map((qr) => qr.questionnaireId)

  return (
    <Page title="Taper App - Weekly Assessment">
      <SurveyController questionnaireIds={questionnaires} />
    </Page>
  )
}

export default WeeklyAssessment
