import React from 'react'
import { H3, makeStyles, Skeleton } from '@perk-ui/core'

const useStyles = makeStyles((theme) => ({
  feedLabelSkeleton: {
    marginBottom: theme.spacing(3),
  },
}))

const FeedLabelSkeleton: React.FC = () => {
  const classes = useStyles()

  return (
    <Skeleton className={classes.feedLabelSkeleton}>
      <H3>......</H3>
    </Skeleton>
  )
}

export default FeedLabelSkeleton
