import React from 'react'
import { Body1, Box, BoxProps } from '@perk-ui/core'

export interface SectionTitleProps extends BoxProps {
  title: string
  helper?: string
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  helper,
  style,
  ...rest
}) => {
  return (
    <Box
      pt={3}
      pb={2}
      style={{ width: '100%', textAlign: 'left', ...style }}
      {...rest}
    >
      <Body1 gutterBottom={!!helper} style={{ fontWeight: 'bold' }}>
        {title}
      </Body1>
      {helper && <Body1>{helper}</Body1>}
    </Box>
  )
}

export default SectionTitle
