import React from 'react'
import { Box } from '@perk-ui/core'

import LabeledAvatar from '../../../components/LabeledAvatar'
import PainScoreCircle from '../../../components/PainScoreCircle'
import {
  DAYS_THIS_WEEK,
  toDayAbbreviation,
  toDayName,
} from '../../../utils/dates'
import { DailyJournalItem } from '../FeedResponse'

export interface DailyJournalScoreListProps {
  data: DailyJournalItem['data']
}

const DailyJournalScoreList: React.FC<DailyJournalScoreListProps> = ({
  data,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" mt={1}>
      {DAYS_THIS_WEEK.map((day) => {
        const dayName = toDayName(day)
        const dayAbbrev = toDayAbbreviation(day)
        const score = data.scores.find((d) => d.day === dayName)

        return (
          <LabeledAvatar key={dayName} label={dayAbbrev} ariaLabel={dayName}>
            <PainScoreCircle score={score?.value} />
          </LabeledAvatar>
        )
      })}
    </Box>
  )
}

export default DailyJournalScoreList
