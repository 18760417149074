import { useLocation } from 'react-router'

/**
 * Returns the parsed Query parameter @param key from the URL.
 *
 * @example
 * const id = useQueryParam('id')
 *
 * @note Would be a nice enhancement to make this like a useState, where you can read and set @param key
 *
 * @note I tried a few fancier signatures but wasn't able to get them working well:
 * @example `useQueryParams<K extends string | ArrayParamConfig>(keys: K[]) {`
 * @example `useQueryParam<T extends boolean>(key: string, isArray?: T): (T extends true ? string[] : string`
 */
export function useQueryParam<T extends string>(key: T) {
  const params = new URLSearchParams(useLocation().search)
  return params.get(key)
}
