import React, { useLayoutEffect, useState } from 'react'
import { makeStyles } from '@perk-ui/core'
import clsx from 'clsx'
import { Triangle } from 'react-feather'

import {
  severityForScore,
  weeklyAssessmentScoreColorMap,
  WeeklyAssessmentScoreRangeMap,
  weeklyAssessmentScoreToColor,
} from '../../../utils/painScores'
import { randOfRange } from '../../../utils/randoms'

interface PainScoreScaleProps {
  scoreMap: WeeklyAssessmentScoreRangeMap
  score: number
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .pain-scale-bar:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    '& .pain-scale-bar:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    },
  },
  bar: {
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.21)',
    height: theme.spacing(2),
  },
  num: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
  },
  maxNum: {
    textAlign: 'end',
    gridColumn: '4',
  },
  grid: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    width: 'calc(100% - 24px)',
    columnGap: '8px',
  },
}))

const PainScoreScale: React.FC<PainScoreScaleProps> = ({ scoreMap, score }) => {
  const classes = useStyles()
  const [rnd] = useState<number>(() => randOfRange(0, 100000))
  const uniqRootClass = `pain-score-scale-${rnd}`
  const { min, max, normal, mild, moderate, severe } = scoreMap
  const { main: scoreColor } = weeklyAssessmentScoreToColor(scoreMap, score)

  // Calculate each severity's proprtions of the bar
  const scoreRange = max - min
  const w1 = ((normal - min) / scoreRange) * 100
  const w2 = ((mild - normal) / scoreRange) * 100
  const w3 = ((moderate - mild) / scoreRange) * 100
  const w4 = ((max - moderate) / scoreRange) * 100
  const gridTemplateColumns = `${w1}% ${w2}% ${w3}% ${w4}%`

  // I found it very difficult to coordinate the Triangle's placement
  // with static CSS styles. In particular, it was difficult to
  // account for the margin or column gap between each severity's bar.
  // This approach uses DOM introspection to place the triangle.
  useLayoutEffect(() => {
    const severity = severityForScore(scoreMap, score)
    const $bar = document.querySelector(
      `.${uniqRootClass} .pain-scale-${severity}`,
    ) as HTMLElement
    if (!$bar) return

    // The ratio of this score within the upper and lower bound of its severity
    // i.e. score of 35 is 0.5 of the way between 15 and 55
    const ratioInBar =
      severity === 'normal'
        ? (score - min) / (normal - min)
        : severity === 'mild'
        ? (score - normal) / (mild - normal)
        : severity === 'moderate'
        ? (score - mild) / (moderate - mild)
        : (score - moderate) / (severe - moderate)

    const $triangle = document.querySelector(
      `.${uniqRootClass} .pain-scale-triangle`,
    ) as HTMLElement
    if (!$triangle) return

    const { offsetLeft, offsetWidth: widthOfBar } = $bar
    const parentLeft = $bar.parentElement?.offsetLeft ?? 0
    const widthFromLeftOfScale = offsetLeft - parentLeft
    $triangle.style.marginLeft =
      widthFromLeftOfScale + ratioInBar * widthOfBar + 'px'
    // eslint-disable-next-line
  }, [score, scoreMap])

  return (
    <div className={clsx(classes.root, uniqRootClass)}>
      <div className={'pain-scale-triangle'}>
        <Triangle
          style={{
            marginLeft: -12,
            transform: 'rotate(180deg)',
            color: scoreColor,
            fill: scoreColor,
          }}
        />
      </div>
      <div
        className={clsx('pain-scale-bars', classes.grid)}
        style={{
          gridTemplateColumns,
        }}
      >
        <ColorBar severity="normal" />
        <ColorBar severity="mild" />
        <ColorBar severity="moderate" />
        <ColorBar severity="severe" />
      </div>
    </div>
  )
}

const ColorBar: React.FC<{
  severity: keyof typeof weeklyAssessmentScoreColorMap
}> = ({ severity }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx('pain-scale-bar', `pain-scale-${severity}`, classes.bar)}
      style={{
        backgroundColor: weeklyAssessmentScoreColorMap[severity].main,
      }}
    ></div>
  )
}

export default PainScoreScale
