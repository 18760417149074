import React, { HTMLProps } from 'react'
import { makeStyles } from '@perk-ui/core'

export interface EmojiProps extends HTMLProps<HTMLSpanElement> {
  symbol: string
  label: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '2rem',
    fontFamily: `-webkit-pictograph, ${theme.typography.fontFamily}`,
  },
}))

const Emoji: React.FC<EmojiProps> = ({ symbol, label, ...rest }) => {
  const classes = useStyles()
  return (
    <span
      className={classes.root}
      role="img"
      aria-label={label ? label : ''}
      aria-hidden={label ? 'false' : 'true'}
      {...rest}
    >
      {symbol}
    </span>
  )
}

export default Emoji
