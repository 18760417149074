import './theme-type-extensions.d.ts'

import { createPerkPalette, createPerkTheme } from '@perk-ui/core'

const opioidPalette = createPerkPalette({
  primary: {
    main: 'rgb(30, 112, 66)',
  },
  secondary: {
    main: 'rgb(255, 225, 199)',
  },
  success: {
    main: 'rgba(30, 112, 66, 0.53)',
  },
  error: {
    main: 'rgb(179, 0, 0)',
  },
})

export const fancyFontFamily = [
  'Baskervville, serif',
  '-apple-system',
  'BlinkMacSystemFont',
  'sans-serif',
].join(',')

export const opioidTheme = createPerkTheme(opioidPalette, {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          // Prevents the horizontal scrollbar from showing when transitioning Routes
          overflowX: 'hidden',
        },
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        '& p': {
          fontFamily: fancyFontFamily,
        },
      },
    },
    MuiDialog: {
      paperFullWidth: {
        width: 'calc(100% - 48px)',
      },
    },
  },
  typography: {
    h1: {
      fontFamily: fancyFontFamily,
      fontWeight: 400,
      fontStyle: 'normal',
    },
    h2: {
      fontFamily: fancyFontFamily,
      fontWeight: 400,
      fontStyle: 'normal',
    },
    h3: {
      fontFamily: fancyFontFamily,
      fontWeight: 400,
      fontStyle: 'normal',
    },
  },
})

// It's convenient to be able to dig into the theme at runtime.
// eslint-disable-next-line
;(window as any).materialTheme = opioidTheme
