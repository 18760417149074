import React from 'react'
import { Redirect, RedirectProps } from 'react-router'

import useRedirectCleanup from './useRedirectCleanup'

const AnimatedRedirect: React.FC<RedirectProps> = (props) => {
  useRedirectCleanup()

  return <Redirect {...props} />
}

export default AnimatedRedirect
