import React from 'react'
import { Body1 } from '@perk-ui/core'

import ImgWithFallback from '../../../components/ImgWithFallback'
import {
  BodyItem as BodyItemData,
  ImageItem,
  LinkItem,
  TextItem,
  VideoItem,
} from '../patient-resource-data'
import BodyLink from './BodyLink'
import Video from './Video'

interface BodyItemProps {
  item: BodyItemData
}

const BodyItem: React.FC<BodyItemProps> = ({ item }) => {
  switch (item.type) {
    case 'text': {
      const { value, meta } = item as TextItem
      const isBold = meta?.bold
      return (
        <Body1
          style={{
            marginBottom: '0.75rem',
            ...(isBold ? { fontWeight: 'bold' } : {}),
          }}
        >
          {value}
        </Body1>
      )
    }
    case 'link': {
      const { value } = item as LinkItem
      return <BodyLink value={value} />
    }
    case 'video': {
      const { value, title } = item as VideoItem
      return <Video src={value} title={title} />
    }
    case 'image': {
      const { source, alt } = item as ImageItem
      return (
        <ImgWithFallback
          src={source.webp}
          fallback={source.jpg}
          alt={alt}
          style={{ paddingBottom: 16 }}
        />
      )
    }
    default: {
      throw new Error(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        `Unrecognized Body Item type: ${(item as any).type}. Item: ${item}`,
      )
    }
  }
}

export default BodyItem
