import React from 'react'
import { Box, H3, HeaderBar, HeaderBarButton } from '@perk-ui/core'
import { useParams } from 'react-router'

import ImgWithFallback from '../../components/ImgWithFallback'
import { Footer, Header, Page } from '../../components/Layout'
import { findBy } from '../../utils/arrays'
import { useHistory } from '../../utils/useHistory'
import BodyItem from './components/BodyItem'
import resourceData from './patient-resource-data'

const PatientResource: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { goBack } = useHistory()

  const resource = findBy('id', id, resourceData)
  if (!resource) {
    throw new Error(`Failed to find patient resource with ID: ${id}`)
  }

  const { title, body } = resource

  return (
    <Page
      title="Taper App - Resources"
      header={
        <Header withNav={false}>
          <HeaderBar
            titleContent="Resources"
            left={<HeaderBarButton variant="back" onClick={goBack} />}
          />
        </Header>
      }
      footer={<Footer withNav={false}></Footer>}
    >
      <ImgWithFallback
        style={{ maxHeight: '250px', overflow: 'hidden' }}
        src={`${process.env.PUBLIC_URL}/images/${resource.id}.webp`}
        fallback={`${process.env.PUBLIC_URL}/images/${resource.id}.jpg`}
        alt={resource.title} // This is a bit iffy as to whether this alt is actually sufficient
      />
      <Box p={3}>
        <H3 gutterBottom>{title}</H3>
        {body.map((item, idx) => (
          <BodyItem key={idx} item={item} />
        ))}
      </Box>
    </Page>
  )
}

export default PatientResource
