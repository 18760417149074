import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import apm from '../../config/analytics'

/**
 * This hook reports `route-change` events to APM. It is intended to be used within a Route component.
 *
 * Most of the logic and comments here were taken from the @elastic/apm-rum-react package
 * @see https://github.com/elastic/apm-agent-rum-js/blob/master/packages/rum-react/src/get-with-transaction.js
 */
const useRouteChangeEvent = () => {
  const { pathname } = useLocation()
  /**
   * We start the transaction as soon as the ApmComponent gets rendered
   * so that we can capture all the effects inside child components
   *
   * The reason why we have this transaction inside setState is that we don't
   * want this piece of code to run on every render instead we want to
   * start the transaction only on component mounting
   */
  const [transaction] = useState(() => {
    if (!apm.isActive()) return

    return apm.startTransaction(pathname, 'route-change', {
      managed: true,
      canReuse: true,
    })
  })

  /**
   * React guarantees the parent component effects are run after the child components effects
   * So once all the child components effects are run, we end the transaction.
   */
  useEffect(() => {
    requestAnimationFrame(() => transaction?.end())
    return () => {
      /**
       * Incase the transaction is never ended, we check if the transaction
       * needs to be closed during unmount phase
       *
       * We call detectFinish instead of forcefully ending the transaction
       * since it could be a redirect route and we might prematurely close
       * the currently running transaction
       */
      if (!transaction?.isFinished()) transaction?.end()
    }
  }, [transaction])
}

export default useRouteChangeEvent
