import { IQuestionnaireResponse } from '@ahryman40k/ts-fhir-types/lib/R4'
import { useMutation } from 'react-query'

import request from '../../config/axios'

const useNextQuestion = () => {
  // `https://eac.perk-demo.com/ac_api/2018-10/Questionnaire/${surveyId}/next-q`
  const url = '/fhir/Questionnaire/$next-question'

  return useMutation<IQuestionnaireResponse, unknown, IQuestionnaireResponse>(
    (vars) => request.post(url, vars).then((r) => r.data),
  )
}

export default useNextQuestion
