import axios from 'axios'

import config from './app-config'

export const BaseAxiosConfig = {
  baseURL: config.baseApiPath,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
  },
}

const request = axios.create(BaseAxiosConfig)

request.interceptors.request.use((req) => {
  // Update headers when a request is made to our /fhir endpoints
  if (req.url?.startsWith('/fhir')) {
    req.headers['Content-Type'] = 'application/fhir+json; charset=UTF-8'
    req.headers['Accept'] =
      'application/fhir+json;q=1.0, application/json+fhir;q=0.9'
  }

  // Strip our Auth headers if we're requesting a resource from
  // anywhere other than our servers
  try {
    if (req.baseURL !== config.baseApiPath) {
      delete req.headers['Authorization']
      delete req.headers['X-Hub-Authorization']
    }

    const url = new URL(req.url || '')
    if (!url.origin.startsWith(config.baseApiPath)) {
      delete req.headers['Authorization']
      delete req.headers['X-Hub-Authorization']
    }
  } catch {
    // request was not directed to an external server
  }

  return req
})

// This could be where we parse snake_case response keys to camelCase
// fetcher.interceptors.response.use((response) => {
//   const data = response.data
//   const formattedData = <do the casing transform here>
//   response.data = formattedData
//   return response
// })

/**
 * Update the `Authorization` header for all Axios requests
 * @param idpAccessToken A token provided by an external Identity Provider
 */
export const setIdpAuthHeader = (idpAccessToken?: string) => {
  const currentHeaders = request.defaults.headers

  if (idpAccessToken) {
    request.defaults.headers = {
      ...currentHeaders,
      Authorization: `Bearer ${idpAccessToken}`,
    }
  } else {
    delete request.defaults.headers['Authorization']
  }
}

/**
 * Update the `X-Hub-Authorization` header for all Axios requests
 * @param hubAccessToken A token provided by Perk's Auth System / Hub Identity Provider
 */
export const setHubAuthHeader = (hubAccessToken?: string) => {
  const currentHeaders = request.defaults.headers

  if (hubAccessToken) {
    request.defaults.headers = {
      ...currentHeaders,
      'X-Hub-Authorization': `Bearer ${hubAccessToken}`,
    }
  } else {
    delete request.defaults.headers['X-Hub-Authorization']
  }
}

export default request
