/**
 * Converts a string with escaped HTML characters to a valid HTML string.
 *
 * @example
 * const encodedHTMLString = "How intense was your pain at its &lt;u&gt;worst&lt;/u&gt;?"
 * decodeHTMLString(encodedHTMLString) => "How intense was your pain at its <u>worst</u>?"
 */
export const decodeHTMLString = (htmlString: string) => {
  const div = document.createElement('div')
  div.innerHTML = htmlString
  return div.childNodes.length === 0 ? '' : div.childNodes[0].nodeValue || ''
}
