import React from 'react'
import { Card, CardActions, CardContent, Stack } from '@perk-ui/core'
import { differenceInDays, isAfter, isToday, parse, startOfDay } from 'date-fns'

import AppLinkButton from '../../components/AppLinkButton'
import { toRelativeDateOrString } from '../../utils/dates'
import { MedicationChangeItem } from './FeedResponse'

export interface MedicationChangeFeedItemProps {
  item: MedicationChangeItem
}

const buildBody = (day: Date) => {
  if (isToday(day)) {
    return 'Your medication is scheduled to change today'
  } else if (isAfter(day, new Date())) {
    const daysFromNow = differenceInDays(day, startOfDay(new Date()))

    return `In ${daysFromNow} day${
      daysFromNow > 1 ? 's' : ''
    }, your medication is scheduled to change`
  } else {
    const daysAgo = differenceInDays(startOfDay(new Date()), day)
    return `Your medication changed ${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`
  }
}

const buildTitle = (day: Date) => {
  if (isAfter(day, new Date())) {
    return 'Medication Change Coming Up'
  } else if (isToday(day)) {
    return 'Medication Change Today'
  } else {
    return 'Medication Changed'
  }
}

// TODO Make link to medication change page work
const MedicationChangeFeedItem: React.FC<MedicationChangeFeedItemProps> = ({
  item,
}) => {
  const { change_date: date_string, time_helper } = item

  const change_date = parse(date_string, 'yyyy-MM-dd', new Date())

  const title = buildTitle(change_date)
  const body = buildBody(change_date)
  const helperText = toRelativeDateOrString(time_helper)

  return (
    <Card style={{ marginBottom: 24 }}>
      <CardContent>
        <Stack title={title} body={body} helper={helperText} />
      </CardContent>
      <CardActions>
        <AppLinkButton size="medium" fullWidth to={'/'}>
          View Change
        </AppLinkButton>
      </CardActions>
    </Card>
  )
}

export default MedicationChangeFeedItem
