import React from 'react'
import { ICarePlan } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Card, CardContent, Stack, tail } from '@perk-ui/core'

import MedicationDecreaseGraph from '../../../features/Fhir/CarePlan/MedicationDecreaseGraph'
import { getFinalTaperStepValues } from '../../../features/Fhir/CarePlan/taperStepUtils'

interface MedicationDecreaseCardProps {
  data: ICarePlan[]
}

const MedicationDecreaseCard: React.FC<MedicationDecreaseCardProps> = ({
  data,
}) => {
  // If we only have 1 taper step, there is no graph data to show
  if (data.length <= 1) {
    return null
  }

  const latestTaperStep = data[0]
  const initialTaperStep = tail(data)

  const beginningMme = getFinalTaperStepValues(initialTaperStep).mme
  const afterMme = getFinalTaperStepValues(latestTaperStep).mme

  // If the graph won't show a decrease, or shows an increase, don't show the graph
  if (afterMme >= beginningMme) {
    return null
  }

  return (
    <Card style={{ marginBottom: 24 }}>
      <CardContent>
        <Stack title="My Taper Progress" />
        <MedicationDecreaseGraph
          data={{ before: { value: beginningMme }, after: { value: afterMme } }}
        />
      </CardContent>
    </Card>
  )
}

export default MedicationDecreaseCard
