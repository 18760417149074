import React from 'react'
import { AppBar, AppBarProps, Box } from '@perk-ui/core'

import { isPlatform } from '../../utils/platform'
import BottomNav from './BottomNav'

export interface FooterProps extends AppBarProps {
  /**
   * @default true
   */
  withNav: boolean

  /**
   * @default true
   */
  withSafeInset?: boolean
}

const Footer: React.FC<FooterProps> = ({
  children,
  withNav,
  withSafeInset = true,
  ...rest
}) => {
  const isPhoneApp = isPlatform('mobile') && !isPlatform('mobileweb')

  return (
    <AppBar placement="bottom" {...rest}>
      <Box
        style={{
          maxWidth: 620,
          width: '100%',
          margin: 'auto',
        }}
      >
        {children}
      </Box>
      {isPhoneApp && withNav && <BottomNav />}
      {withSafeInset && (
        <div style={{ paddingBottom: 'env(safe-area-inset-bottom)' }} />
      )}
    </AppBar>
  )
}

export default Footer
