import { EnvConfig } from './config-interfaces'

const p41Config: EnvConfig = {
  baseApiPath: 'https://api.humanfactorsapps.com',
  apmUrl: 'https://al.humanfactorsapps.com',
  oauthConfig: {
    popup: {
      clientId: '770f53e0-4e05-4a13-b0e2-67ce39e2e3be',
      redirectUrl: 'https://app.humanfactorsapps.com/oauthPopupCallback',
      windowOptions:
        'location=no,toolbar=no,width=500,height=800,left=100,top=100',
    },
    redirect: {
      clientId: 'c109a387-9980-457e-99bd-65cf3f032d5d',
      redirectUrl: 'https://app.humanfactorsapps.com/oauthRedirectCallback',
    },
    ios: {
      clientId: 'bc4bef57-fe13-4d3e-a539-d915093d17d7',
      redirectUrl: 'com.perkmotivation.ChronicPain://oauthCallback',
      callbackScheme: 'com.perkmotivation.ChronicPain://',
      callbackPath: 'oauthCallback',
    },
    android: {
      clientId: 'bc4bef57-fe13-4d3e-a539-d915093d17d7',
      redirectUrl: 'com.perkmotivation.ChronicPain://oauthCallback',
      callbackScheme: 'com.perkmotivation.chronicpain://', // android callback must be lowercase
      callbackPath: 'oauthCallback',
    },
    shared: {
      authorizationBaseUrl:
        'https://authorization.cerner.com/tenants/c2a9aaad-29f4-4d5d-87b0-388c13a9b433/protocols/oauth2/profiles/smart-v1/personas/patient/authorize',
      accessTokenEndpoint:
        'https://authorization.cerner.com/tenants/c2a9aaad-29f4-4d5d-87b0-388c13a9b433/protocols/oauth2/profiles/smart-v1/token',
      scope:
        'patient/Patient.read patient/MedicationRequest.read openid fhirUser profile online_access launch/patient',
      aud:
        'https://fhir-ehr.cerner.com/r4/c2a9aaad-29f4-4d5d-87b0-388c13a9b433',
      iss:
        'https://authorization.cerner.com/tenants/c2a9aaad-29f4-4d5d-87b0-388c13a9b433/oidc/idsps/c2a9aaad-29f4-4d5d-87b0-388c13a9b433-ch/',
      authority:
        'https://authorization.cerner.com/tenants/c2a9aaad-29f4-4d5d-87b0-388c13a9b433/oidc/idsps/c2a9aaad-29f4-4d5d-87b0-388c13a9b433-ch/.well-known/openid-configuration',
      jwksUri: 'https://authorization.cerner.com/jwk',
    },
  },
}

export default p41Config
