import React, { useCallback, useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Body1,
  Box,
  Button,
  CircularProgress,
  H3,
  IconButton,
  makeStyles,
} from '@perk-ui/core'
import { ArrowLeft } from 'react-feather'

import ControlledButtonGroup from '../../components/ControlledButtonGroup'
import { decodeHTMLString } from '../../utils/decodeHTMLString'

const getAnswerOptionText = (opt: R4.IQuestionnaire_AnswerOption) => {
  return opt.valueCoding?.display || opt.valueString
}

interface SurveyProps {
  goBack?: () => Promise<void>
  prompt: string
  question: string
  answerOptions: R4.IQuestionnaire_AnswerOption[]
  onAnswer: (option: R4.IQuestionnaire_AnswerOption) => Promise<void>
}

const useStyles = makeStyles((theme) => ({
  header: {
    '@media (min-height: 700px)': {
      marginBottom: '30%',
    },
    marginLeft: -16,
  },
  spacer: {
    height: theme.spacing(6),
    visibility: 'hidden',
  },
}))

// TODO rename this component: it's a type of page that displays only one question with a type of Group
const Survey: React.FC<SurveyProps> = ({
  goBack,
  prompt,
  question,
  answerOptions,
  onAnswer,
}) => {
  const classes = useStyles()
  const [goingBack, setGoingBack] = useState(false)
  const handleAnswer = useCallback(
    (id) => {
      const option = answerOptions.find((opt) => opt.id === id)
      if (!option) return Promise.reject()

      return onAnswer(option)
    },
    [onAnswer, answerOptions],
  )

  const handleBack = () => {
    setGoingBack(true)
    goBack && goBack().finally(() => setGoingBack(false))
  }

  return (
    <Box
      p={3}
      style={{
        height:
          'calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top))',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Box className={classes.header}>
          {goBack ? (
            goingBack ? (
              <Box m={2}>
                <CircularProgress color="inherit" size={24} />
              </Box>
            ) : (
              <IconButton onClick={handleBack}>
                <ArrowLeft />
              </IconButton>
            )
          ) : (
            <div className={classes.spacer}>spacer</div>
          )}
        </Box>
        <H3 color="primary" paragraph>
          {prompt}
        </H3>

        <Body1 paragraph style={{ fontSize: '2rem', fontWeight: 'bold' }}>
          <span
            dangerouslySetInnerHTML={{ __html: decodeHTMLString(question) }}
          ></span>
        </Body1>
      </Box>
      <Box>
        <ControlledButtonGroup onButtonSelect={handleAnswer}>
          {answerOptions.map((opt) => (
            <Button
              key={opt.id}
              id={opt.id}
              fullWidth
              size="large"
              color="default"
              style={{
                fontWeight: 'bold',
              }}
            >
              {getAnswerOptionText(opt) || 'answerOption.valueString'}
            </Button>
          ))}
        </ControlledButtonGroup>
      </Box>
    </Box>
  )
}

export default Survey
