import { useMutation, useQueryClient } from 'react-query'

import request from '../../config/axios'

const useCompleteInterrupt = () => {
  const queryClient = useQueryClient()

  return useMutation<string, unknown, string>(
    (id) => {
      const url = `/intrepids/${id}/complete`

      return request.post(url).then((r) => r.data)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('intrepids/search', {
          refetchActive: true,
          refetchInactive: true,
        })
      },
    },
  )
}

export default useCompleteInterrupt
