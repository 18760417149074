import React, { ReactElement } from 'react'
import { ICarePlan } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Body1, Box, tail } from '@perk-ui/core'
import { addDays, format, parseISO } from 'date-fns'
import {
  Area,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { Margin } from 'recharts/types/util/types'

import { fancyFontFamily } from '../../../config/theme'
import { taperExtensionUrls } from '../../../features/Fhir/CarePlan/taperStepUtils'
import { findExtension } from '../../../features/Fhir/utils'
import { assert } from '../../../utils/assert'
import { interferenceScoreMap } from '../../../utils/painScores'
import { ScoreData } from './PainHistory'

type ChartDataKey = 'intensityScore' | 'interferenceScore' | 'totalMME'

const chartColors: Record<ChartDataKey, string> = {
  intensityScore: '#9C8E83',
  interferenceScore: '#C3B3A2',
  totalMME: '#FFE1C7',
}

interface TaperStepGraphData {
  date: number
  totalMME: number
}

/**
 * Function to convert a ICarePlan into graph data
 */
const getTaperStepChartData = (resource: ICarePlan): TaperStepGraphData => {
  // Ensure that the resource has a date
  assert(
    !!resource?.period?.start,
    `TaperStep missing start date. Id: ${resource.id}`,
  )

  // Get the MME
  const mmeExt = findExtension(
    [taperExtensionUrls.final.primaryUrl, taperExtensionUrls.final.mmeUrl],
    resource.extension || [],
  )

  return {
    date: parseISO(resource.period.start).getTime(),
    totalMME: mmeExt.valueDecimal as number,
  }
}

export interface PainHistoryChartProps {
  /**
   * Warning: This data can be aggregated across multiple series of data, but
   * those groups should be internally sorted how you want (likely by date)
   * and grouped together.
   *
   * pseudo code: array.sortBy('date'); array.sortBy('questionniareType')
   *
   * i.e. [intensityQr_1, ..., intensityQr_N, interferenceQr_1, ..., interferenceQr_N]
   */
  interferenceData: ScoreData[]
  intensityData: ScoreData[]
  taperSteps: ICarePlan[]

  /**
   * The margin to inset the chart content by. Can be used by the container to prevent
   * the ReferenceLine text to be cut off
   */
  margin: Margin
}

/**
 * TODO: Extend ReferenceLine past the XAxis and align with the label's text
 * Perhaps that can be done by using the XAxis' `tick` property
 *
 * TODO: Apply a dropshadow to the SVG lines. For some reason, applying this
 * as I have it causes the other line to vanish. I was able to get something
 * working in the devtools panel when applying this manually
 * @see https://stackoverflow.com/questions/6088409/svg-drop-shadow-using-css3
 * @see http://bl.ocks.org/cpbotha/5200394
 */
const PainHistoryChart: React.FC<PainHistoryChartProps> = ({
  intensityData,
  interferenceData,
  taperSteps,
  margin,
}) => {
  // Ensure that we have the data loaded before continuing
  if (!intensityData.length || !interferenceData.length || !taperSteps.length) {
    return null
  }

  // 1) Convert the TaperSteps into data that the graph can deal with
  // 2) Sort by date asc
  // 3) then limit to the last 3 steps
  const taperStepData = taperSteps
    .map((t) => getTaperStepChartData(t))
    .sort((a, b) => (a.date > b.date ? 1 : -1))
    .slice(Math.max(taperSteps.length - 3, 0))

  // We receive a list of scores and TaperSteps from the Hub that can have dates
  // that could be out of sync with the reality, i.e. a delayed doctor's visit means
  // the taperStep doesn't end until later. This adds a bit of a buffer to continue
  // the MME graphs past the last assessment score.
  const today = new Date()
  const dataWithContinuity = [
    ...intensityData,
    ...interferenceData,
    ...taperStepData,
    {
      // This ensures that the chart continues until today. This could be needed
      // if a Patient is overdue for their appointment and the taper step has ended,
      // but they're still on the same medications.
      date: addDays(today, 10).getTime(),
      totalMME: 0,
    },
  ].filter((score) => score.date >= taperStepData[0].date) // filter out data that is too old

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart data={dataWithContinuity} margin={margin}>
        <YAxis
          type="number"
          dataKey="score"
          yAxisId="painScores"
          hide={true}
          // The two Y axes must work together so the data is displayed
          // without overlapping but the ReferenceLines carry through
          // the chart types.
          // Give the painScore axis extra bottom padding. This could
          // probably be refined
          domain={['dataMin - 60', interferenceScoreMap.max]}
        />
        <YAxis
          type="number"
          dataKey={(datum: TaperStepGraphData) => datum.totalMME}
          yAxisId="totalMME"
          hide={true}
          // Give the totalMME axis extra top padding. This could
          // probably be refined
          domain={[0, (dataMax: number) => dataMax * 1.75]}
        />
        <XAxis
          dataKey="date"
          type="number"
          tick={false}
          domain={[taperStepData[0].date, tail(dataWithContinuity).date]}
          axisLine={false}
        />
        {/* <SvgDropshadowFilter
          id="dropshadow"
          blur="3.5"
          dx="0"
          dy="10"
          opacity="0.2"
        /> */}
        <Line
          type="monotone"
          dataKey="intensityScore"
          stroke={chartColors.intensityScore}
          strokeWidth={2}
          strokeLinecap="butt"
          yAxisId="painScores"
          dot={false}
          isAnimationActive={false}
          // filter="url(#dropshadow)"
          name="Pain Intensity"
          key="intensity"
        />
        <Line
          type="monotone"
          dataKey="interferenceScore"
          stroke={chartColors.interferenceScore}
          strokeWidth={2}
          strokeLinecap="butt"
          yAxisId="painScores"
          dot={false}
          isAnimationActive={false}
          // filter="url(#dropshadow)"
          name="Pain Interference"
          key="interference"
        />
        <Area
          stackId="totalMME"
          type="stepBefore"
          yAxisId="totalMME"
          dataKey="totalMME"
          isAnimationActive={false}
          stroke="transparent"
          fill={chartColors.totalMME}
          name="Total MME"
        />
        {taperStepData.map((tick) => {
          return (
            <ReferenceLine
              key={tick.date}
              x={tick.date}
              yAxisId="painScores"
              stroke="#B5B5B5"
              strokeWidth={1.5}
              isFront
              ifOverflow="extendDomain"
              // @ts-expect-error This beta version of recharts is missing this type
              label={(args) => {
                const { x, y, height } = args.viewBox
                return ((
                  <g
                    style={{
                      transform: `translate(${x - 20}px, ${y + height}px)`,
                    }}
                  >
                    <text
                      x={0}
                      y={0}
                      dy={16}
                      textAnchor="start"
                      fill="#666"
                      fontWeight="bold"
                    >
                      {format(new Date(tick.date), 'MMM dd').toUpperCase()}
                    </text>
                  </g>
                ) as unknown) as SVGElement // TS doesn't natively recognize this JSX as an SVGElement
              }}
            />
          )
        })}
        <Legend content={(LegendLabel as unknown) as ReactElement} />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

interface LegendLabelProps {
  payload: {
    dataKey: ChartDataKey
    value: string
  }[]
}

const LegendLabel: React.FC<LegendLabelProps> = ({ payload }) => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-between">
      {payload.map((load, index: number) => {
        const color = chartColors[load.dataKey]
        return (
          <Box
            key={`item-${index}`}
            py={1}
            style={{
              minWidth: 150,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                height: 20,
                width: 20,
                backgroundColor: color,
                marginRight: 8,
              }}
            />
            <Body1 style={{ fontFamily: fancyFontFamily }}>{load.value}</Body1>
          </Box>
        )
      })}
    </Box>
  )
}

export default PainHistoryChart
