import React from 'react'
import { ICarePlan, IMedicationRequest } from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Body1,
  Box,
  Card,
  CardContent,
  H3,
  H5,
  HeaderBar,
  HeaderBarButton,
  Paper,
  Stack,
} from '@perk-ui/core'
import { isAfter, isValid } from 'date-fns'

import { Footer, Header, Page } from '../../../components/Layout'
import { findCurrentTaperStep } from '../../../features/Fhir/CarePlan/taperStepUtils'
import useTaperStepSearch from '../../../features/Fhir/CarePlan/useTaperStepSearch'
import { useHistory } from '../../../utils/useHistory'
import TaperStepMedications from '../components/TaperStepMedications'

export interface TaperHistoryProps {}

const TaperHistory: React.FC<TaperHistoryProps> = ({ children }) => {
  const { goBack } = useHistory()
  const { data: taperStepData = [] } = useTaperStepSearch()

  // const taperStepData = taperStepCarePlanData

  const upcomingStep = taperStepData.find((step) => {
    const startDate = new Date(step.period?.start || '')
    if (!isValid(startDate)) return false
    return isAfter(startDate, new Date())
  })
  const currentStep = findCurrentTaperStep(taperStepData)
  const pastSteps = taperStepData.filter((step) => {
    const endDate = new Date(step.period?.end || '')
    if (!isValid(endDate)) return false

    return isAfter(new Date(), endDate)
  })

  return (
    <Page
      title="Medication Plan History"
      header={
        <Header withNav={false}>
          <HeaderBar
            titleContent="Medication Plan History"
            left={<HeaderBarButton variant="back" onClick={goBack} />}
          />
        </Header>
      }
      footer={<Footer withNav={false} floating></Footer>}
    >
      <Box p={3}>
        {upcomingStep && (
          <>
            <H3 paragraph>Upcoming</H3>
            <Card>
              <CardContent>
                <TaperStepMedications taperStep={upcomingStep} />
              </CardContent>
            </Card>

            <Box mb={3} />
          </>
        )}

        {currentStep ? (
          <>
            <H3 paragraph>Current</H3>
            <Card>
              <CardContent>
                <TaperStepMedications taperStep={currentStep} />
              </CardContent>
            </Card>
          </>
        ) : (
          <>
            <H3>No current taper steps</H3>
          </>
        )}
        <Box mb={3} />

        {pastSteps.length ? (
          <>
            <H3 paragraph>Past</H3>
            {pastSteps.map((step) => (
              <Box mb={3} key={step.id}>
                <Card>
                  <CardContent>
                    <TaperStepMedications taperStep={step} />
                  </CardContent>
                </Card>
              </Box>
            ))}
          </>
        ) : null}
      </Box>
    </Page>
  )
}

export default TaperHistory
